import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const RetentionListing = lazy(() =>
  import('./RetentionListing').then(module => ({
    default: module.RetentionListing,
  }))
)

const RetentionForm = lazy(() =>
  import('./RetentionForm').then(module => ({
    default: module.RetentionForm,
  }))
)

const retentionRoutes: RouteObject[] = [
  {
    path: 'retention',
    children: [
      {
        path: '',
        element: <RetentionListing />,
      },
      {
        path: 'add',
        element: <RetentionForm mode="add" />,
      },
      {
        path: 'edit',
        element: <RetentionForm mode="edit" />,
      },
    ],
  },
]

export default retentionRoutes
