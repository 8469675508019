import { yupResolver } from '@hookform/resolvers/yup'
import { ColumnFlex, Container, Footer, RowGrid } from '@ifca-ui/core'
import { Card, CardContent } from '@mui/material'
import BlockFloorUnitSelection from 'components/General/BlocksFloorsUnitsSelection'
import DropdownButton from 'components/Inputs/DropdownButton'
import { TextFormField } from 'components/Inputs/InputFormField'
import { NumberFormField } from 'components/Inputs/NumberFormField'
import useNav from 'components/RouteService/useNav'
import {
  useAddPriceAdjustmentMutation,
  useEditPriceAdjustmentMutation,
  useGetPriceAdjustmentByIdLazyQuery,
  useGetReasonListLazyQuery,
  useGetRoundingLazyQuery,
  useGetSelectUnitAtPriceAdjustmentLazyQuery,
} from 'generated/graphql'
import useHeader from 'helpers/Hooks/useHeader'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { CommonYupValidation } from 'helpers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import * as yup from 'yup'
import { ProjectSubHeader } from '../../../../components/General/ProjectSubHeader'

export interface UnitPriceInput {
  id: string
  title: string
  roundingMode: any
  roundingPrecision: any
  reason: any
  computationMode: any
  computationValue: number
  remark?: string
}

export const UnitPricingForm = () => {
  const { projectID, id }: any = useParams()
  const { navBack } = useNav()

  useHeader({
    current: 'Unit Pricing',
    maxItems: 3,
    rightText: id ? 'Edit' : 'New',
  })

  const schema = yup.object().shape({
    title: CommonYupValidation.requireField('Title is required'),
    roundingMode: CommonYupValidation.requireArray('Rounding Mode is required'),
    roundingPrecision: CommonYupValidation.requireArray(
      'Rounding Precision is required'
    ),
    reason: CommonYupValidation.requireArray('Reason is required'),
    computationMode: CommonYupValidation.requireArray(
      'Computation Mode is required'
    ),
    computationValue: CommonYupValidation.requireField(
      'Computation Value is required'
    ),
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<UnitPriceInput>({
    defaultValues: {
      title: '',
      roundingPrecision: null,
      roundingMode: null,
      computationMode: null,
      computationValue: null,
      reason: null,
      remark: '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  })

  // #region addPriceAdjustment (previously useAddPriceAdjustment)

  const [addPriceAdjustment, { loading: isLoadingAdd }] =
    useAddPriceAdjustmentMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
      onCompleted: data => {
        if (data?.addPriceAdjustment) {
          setSnackBar(SystemMsgs.createNewRecord())
          setTimeout(() => {
            navBack()
          }, 500)
        }
      },
    })
  // #endregion

  // #region editPriceAdjustment (previously useEditPriceAdjustment())
  const [editPriceAdjustment, { loading: isLoadingEdit }] =
    useEditPriceAdjustmentMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
      onCompleted: data => {
        if (data?.editPriceAdjustment) {
          setSnackBar(SystemMsgs.updateRecord())
          setTimeout(() => {
            navBack()
          }, 500)
        }
      },
    })
  // #endregion

  // #region getPriceAdjustmentById
  const [
    fetchRounding,
    {
      data: { getRounding } = {
        getRounding: null,
      },
    },
  ] = useGetRoundingLazyQuery()

  const [
    fetchPriceAdjustmentData,
    {
      data: { getPriceAdjustmentById: priceAdjustment } = {
        getPriceAdjustmentById: null,
      },
      refetch: refetchPriceAdjustmentData,
      loading: priceAdjustmentByIdLoading,
      error: priceAdjustmentByIdError,
    },
  ] = useGetPriceAdjustmentByIdLazyQuery({
    onCompleted: ({ getPriceAdjustmentById }) => {
      {
        const foundRoundingPrecision = getRounding.roundingPrecision.find(
          x => x.id === getPriceAdjustmentById?.roundingPrecision
        )
        const foundRoundingMode = getRounding.roundingMode.find(
          x => x.id === getPriceAdjustmentById?.roundingMode
        )
        const foundComputationMode = computationModeList.find(
          x => x.name === getPriceAdjustmentById?.computationMode
        )
        reset({
          title: getPriceAdjustmentById.name,
          roundingPrecision: foundRoundingPrecision,
          roundingMode: foundRoundingMode,
          computationValue: getPriceAdjustmentById.computationValue,
          computationMode: foundComputationMode,
          reason: getPriceAdjustmentById.defaultReason[0],
          remark: getPriceAdjustmentById.remark,
        })
      }
    },
  })
  // #endregion

  // #region getSelectUnitAtPriceAdjustment
  const [
    fetchSelectUnitList,
    {
      data: { getSelectUnitAtPriceAdjustment: unitData } = {
        getSelectUnitAtPriceAdjustment: [],
      },
      loading: isFetchingUnits,
    },
  ] = useGetSelectUnitAtPriceAdjustmentLazyQuery({})
  // #endregion

  // #region getReasonList
  const [
    fetchReasonList,
    {
      data: { getReasonList: reasonList } = {
        getReasonList: null,
      },
    },
  ] = useGetReasonListLazyQuery({})
  // #endregion

  const onSubmit = (data: UnitPriceInput) => submitApi(data, 'SUBMITTED')
  const saveAsDraft = (data: UnitPriceInput) => submitApi(data, 'DRAFT')
  const submitApi = (data: UnitPriceInput, status: string) => {
    if (id) {
      // Edit data
      const unitIdList = selectedUnit?.map(x => x.unitId)
      if (unitIdList.length == 0 || unitIdList.length == null) {
        return setSnackBar('Please select at least one(1) unit to save')
      }

      editPriceAdjustment({
        variables: {
          input: {
            id: id,
            projectId: projectID,
            computationMode: data.computationMode.name,
            computationValue: parseFloat(data.computationValue.toString()),
            name: data.title,
            reason: data.reason.id,
            roundingMode: data.roundingMode.id,
            roundingPrecision: data.roundingPrecision.id,
            unitIdList: unitIdList,
            remark: data?.remark,
            status: status,
          },
        },
      })
    } else {
      // Add data
      const unitIdList = selectedUnit?.map(x => x.unitId)
      if (unitIdList.length == 0 || unitIdList.length == null) {
        return setSnackBar('Please select at least one(1) unit to save')
      }

      addPriceAdjustment({
        variables: {
          input: {
            projectId: projectID,
            computationMode: data.computationMode.name,
            computationValue: parseFloat(data.computationValue.toString()),
            name: data.title,
            reason: data.reason.id,
            roundingMode: data.roundingMode.id,
            roundingPrecision: data.roundingPrecision.id,
            unitIdList: unitIdList,
            remark: data?.remark,
            status: status,
          },
        },
      })
    }
  }

  useEffect(() => {
    fetchReasonList()
    fetchSelectUnitList({
      variables: {
        projectId: projectID,
        id: id ? id: null,
      },
    })

    fetchRounding()
    if (id) {
      fetchPriceAdjustmentData({
        variables: {
          projectId: projectID,
          id: id,
        },
      })
    }
  }, [])

  const [selectedUnit, setSelectedUnit] = useState<any>([])

  const computationModeList = [
    { name: 'New Price = Value' },
    { name: 'Original Price + Value' },
    { name: 'Original Price x Value' },
  ]

  return (
    <>
      <ProjectSubHeader />
      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <Container>
          <ColumnFlex gap={'10px'}>
            <Card>
              <CardContent>
                <TextFormField
                  control={control}
                  label="Title"
                  name="title"
                  errors={errors}
                />

                <RowGrid gridTemplateColumns={'1fr 1fr'} gap="10px">
                  <DropdownButton
                    control={control}
                    label="Rounding Mode"
                    name="roundingMode"
                    errors={errors}
                    optionLabel={'name'}
                    listData={getRounding?.roundingMode}
                  />
                  <DropdownButton
                    control={control}
                    label="Rounding Precision"
                    name="roundingPrecision"
                    errors={errors}
                    optionLabel={'name'}
                    listData={getRounding?.roundingPrecision}
                  />
                </RowGrid>
                {reasonList && (
                  <DropdownButton
                    control={control}
                    label="Reason"
                    name="reason"
                    errors={errors}
                    optionLabel={'name'}
                    listData={reasonList}
                  />
                )}

                <DropdownButton
                  control={control}
                  label="Computation Mode"
                  name="computationMode"
                  errors={errors}
                  optionLabel={'name'}
                  listData={computationModeList}
                />
                <NumberFormField
                  control={control}
                  label="Value"
                  name="computationValue"
                  errors={errors}
                />
                <TextFormField
                  control={control}
                  label="Remark"
                  name="remark"
                  errors={errors}
                  isRequired={false}
                />
              </CardContent>
            </Card>
            {unitData && (
              <Card>
                <CardContent>
                  <BlockFloorUnitSelection
                    blockDetails={unitData}
                    setSelectedUnit={setSelectedUnit}
                    existingSelectedUnit={
                      id && priceAdjustment?.priceAdjustmentUnit
                        ? priceAdjustment?.priceAdjustmentUnit?.map(
                            x => x.unitId
                          )
                        : []
                    }
                  />
                </CardContent>
              </Card>
            )}
          </ColumnFlex>
        </Container>
      </form>
      <Footer
        buttons={[
          {
            children: 'Save as Draft',
            color: 'primary',
            disabled: isLoadingAdd,
            onClick: () => {
              handleSubmit(saveAsDraft)()
            },
          },
          {
            children: 'Submit',
            color: 'primary',
            disabled: isLoadingAdd,
            onClick: () => {
              handleSubmit(onSubmit)()
            },
          },
        ]}
      />
      {/* <Footer
        buttons={[
          {
            children: 'Save as Draft',
            color: 'primary',
            disabled: isLoadingAdd,
            onClick: () => {
              handleSubmit(saveAsDraft)()
            },
          },
          {
            children: 'Submit',
            color: 'primary',
            disabled: isLoadingAdd,
            onClick: () => {
              handleSubmit(onSubmit)()
            },
          },
        ]}
      /> */}
    </>
  )
}
