import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const ContractorListing = lazy(() => import('./ContractorListing'))
const ContractorDetail = lazy(() => import('./ContractorDetail'))
const ContractorForm = lazy(() => import('./ContractorForm'))

const ContractorRoutes: RouteObject[] = [
  { path: '', element: <ContractorListing /> },
  {
    path: '',
    children: [
      { path: 'contractor-form/add', element: <ContractorForm mode="Add" /> },
      {
        path: 'contractor-form/:id/edit',
        element: <ContractorForm mode="Edit" />,
      },
      { path: 'contractor-detail/:id', element: <ContractorDetail /> },
    ],
  },
]

export default ContractorRoutes
