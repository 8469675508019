import {
  Card as MuiCard,
  CardContent,
  type CardContentProps,
  CardHeader,
  type CardHeaderProps,
  type CardProps,
} from '@mui/material'
import type React from 'react'
import { Colors } from '../../styles/variables'
interface ICardProps extends CardProps {
  header?: {
    title?: React.ReactNode
    action?: React.ReactNode
    props?: CardHeaderProps
  }
  cardContent?: CardContentProps
  children?: React.ReactNode
}
export const Card = ({
  header,
  children,
  cardContent,
  ...props
}: ICardProps): JSX.Element => {
  return (
    <MuiCard
      {...props}
      sx={{
        marginBottom: '4px',
        borderRadius: '3px',
        boxShadow:
          '0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1)',
        ...props?.sx,
      }}
    >
      {header ? (
        <CardHeader
          disableTypography
          title={header?.title}
          action={header?.action}
          {...header?.props}
          sx={{
            padding: '10px 12px',
            background: Colors.lightOrange,
            '& .MuiIconButton-root': {
              padding: '0px',
            },
            // '& .MuiSvgIcon-root': {
            //   width: '18px',
            //   height: '18px',
            // },
            '& .MuiCardHeader-action': {
              marginRight: 'unset',
            },
            ...header?.props?.sx,
          }}
        />
      ) : null}

      <CardContent
        {...cardContent}
        sx={{
          padding: '10px 12px',
          display: 'flex',
          flexWrap: 'wrap',
          ':last-child': {
            paddingBottom: '10px',
          },
          ...cardContent?.sx,
        }}
      >
        {children}
      </CardContent>
    </MuiCard>
  )
}
