import { Card, ColumnGrid, Container, EmptyState, RowGrid } from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'
import {
  useGetAddressByIdQuery,
  useGetAllMasterDataQuery,
  useGetContactByIdQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'

import { CardListHeader, CardListItem } from 'components/General/CardListItem'
import { TopSectionHeader } from 'components/Header/HeaderSection'

import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useLocation, useNavigate, useParams } from 'react-router'

export const PurchaseInfoDetail = () => {
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  let location = useLocation()
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  const [primaryAddress, setPrimaryAddress] = useState()

  const { id, isMainBuyer } = location?.state
  const { projectID, saleID }: any = useParams()

  //#region enum listing
  const IdentityTypeListing = [
    {
      ID: 'IDENTITY_CARD',
      Name: 'Identity Card',
    },
    { ID: 'PASSPORT', Name: 'Passport' },
    { ID: 'MILITARY_CARD', Name: 'Military Card' },
    { ID: 'BUSINESS_LICENSE', Name: 'Business License' },
  ]

  const CustomerOriginListing = [
    { ID: 'STAFF_PURCHASE', Name: 'Staff Purchaser' },
    { ID: 'PUBLIC', Name: 'Public' },
    { ID: 'GOVERNMENT_STAFF', Name: 'Government Staff' },
    { ID: 'COMPANY_PURCHASE', Name: 'Company Purchase' },
  ]
  //#endregion

  const {
    loading: curProjectLoading,
    error: curProjectError,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })

  //#region get master data graphql
  const {
    loading: getMasterDataLoading,
    error: getMasterDataError,
    data: { GetAllMasterInfo } = { GetAllMasterInfo: {} },
  } = useGetAllMasterDataQuery({})
  const { Genders }: any = GetAllMasterInfo
  //#endregion

  const {
    loading: getContactByIdLoading,
    error: getContactByIdError,
    data: { getContactById } = { getContactById: null },
  } = useGetContactByIdQuery({
    variables: {
      contactId: id,
    },
  })

  const {
    loading: getAddressByIdLoading,
    error: getAddressByIdError,
    data: { getAddressById } = { getAddressById: null },
  } = useGetAddressByIdQuery({
    variables: {
      contactId: id,
    },
  })

  const contactExt = getContactById?.contactExt

  const MarketingMaterial = () => {
    let list = []
    if (contactExt?.subscribeMaterialSms) list.push('SMS')
    if (contactExt?.subscribeMaterialWhatsapp) list.push('Whatsapp')
    if (contactExt?.subscribeMaterialEmail) list.push('Email Blast')
    if (contactExt?.subscribeMaterialPaNews) list.push('Purchaser App > News')

    return list
  }

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'close',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: isMainBuyer == true ? 'Main Buyer' : 'Joint Buyer',
          },
          rightText: '',
        },
      },
    })
  }, [projectDetail, getContactById])

  useEffect(() => {
    if (getAddressById) {
      let temp = getAddressById?.filter(x => x?.is_primary === true)
      setPrimaryAddress(temp)
    }
  }, [getAddressById])

  return (
    <>
      <Container>
        <Card className="card-container-detail">
          {getContactById?.companyName && (
            <>
              <Box>
                <Typography className="label-f">Company Name</Typography>
                <Typography className="value-f">
                  {getContactById?.companyName ?? '-'}
                </Typography>
              </Box>

              <Box>
                <Typography className="label-f">Company Reg No.</Typography>
                <Typography className="value-f">
                  {getContactById?.companyRegNo ?? '-'}
                </Typography>
              </Box>
            </>
          )}
          <Box>
            <Typography className="label-f">Nationality</Typography>
            <Typography className="value-f">
              {getContactById?.nationality?.name ?? '-'}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Identity Type</Typography>
            <Typography className="value-f">
              {IdentityTypeListing?.find(
                el => el.ID == contactExt?.identityType
              )?.Name ?? '-'}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">
              {getContactById?.companyName ? 'Director NRIC' : 'Identity No'}
            </Typography>
            <Typography className="value-f">{getContactById?.icNo}</Typography>
          </Box>
          <Box>
            <Typography className="label-f">
              {getContactById?.companyName
                ? 'Director Name'
                : 'Name as per NRIC'}
            </Typography>
            <Typography className="value-f">
              {getContactById?.fullName}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Salutation</Typography>
            <Typography className="value-f">
              {getContactById?.salutation?.name ?? '-'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Race</Typography>
            <Typography className="value-f">
              {getContactById?.race?.name ?? '-'}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Marital Status</Typography>
            <Typography className="value-f">
              {getContactById?.maritalStatus?.name ?? '-'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Gender</Typography>
            <Typography className="value-f">
              {Genders?.[getContactById?.gender] ?? '-'}
            </Typography>
          </Box>
          <Box>
            <Typography className="label-f">Customer Origin</Typography>
            <Typography className="value-f">
              {CustomerOriginListing?.find(
                el => el.ID == contactExt?.customerOrigin
              )?.Name ?? '-'}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Bumiputera</Typography>
            <Typography className="value-f">
              {contactExt?.isBumi ? 'Yes' : 'No'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">VIP</Typography>
            <Typography className="value-f">
              {contactExt?.isVip ? 'Yes' : 'No'}
            </Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Foreign Purchase</Typography>
            <Typography className="value-f">
              {contactExt?.isForeignPurchase ? 'Yes' : 'No'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Email</Typography>
            <Typography className="value-f">{getContactById?.email}</Typography>
          </Box>
          <Box className="left">
            <Typography className="label-f">Home Tel No.</Typography>
            <Typography className="value-f">
              {getContactById?.homeTel ?? '-'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Mobile No.</Typography>
            <Typography className="value-f">
              {getContactById?.phoneNo ?? '-'}
            </Typography>
          </Box>
          <Box>
            <Typography className="label-f">Office Tel No.</Typography>
            <Typography className="value-f">
              {getContactById?.officeTel ?? '-'}
            </Typography>
          </Box>{' '}
          <Box className="left">
            <Typography className="label-f">
              Tax Indenfication No (TIN)
            </Typography>
            <Typography className="value-f">
              {contactExt?.tin ?? '-'}
            </Typography>
          </Box>
          <Box className="right">
            <Typography className="label-f">Sst Registration No.</Typography>
            <Typography className="value-f">
              {contactExt?.sstNo ?? '-'}
            </Typography>
          </Box>
          <Box>
            <Typography className="label-f">Marketing Material</Typography>
            <Typography className="value-f">
              {MarketingMaterial()?.map((x, index) => {
                if (index == MarketingMaterial()?.length - 1) return `${x}`
                else return `${x}, `
              })}
            </Typography>
          </Box>
          <Box>
            <Typography className="label-f">Mailing Method</Typography>
            <Typography className="value-f">
              {contactExt?.mailingMethod ?? '-'}
            </Typography>
          </Box>
        </Card>

        {getAddressById && (
          <div className="address-card-list">
            <CardListHeader
              title={
                <Typography
                  className="text-xsTitle"
                  color="#FF9800"
                  component="span"
                  width="100%"
                  flex="1 1"
                  variant="inherit"
                >
                  Address Listing
                </Typography>
              }
            >
              {getAddressById === undefined || getAddressById?.length < 0 ? (
                <EmptyState title="No Record Found" />
              ) : (
                <>
                  {getAddressById
                    ?.sort((a, b) => (b.is_primary === true ? 1 : -1))
                    .map((el, i) => {
                      return (
                        <>
                          <CardListItem
                            key={i}
                            body={
                              <Box className="address-card-view">
                                <ColumnGrid
                                  gridTemplateRows={'1fr 1fr 1fr'}
                                  gap="10px"
                                >
                                  <RowGrid gridTemplateColumns={'1fr 1fr'}>
                                    <div>
                                      <Typography className="label-f">
                                        Address
                                      </Typography>
                                      <Typography className="value-f">
                                        {el?.address?.address}
                                      </Typography>
                                    </div>
                                    <div>
                                      <Typography className="label-f">
                                        Primary
                                      </Typography>
                                      <Typography className="value-f">
                                        {el?.is_primary === true ? 'Yes' : 'No'}
                                      </Typography>
                                    </div>
                                  </RowGrid>
                                  <RowGrid gridTemplateColumns={'1fr 1fr'}>
                                    <div>
                                      <Typography className="label-f">
                                        Country
                                      </Typography>
                                      <Typography className="value-f">
                                        {el?.address?.country}
                                      </Typography>
                                    </div>

                                    <div>
                                      <Typography className="label-f">
                                        Postcode
                                      </Typography>
                                      <Typography className="value-f">
                                        {el?.address?.postCode}
                                      </Typography>
                                    </div>
                                  </RowGrid>
                                  <RowGrid gridTemplateColumns={'1fr 1fr'}>
                                    <div>
                                      <Typography className="label-f">
                                        State
                                      </Typography>
                                      <Typography className="value-f">
                                        {el?.address?.state}
                                      </Typography>
                                    </div>

                                    <div>
                                      <Typography className="label-f">
                                        City
                                      </Typography>
                                      <Typography className="value-f">
                                        {el?.address?.city}
                                      </Typography>
                                    </div>
                                  </RowGrid>
                                </ColumnGrid>
                              </Box>
                            }
                          />
                        </>
                      )
                    })}
                </>
              )}
            </CardListHeader>
          </div>
        )}
      </Container>
    </>
  )
}
