import React, { useContext, useEffect, useRef, useState } from 'react'
import { SubHeaderContainer } from './SubHeaderContainer'

type props = {
  children?: any
  heightOnChange?: (height: number) => void
}

export const SubContainerX = ({ children, heightOnChange }: props) => {
  const subHeaderRef = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(0)

  //listen to window size on change
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  // reset height
  useEffect(() => {
    setHeight(subHeaderRef?.current?.clientHeight || 0)
  }, [subHeaderRef?.current, children, windowSize])

  useEffect(() => {
    if (heightOnChange && typeof heightOnChange === 'function') {
      heightOnChange(subHeaderRef?.current?.clientHeight || 0)
    }
  }, [height])

  return (
    <>
      <SubHeaderContainer id="subheader-container" ref={subHeaderRef}>
        {children}
      </SubHeaderContainer>
      <div
        style={{
          height: `${height}px`,
        }}
      />
    </>
  )
}
