import React from 'react'
import { AlignItemsEnum, JustifyContentEnum, OverflowEnum } from './LayoutXenum'

type FlexXProps = {
  children?: React.ReactNode
  className?: string
  crossAxisAlignment?: AlignItemsEnum
  flexDirection: 'row' | 'column'
  fullWidth?: boolean
  gap?: string
  height?: string
  mainAxisAlignment?: JustifyContentEnum
  overflow?: OverflowEnum
}

export const FlexX = ({
  children,
  className,
  crossAxisAlignment,
  flexDirection,
  fullWidth,
  gap,
  height,
  mainAxisAlignment,
  overflow,
}: FlexXProps) => {
  return (
    <div
      className={className}
      style={{
        alignItems: crossAxisAlignment,
        display: 'flex',
        flexDirection: flexDirection,
        gap: gap,
        height: height,
        justifyContent: mainAxisAlignment,
        overflow: overflow,
        width: fullWidth ? '100%' : 'auto',
      }}
    >
      {children}
    </div>
  )
}
