import {
  Container,
  EmptyState,
  Expansion,
  ExpansionDetails,
  ExpansionSummary,
  List,
  ListItemText,
  SearchBar,
  SubContainerX,
  Subheader,
  SubheaderText,
} from '@ifca-ui/core'
import {
  Article,
  DateRange,
  EventBusy,
  HouseOutlined,
  NotificationsRounded,
} from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Typography } from '@mui/material'
import TextX from 'components/General/TextX'
import TooltipX from 'components/General/TooltipX'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetPaymentReminderBySaleIdQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

const PAPaymentReminder = (props: any) => {
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  let navigate = useNavigate()
  const { projectID, saleID }: any = useParams()
  const [search, setSearchInput] = React.useState('')
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const { navBack, navTo } = useNav()

  const {
    loading: curProjectLoading,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })

  const {
    loading: getPaymentReminderBySaleIdLoading,
    data: { getPaymentReminderBySaleId } = {
      getPaymentReminderBySaleId: [],
    },
  } = useGetPaymentReminderBySaleIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      saleId: saleID,
    },
  })

  useEffect(() => {
    setOriginalListing(getPaymentReminderBySaleId)
  }, [getPaymentReminderBySaleId])

  //#region header/subheader //////////////
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: 'Payment Reminder',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])

  return (
    <>
      <SubContainerX>
        <Subheader>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Typography
                  component="div"
                  display="flex"
                  className="text-xsTitle text-underline"
                >
                  <Typography
                    component="u"
                    flex="1 1"
                    display="flex"
                    justifyContent={'unset'}
                    variant="inherit"
                    className="text-icon "
                  >
                    <HouseOutlined />
                    {getPaymentReminderBySaleId[0]?.unitNo}
                  </Typography>
                </Typography>
              </Box>
            }
            secondary={
              <Box>
                <Typography
                  component="div"
                  display="flex"
                  className="text-xsTitle"
                >
                  <Typography
                    component="div"
                    display="flex"
                    justifyContent={'unset'}
                    variant="inherit"
                    className="text-icon "
                  >
                    <DateRange />
                    {formatDate(
                      getPaymentReminderBySaleId[0]?.reminderDate.toString()
                    )}
                  </Typography>

                  <Typography
                    color="common.black"
                    component="div"
                    display="flex"
                    variant="inherit"
                    marginLeft="25px"
                  >
                    <Typography
                      className="text-icon text-desc"
                      component="span"
                      variant="inherit"
                    >
                      <NotificationsRounded />1
                    </Typography>
                    <Typography
                      className="text-icon text-desc"
                      component="span"
                      variant="inherit"
                    >
                      <Article />
                      {
                        getPaymentReminderBySaleId[0]?.paymentReminderDetails?.filter(
                          el => el.reminderSeq == 1
                        ).length
                      }
                    </Typography>
                  </Typography>

                  <Typography
                    color="common.black"
                    component="div"
                    display="flex"
                    variant="inherit"
                    marginLeft="25px"
                  >
                    <Typography
                      className="text-icon text-desc"
                      component="span"
                      variant="inherit"
                    >
                      <NotificationsRounded />2
                    </Typography>
                    <Typography
                      className="text-icon text-desc"
                      component="span"
                      variant="inherit"
                    >
                      <Article />
                      {
                        getPaymentReminderBySaleId[0]?.paymentReminderDetails?.filter(
                          el => el.reminderSeq == 2
                        ).length
                      }
                    </Typography>
                  </Typography>

                  <Typography
                    color="common.black"
                    component="div"
                    display="flex"
                    variant="inherit"
                    marginLeft="25px"
                  >
                    <Typography
                      className="text-icon text-desc"
                      component="span"
                      variant="inherit"
                    >
                      <NotificationsRounded />3
                    </Typography>
                    <Typography
                      className="text-icon text-desc"
                      component="span"
                      variant="inherit"
                    >
                      <Article />
                      {
                        getPaymentReminderBySaleId[0]?.paymentReminderDetails?.filter(
                          el => el.reminderSeq == 3
                        ).length
                      }
                    </Typography>
                  </Typography>
                </Typography>
              </Box>
            }
          />
        </Subheader>
        <Box>
          <SearchBar
            title={
              <Typography
                component="span"
                className="text-mdLabel"
                variant="inherit"
              >
                Payment Reminder (
                <Typography
                  component="span"
                  variant="inherit"
                  className="text-mdLabel search-bar-count-f"
                >
                  {`${getPaymentReminderBySaleId?.length ?? 0}`}
                </Typography>
                )
              </Typography>
            }
            searchInput={{
              value: search,
              onChange: e => {
                setSearchInput(e.target.value)
                handleSearch(e?.target?.value, ['reminderDate', 'documentNo'])
              },
              onChangeClearInput: () => {
                handleSearch('', ['reminderDate', 'documentNo'])
                setSearchInput('')
              },
            }}
          />
        </Box>
      </SubContainerX>

      <Container>
        <List>
          {getPaymentReminderBySaleId === undefined ||
          getPaymentReminderBySaleId?.length === 0 ? (
            <EmptyState subTitle="No records" />
          ) : (
            filteredList?.map((v, index) => {
              return (
                <Expansion>
                  <ExpansionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="expansion-panel"
                  >
                    <ListItemText
                      primary={
                        <Typography className="font-flex" component="div">
                          <Typography className="font-left text-xsTitle text-icon">
                            <DateRange />
                            {formatDate(v?.reminderDate.toString())}
                          </Typography>
                        </Typography>
                      }
                      secondary={
                        <Typography component="div" display="flex">
                          <Typography
                            color="common.black"
                            component="div"
                            display="flex"
                            variant="inherit"
                          >
                            <Typography
                              className="text-icon text-desc"
                              component="span"
                              variant="inherit"
                            >
                              <NotificationsRounded />1
                            </Typography>
                            <Typography
                              className="text-icon text-desc"
                              component="span"
                              variant="inherit"
                            >
                              <Article />
                              {
                                v?.paymentReminderDetails?.filter(
                                  el => el.reminderSeq == 1
                                ).length
                              }
                            </Typography>
                          </Typography>
                          <Typography
                            color="common.black"
                            component="div"
                            display="flex"
                            variant="inherit"
                            marginLeft="25px"
                          >
                            <Typography
                              className="text-icon text-desc"
                              component="span"
                              variant="inherit"
                            >
                              <NotificationsRounded />2
                            </Typography>
                            <Typography
                              className="text-icon text-desc"
                              component="span"
                              variant="inherit"
                            >
                              <Article />
                              {
                                v?.paymentReminderDetails?.filter(
                                  el => el.reminderSeq == 2
                                ).length
                              }
                            </Typography>
                          </Typography>
                          <Typography
                            color="common.black"
                            component="div"
                            display="flex"
                            variant="inherit"
                            marginLeft="25px"
                          >
                            <Typography
                              className="text-icon text-desc"
                              component="span"
                              variant="inherit"
                            >
                              <NotificationsRounded />3
                            </Typography>
                            <Typography
                              className="text-icon text-desc"
                              component="span"
                              variant="inherit"
                            >
                              <Article />
                              {
                                v?.paymentReminderDetails?.filter(
                                  el => el.reminderSeq == 3
                                ).length
                              }
                            </Typography>
                          </Typography>
                        </Typography>
                      }
                    />
                  </ExpansionSummary>
                  {v?.paymentReminderDetails?.map(el => (
                    <ExpansionDetails
                      sx={{ padding: '10px 12px', backgroundColor: '#d5d5d5' }}
                    >
                      <Box display="flex">
                        <ListItemText
                          primary={
                            <Typography className="font-flex" component="div">
                              <Typography className="font-left text-desc text-icon">
                                <DateRange />
                                {formatDate(el.documentDate.toString())}
                              </Typography>

                              <Typography className="font-center text-desc">
                                {el.documentNo}
                              </Typography>

                              <TooltipX title="Balance Amount" placement="left">
                                <TextX
                                  data={el.balanceAmount}
                                  dataType={'Number'}
                                  className="text-desc"
                                  color="primary"
                                />
                              </TooltipX>
                            </Typography>
                          }
                          secondary={
                            <Typography className="font-flex" component="div">
                              <Typography className="font-left text-desc text-icon">
                                <EventBusy />
                                {formatDate(el.dueDate.toString())}
                              </Typography>

                              <Typography className="font-right text-desc text-icon">
                                <NotificationsRounded />
                                {el.reminderSeq}
                              </Typography>
                            </Typography>
                          }
                        />
                      </Box>
                    </ExpansionDetails>
                  ))}
                </Expansion>
              )
            })
          )}
        </List>
      </Container>
    </>
  )
}

export default PAPaymentReminder
