import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const DiscountListing = lazy(() => import('./DiscountListing'))

export const DISCOUNT_PATH = {
  DISCOUNT_LISTING: '/project/:projectID/discount',
}

const discountRoutes: RouteObject[] = [
  {
    path: 'discount',
    element: <DiscountListing />,
  },
]

export default discountRoutes
