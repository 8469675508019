import { Box, ListItemText, TextField, Typography } from '@mui/material'
import { CardListHeader, CardListItem } from 'components/General/CardListItem'
import {
  RefundAllocationOutput,
  useGetRefundAllocationLazyQuery,
} from 'generated/graphql'
import { amtStr } from 'helpers/numFormatter'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import React, { useEffect, useState } from 'react'
import {
  Control,
  Controller,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormWatch,
} from 'react-hook-form'
import NumberFormat from 'react-number-format'

interface allocationProps {
  billItem?: string
  isPage: boolean
  amount: number
  documentAmount: number
  saleId?: string
  id?: string
  setTotalAmount?: React.Dispatch<React.SetStateAction<number>>
  subheaderRef?: any
  fields: any
  append: UseFieldArrayAppend<any, 'allocationList'>
  errors?: any
  control: Control<any, any>
  watch: UseFormWatch<any>
  update: UseFieldArrayUpdate<any, 'allocationList'>
  remove?: UseFieldArrayRemove
  showRemainder?: boolean
}

const AllocationComponent = ({
  isPage,
  amount,
  documentAmount,
  saleId,
  id,
  subheaderRef,
  fields,
  append,
  errors,
  control,
  watch,
  update,
  setTotalAmount,
  remove,
  showRemainder = false,
}: allocationProps) => {
  const [total, setTotal] = useState(0)
  const [totalOutstandingAmount, setTotalOutstandingAmount] = useState(0)

  const formatNumber = amount => {
    return parseFloat(amount?.toString().replace(/,/g, ''))
  }

  let totalAmount = 0

  const sum = (array, key) => {
    return formatNumber(array.reduce((a, b) => a + (Number(b[key]) || 0), 0))
  }

  //#region get allocation list api
  const [
    getAllocationList,
    {
      data: { getRefundAllocation } = {
        getRefundAllocation: null,
      },
      refetch,
    },
  ] = useGetRefundAllocationLazyQuery({
    onCompleted: ({ getRefundAllocation }) => {
      if (getRefundAllocation) {
        setTotalOutstandingAmount(sum(getRefundAllocation, 'outstanding'))
        let billingList: RefundAllocationOutput[] = getRefundAllocation
        let arr = []
        for (const v of billingList) {
          arr.push({
            allocateAmount: v.currentAllocate,
            creditId: v.creditId,
            debitId: '',
            description: v.description,
            documentAmount: v.docAmt,
            documentDate: v.docDate,
            documentNo: v.docNo,
            outstanding: v.outstandingAmt,
            table: v.creditRefTable,
            totalAllocate: v.totalAllocate,
          })
        }

        append(arr)
        arr?.map(x => {
          totalAmount += x?.allocateAmount
          setTotal(totalAmount)
          if (setTotalAmount) {
            setTotalAmount(totalAmount)
          }
        })
      }
    },
  })
  //#endregion

  //#region call to function get data when init page
  useEffect(() => {
    if (remove) {
      remove()
    }
    getAllocationList({
      variables: {
        saleId: saleId,
        refundId: id,
      },
    })
  }, [])
  //#endregion

  return (
    <>
      {showRemainder && (
        <CardListHeader
          title={
            <Typography
              className="text-xsTitle"
              color="#FF9800"
              component="span"
              flex="1 1"
              variant="inherit"
            >
              Remainder
            </Typography>
          }
          action={
            <Typography
              className="text-xsTitle"
              color="#FF9800"
              component="span"
              flex="1 1"
              variant="inherit"
            >
              {amtStr(amount - total)}
            </Typography>
          }
        />
      )}

      <form id="submit-form">
        <CardListHeader
          title={
            <Box width="100%" display="flex">
              <Typography
                className="text-xsTitle"
                color="primary"
                component="span"
                flex="1 1"
                variant="inherit"
              >
                Document
              </Typography>

              <Typography
                className="text-xsTitle"
                color="primary"
                component="span"
                flex="1 1"
                variant="inherit"
                textAlign="center"
              >
                O/S Balance
              </Typography>

              <Typography
                className="text-xsTitle"
                color="primary"
                component="span"
                flex="1 1"
                variant="inherit"
                textAlign="right"
              >
                Allocate Amount
              </Typography>
            </Box>
          }
        >
          {fields?.map((el, index) => (
            <CardListItem
              body={
                <ListItemText
                  primary={
                    <Box width="100%" display="flex">
                      <Typography
                        flex="1 1"
                        variant="inherit"
                        paddingRight="6px"
                      >
                        <Typography
                          className="text-xsTitle"
                          color="common.black"
                          component="span"
                          marginRight="6px"
                        >
                          {formatDate(el?.documentDate?.toString())} <br></br>
                          {el?.documentNo ? el?.documentNo : '-'}
                        </Typography>
                      </Typography>

                      <Typography
                        className="text-xsTitle"
                        color="common.black"
                        component="span"
                        flex="1 1"
                        variant="inherit"
                        textAlign="center"
                        padding="0 6px"
                      >
                        <NumberFormat
                          value={el?.outstanding}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                      </Typography>

                      <Typography
                        className="text-xsTitle list-form-input"
                        color="common.black"
                        component="span"
                        flex="1 1"
                        variant="inherit"
                        textAlign="right"
                        paddingLeft="6px"
                      >
                        <Controller
                          control={control}
                          name={`allocationList.${index}.allocateAmount`}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <NumberFormat
                              customInput={TextField}
                              label=""
                              name={`allocationList.${index}.allocateAmount`}
                              autoComplete="off"
                              onChange={event => {
                                const newValue = formatNumber(
                                  event.target.value
                                )
                                onChange(newValue)
                                el.allocateAmount = newValue
                                update(index, el)
                                watch('allocationList')?.map(el => {
                                  totalAmount += el?.allocateAmount
                                  setTotal(totalAmount)
                                  if (setTotalAmount) {
                                    setTotalAmount(totalAmount)
                                  }
                                })
                              }}
                              onBlur={onBlur}
                              value={value}
                              fullWidth
                              margin="none"
                              required
                              multiline={true}
                              thousandSeparator
                              decimalScale={2}
                              allowNegative={false}
                              fixedDecimalScale={true}
                              error={
                                errors?.allocationList?.[index]?.allocateAmount
                                  ? true
                                  : false
                              }
                              helperText={
                                errors?.allocationList?.[index]?.allocateAmount
                                  ?.message
                              }
                            />
                          )}
                        />
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <>
                      <Typography
                        component="div"
                        display="flex"
                        variant="inherit"
                        color="common.black"
                      >
                        <Typography
                          component="span"
                          className="text-desc"
                          variant="inherit"
                          flex="1"
                        >
                          {el?.description ?? '-'}
                        </Typography>
                      </Typography>
                    </>
                  }
                />
              }
            />
          ))}
        </CardListHeader>
      </form>
      {!isPage && (
        <CardListHeader
          title={
            <Box width="100%" display="flex">
              <Typography
                className="text-xsTitle"
                component="span"
                flex="1 1"
                variant="inherit"
                paddingRight="6px"
              >
                Total
              </Typography>

              <Typography
                className="text-xsTitle"
                component="span"
                flex="1 1"
                variant="inherit"
                textAlign="center"
                padding="0 6px"
              >
                {amtStr(totalOutstandingAmount)}
              </Typography>

              <Typography
                className="text-xsTitle"
                component="span"
                flex="1 1"
                variant="inherit"
                textAlign="right"
                paddingLeft="6px"
              >
                {amtStr(total)}
              </Typography>
            </Box>
          }
        />
      )}
    </>
  )
}
export default AllocationComponent
