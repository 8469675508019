import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
// import ProfileEditForm from './ProfileEditForm'

const ChangePassword = lazy(() => import('./ChangePassword'))
const Profile = lazy(() => import('./Profile'))
const ProfileEditForm = lazy(() => import('./ProfileEditForm'))

export const ACCT_PATH = {
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/change-password',
}

const AcctRoutes: RouteObject[] = [
  {
    path: '',
    element: <Profile />,
  },
  {
    path: 'edit',
    element: <ProfileEditForm />,
  },
  {
    path: 'change-password',
    element: <ChangePassword type="updatePassword" />,
  },
]

export default AcctRoutes
