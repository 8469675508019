import {
  Box,
  ButtonProps,
  Typography,
  DialogProps as MuiDialogProps,
} from '@mui/material'
import * as React from 'react'
import { Dialog as IfcaDialog, Subheader, SubheaderText } from '@ifca-ui/core'

export interface CustomDialog {
  header?: React.ReactNode
  body?: React.ReactNode
  footer?: {
    buttons: ButtonProps[]
  }
}

export interface DialogData {
  header?: HeaderTemplateData
  bodyText?: string
}

export interface HeaderTemplateData {
  title: HeaderTitle
  infoline?: InfoLine
}

export interface HeaderTitle {
  leftTopTitle: LeftTopTitle
  rightTopTitle?: Title
}

export interface LeftTopTitle {
  title: Title
  sideTitle?: Title
}

export interface InfoLine {
  leftBottomInfo: LeftBottomInfo
  rightBottomInfo?: Title
}

export interface LeftBottomInfo {
  infoline: Infoline[]
  seperator?: string
}

export interface Infoline extends Title {
  label?: string
}

export interface Title {
  text: any
  // icon?: string;
  // imageIcon?: string;
  // class?:string[];
}

export interface CustomDialogProps extends MuiDialogProps {
  useDefaultDialogHeader?: boolean
  useDefaultDialogBody?: boolean
  defaultDialogData?: DialogData
  header?: React.ReactNode
  body?: React.ReactNode
  footer?: {
    buttons: ButtonProps[]
  }
}

export const Dialog = ({
  useDefaultDialogHeader,
  useDefaultDialogBody,
  defaultDialogData,
  header,
  body,
  footer,
  ...props
}: CustomDialogProps) => {
  return (
    <IfcaDialog
      {...props}
      fullWidth={true}
      header={
        useDefaultDialogHeader ? (
          <Subheader sx={{ padding: '10px 12px' }}>
            <SubheaderText
              primary={
                <Box borderBottom="1px solid #fff">
                  <Box display="flex" margin="2px 0px" width="100%">
                    <Typography
                      className="text-xsTitle"
                      color="#FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      {
                        defaultDialogData?.header?.title?.leftTopTitle?.title
                          ?.text
                      }
                      <Typography
                        className="text-xsTitle"
                        color="#FF9800"
                        component="span"
                        variant="inherit"
                      >
                        {
                          defaultDialogData?.header?.title?.leftTopTitle
                            ?.sideTitle?.text
                        }
                      </Typography>
                    </Typography>
                    <Typography
                      className="text-xsTitle"
                      color="#FF9800"
                      component="span"
                      variant="inherit"
                    >
                      {defaultDialogData?.header?.title?.rightTopTitle?.text}
                    </Typography>
                  </Box>
                </Box>
              }
              secondary={
                <Box
                  color="#212121"
                  display="flex"
                  fontSize="10px"
                  fontWeight={700}
                  lineHeight="16px"
                  mt="2px"
                  width="100%"
                >
                  {defaultDialogData?.header?.infoline?.leftBottomInfo?.infoline?.map(
                    x => (
                      <Typography component="div" alignContent="center">
                        <Typography component="span" variant="inherit">
                          {x?.label}
                        </Typography>

                        <Typography component="span" variant="inherit">
                          {x?.text}
                        </Typography>

                        <Typography component="span" variant="inherit">
                          {defaultDialogData?.header?.infoline?.leftBottomInfo
                            ?.seperator || '|'}
                        </Typography>
                      </Typography>
                    )
                  )}

                  <Typography component="span" variant="inherit">
                    {defaultDialogData?.header?.infoline?.rightBottomInfo?.text}
                  </Typography>
                </Box>
              }
            />
          </Subheader>
        ) : (
          header
        )
      }
      body={
        useDefaultDialogBody ? (
          <Box width="100%" display="flex">
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-xsTitle"
            >
              {defaultDialogData?.bodyText}
            </Typography>
          </Box>
        ) : (
          body
        )
      }
      footer={footer}
    />
  )
}
