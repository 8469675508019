import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  TextField,
} from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

interface Props {
  control: any
  errors?: any
  label: string
  listData?: any
  name: string
  options?: any
  optionLabel: string
  isRequired?: boolean
  margin?: 'normal' | 'dense' | 'none'
  multiple?: boolean
  onChange?: (
    event: React.SyntheticEvent,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails
  ) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

const DropdownButton = ({
  control,
  errors,
  label,
  listData,
  name,
  options,
  optionLabel,
  isRequired = true,
  margin = 'normal',
  multiple = false,
  onChange: externalOnChange = () => {},
  onBlur: externalOnBlur = () => {},
}: Props) => {
  return (
    <Controller
      key={name}
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Autocomplete
          multiple={multiple}
          options={options || listData}
          getOptionLabel={option => option?.[optionLabel] || ''}
          fullWidth
          value={multiple ? value || [] : value || null} // Handle value based on 'multiple' prop
          onChange={(event, newValue, reason, details) => {
            onChange(newValue)
            externalOnChange(event, newValue, reason, details)
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={params => (
            <TextField
              {...params}
              label={`${label} ${isRequired ? '*' : ''}`}
              variant="standard"
              margin={margin}
              autoComplete="off"
              error={!!errors?.[name]} // use the actual field name for error checking
              helperText={errors?.[name]?.message}
              onBlur={event => {
                onBlur()
                externalOnBlur(event)
              }} // Pass onBlur to ensure it gets called
              inputRef={ref} // Attach ref to the input field
            />
          )}
        />
      )}
    />
  )
}

export default DropdownButton
