import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Container,
  FileAttachment,
  FilePreviewCard,
  FileUploadTextField,
  Footer,
  ListItem,
  ListItemText,
  MenuItem,
  useUploadAttachment,
} from '@ifca-ui/core'
import { PictureAsPdfSharp } from '@mui/icons-material'
import { Autocomplete, Avatar, Box, TextField, Typography } from '@mui/material'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import { DatePickerX } from 'components/Inputs/DatePickerX'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  AddRefundDataInput,
  AllocationList,
  CompanyBankList,
  CreditCardType,
  PaymentMethod,
  useAddPArefundMutation,
  useEditPArefundMutation,
  useGetCompanyBankAccountQuery,
  useGetPArefundByIdLazyQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { CommonYupValidation } from 'helpers/yup'
import { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import { allocationList } from '../Components/AllocationValidation'
import RefundAllocationComponent from './RefundAllocationComponent'

export interface currencyCodeProps {
  code: string
  name: string
}

interface refundProps extends allocationList {
  receiptDate: Date
  paymentMethod: string
  chequeDate?: Date
  chequeExpireDate?: Date
  creditCardType?: string
  referenceNo: string
  description: string
  bankAccount: CompanyBankList
}

export const PArefundForm = (props: any) => {
  let navigate = useNavigate()

  const { projectID, saleID, id }: any = useParams()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const {
    files,
    handleUploadChange,
    handleRemove,
    previewFiles,
    setFiles,
    getExistingFileRemovedAttachmentIDs,
    setExistingFile,
    uploadedFiles,
  } = useUploadAttachment()

  const [bankProfileList, setBankProfileList] = useState<CompanyBankList[]>([])
  const [totalAmount, setTotalAmount] = useState(0)
  const { navBack, navTo } = useNav()

  const formatNumber = amount => {
    if (amount) {
      return parseFloat(amount?.toString().replace(/,/g, ''))
    } else {
      return 0
    }
  }

  //#region schema validation
  const schema = yup.object({
    receiptDate: CommonYupValidation.requireDate('Date is required'),
    paymentMethod: CommonYupValidation.requireField(
      'Payment Method is required'
    ),
    referenceNo: CommonYupValidation.requireField('Reference No is required'),
    description: CommonYupValidation.requireField('Description is required'),
    bankAccount: CommonYupValidation.requireArray('Bank Account is required'),
    allocationList: CommonYupValidation.allocationList(),
    chequeDate: CommonYupValidation.date().when('paymentMethod', {
      is: value => value == PaymentMethod.Cheque,
      then: CommonYupValidation.requireDate('Cheque Date is required'),
    }),
    chequeExpireDate: CommonYupValidation.date().when('paymentMethod', {
      is: value => value == PaymentMethod.Cheque,
      then: CommonYupValidation.requireDate('Cheque Expiry Date is required'),
    }),
    creditCardType: CommonYupValidation.field().when('paymentMethod', {
      is: value => value == PaymentMethod.CreditCard,
      then: CommonYupValidation.requireField('Credit Card Type is required'),
    }),
  })
  //#endregion

  //#region init use form method
  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<refundProps>({
    mode: 'all',
    defaultValues: {
      allocationList: [] as any,
      bankAccount: null,
      creditCardType: '',
      description: '',
      paymentMethod: '',
      receiptDate: new Date(),
      referenceNo: '',
    },
    resolver: yupResolver(schema),
  })

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'allocationList',
  })
  //#endregion

  //#region init subheader height

  //#endregion

  //#region get company bank account

  //#endregion

  //#region payment method listing
  const paymentMethodListing = [
    {
      id: PaymentMethod.Cash,
      title: 'Cash',
    },
    {
      id: PaymentMethod.Cheque,
      title: 'Cheque',
    },
    {
      id: PaymentMethod.CreditCard,
      title: 'Credit Card/Debit Card',
    },
    {
      id: PaymentMethod.OnlineTransfer,
      title: 'Transfer/Manual Cheque',
    },
  ]
  //#endregion

  //#region credit card type listing
  const creditCardTypeListing = [
    {
      id: CreditCardType.Master,
      title: 'Master',
    },
    {
      id: CreditCardType.Visa,
      title: 'Visa',
    },
    {
      id: CreditCardType.Amex,
      title: 'Amex',
    },
    {
      id: CreditCardType.Diners,
      title: 'Diners',
    },
  ]
  //#endregion
  const {
    refetch: getCompanyBankAccountRefetch,
    data: { getCompanyBankAccount } = { getCompanyBankAccount: [] },
    loading: getCompanyBankAccountLoading,
    error: getCompanyBankAccountError,
  } = useGetCompanyBankAccountQuery({
    onCompleted: data => {
      setBankProfileList(data?.getCompanyBankAccount)
    },
  })
  //#region set cheque date when payment method onchange
  useEffect(() => {
    const paymentMethod = watch('paymentMethod')
    setValue('chequeDate', null)
    setValue('chequeExpireDate', null)
    setValue('creditCardType', '')
    if (paymentMethod == PaymentMethod.Cheque) {
      setValue('chequeDate', new Date())
      setValue('chequeExpireDate', new Date())
    }
  }, [watch('paymentMethod')])
  //#endregion

  const [
    getPArefund,
    {
      data: { getPArefundById } = { getPArefundById: null },
      loading: getPArefundByIdLoading,
    },
  ] = useGetPArefundByIdLazyQuery({})

  useEffect(() => {
    if (props.mode === 'edit') {
      getPArefund({
        variables: {
          id: id,
        },
      })
    }
  }, [props.mode])

  //#region project detail API
  const {
    loading: curProjectLoading,
    error: curProjectError,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })
  //#endregion

  useEffect(() => {
    if (getPArefundById) {
      setValue('receiptDate', getPArefundById?.documentDate)
      setValue('chequeDate', getPArefundById?.chequeDate)
      setValue('chequeExpireDate', getPArefundById?.chequeExpireDate)
      setValue('referenceNo', getPArefundById?.documentRef)
      setValue('description', getPArefundById?.description)
      setValue('paymentMethod', getPArefundById?.refundMethod)
      const defaultBank = bankProfileList?.find(
        el => el.bankAccountId == getPArefundById?.bankAccountId
      )
      setValue('bankAccount', defaultBank)
      handleEditUpload(getPArefundById.attachment)
    }
  }, [getPArefundById])
  //#region on submit
  const onSubmit = (data: refundProps) => submitApi(data, true)
  const saveAsDraft = (data: refundProps) => submitApi(data, false)

  const submitApi = (data: refundProps, isSubmitted) => {
    const allocation: AllocationList[] = data?.allocationList
      ?.filter(el => el.allocateAmount > 0)
      ?.map(item => {
        return {
          amount: item.allocateAmount,
          creditId: item.creditId,
          debitId: item.debitId,
          debitRefTable: 'refund',
          table: item.table,
        }
      })

    const paymentMethod = data.paymentMethod as PaymentMethod
    let extraCreditCardType
    if (data.paymentMethod == PaymentMethod.CreditCard) {
      const creditCardType = data?.creditCardType as CreditCardType
      extraCreditCardType = {
        creditCardType: creditCardType,
      }
    }
    const submitData: AddRefundDataInput = {
      ...extraCreditCardType,
      allocationList: allocation,
      attachments: uploadedFiles,
      bankAccountId: data.bankAccount.bankAccountId,
      chequeDate: data?.chequeDate,
      chequeExpireDate: data?.chequeExpireDate,
      description: data?.description,
      documentAmount: totalAmount,
      documentDate: data.receiptDate,
      documentRef: data.referenceNo,
      projectId: projectID,
      refundMethod: paymentMethod,
      saleId: saleID,
    }
    if (props.mode === 'add') {
      addPArefund({
        variables: {
          isSubmitted: isSubmitted,
          input: submitData,
        },
      })
    } else {
      editPArefund({
        variables: {
          isSubmitted: isSubmitted,
          input: {
            ...extraCreditCardType,
            allocationList: allocation,
            attachments: uploadedFiles,
            bankAccountId: data.bankAccount.bankAccountId,
            chequeDate: data?.chequeDate,
            chequeExpireDate: data?.chequeExpireDate,
            deleteAttachments: getExistingFileRemovedAttachmentIDs(),
            description: data?.description,
            documentAmount: totalAmount,
            documentDate: data.receiptDate,
            documentRef: data.referenceNo,
            id: id,
            projectId: projectID,
            refundMethod: paymentMethod,
            saleId: saleID,
          },
        },
      })
    }
  }
  //#endregion
  const [addPArefund, { loading: addLoading }] = useAddPArefundMutation({
    onError: error => {},
    onCompleted: () => {
      setSnackBar(SystemMsgs.createNewRecord())
      navBack()
    },
  })

  const [editPArefund, { loading: editLoading }] = useEditPArefundMutation({
    onCompleted: () => {
      setSnackBar(SystemMsgs.updateRecord())
      navBack()
    },
  })
  //#endregion

  const handleEditUpload = async (file: any) => {
    const existingFile: FileAttachment[] = []
    const prevFile = []
    //start (new)
    const newFiles = await Promise.all(
      file.map(async (x, i) => {
        const data = await fetch(x.resourceUrl, { mode: 'no-cors' })
          .then(res => res.blob())
          .then(file => new File([file], x.fileName, { type: x?.mediaType }))
        let temp: FileAttachment = {
          File: data,
          AttachmentID: x.id,
          ResourceUrl: x.resourceUrl,
        }
        prevFile.push(data)
        existingFile.push(temp)
      })
    )
    //end
    setExistingFile([...existingFile])
  }

  //#region init header/subheader
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'close',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 4,
            current: 'Refund',
          },
          rightText: props.mode === 'add' ? 'New' : 'Edit',
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])
  //#endregion

  return (
    <>
      <Container>
        <form id="submit-form">
          <Card>
            <Box width="100%" display="flex">
              <Box width="50%" pr="6px">
                <DatePickerX
                  control={control}
                  name="receiptDate"
                  label="Date"
                  required
                  helperText={errors?.receiptDate?.message}
                  error={!!errors?.receiptDate}
                />
              </Box>

              <Box width="50%" pl="6px">
                <Controller
                  control={control}
                  name="paymentMethod"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      select
                      name="paymentMethod"
                      label="Payment Method"
                      autoComplete="off"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      helperText={errors?.paymentMethod?.message}
                      error={errors?.paymentMethod ? true : false}
                    >
                      {paymentMethodListing?.map((el, index) => (
                        <MenuItem value={el.id} key={index}>
                          {el.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
            </Box>
            <Box width="100%" display="flex">
              <Controller
                control={control}
                name="bankAccount"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    options={bankProfileList}
                    getOptionLabel={option =>
                      `${option?.['bankProfileName']} | ${option?.['bankAccountCode']}`
                    }
                    renderOption={(props, option, { selected }) => (
                      <ListItem style={{ padding: '0' }} {...props}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              component="div"
                              className="text-xsTitle"
                              variant="inherit"
                              flex="1"
                            >
                              {`${option?.['bankProfileName']} | ${option?.['currency']}`}
                            </Typography>
                          }
                          secondary={
                            <Typography component="div">
                              <Typography
                                component="span"
                                className="text-icon text-desc"
                                variant="inherit"
                                color="#454545"
                              >
                                {`${option?.['bankAccountCode']} | ${option?.['bankAccountNo']}`}
                              </Typography>
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                    fullWidth
                    onChange={(event, value) => {
                      onChange(value)
                    }}
                    value={value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Bank Account"
                        required
                        variant="standard"
                        margin="normal"
                        value={value}
                        autoComplete="off"
                        helperText={errors?.bankAccount?.message}
                        error={errors?.bankAccount ? true : false}
                      />
                    )}
                  />
                )}
              />
            </Box>
            {watch('paymentMethod') == PaymentMethod.Cheque && (
              <Box width="100%" display="flex">
                <Box width="50%" pr="6px">
                  <DatePickerX
                    control={control}
                    name="chequeDate"
                    label="Cheque Date"
                    required
                    helperText={errors?.chequeDate?.message}
                    error={!!errors?.chequeDate}
                  />
                </Box>

                <Box width="50%" pl="6px">
                  <DatePickerX
                    control={control}
                    name="chequeExpireDate"
                    label="Cheque Expire Date"
                    required
                    helperText={errors?.chequeExpireDate?.message}
                    error={!!errors?.chequeExpireDate}
                  />
                </Box>
              </Box>
            )}

            {watch('paymentMethod') == PaymentMethod.CreditCard && (
              <Controller
                control={control}
                name="creditCardType"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    select
                    name="creditCardType"
                    label="Credit Card Type"
                    autoComplete="off"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    required
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={errors?.creditCardType?.message}
                    error={errors?.creditCardType ? true : false}
                  >
                    {creditCardTypeListing?.map((el, index) => (
                      <MenuItem value={el.id} key={index}>
                        {el.title}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            )}

            <Controller
              control={control}
              name="referenceNo"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Reference No"
                  name="referenceNo"
                  autoComplete="off"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  required
                  helperText={errors?.referenceNo?.message}
                  error={errors?.referenceNo ? true : false}
                />
              )}
            />
            <FileUploadTextField
              label="Upload Attachment(s)"
              autoComplete="off"
              multiple
              value={files}
              accept="image/gif,image/png,image/jpg,image/jpeg,application/pdf"
              onChange={e => handleUploadChange(e)}
              filePreview={
                <>
                  {previewFiles?.map((file, index) => (
                    <FilePreviewCard
                      key={index}
                      label={files[index]?.name}
                      badge={{
                        invisible: false,
                        onClick: () => {
                          handleRemove(index)
                          // uploadedFiles.reduce()
                        },
                      }}
                    >
                      {files[index]?.type?.includes('pdf') ? (
                        <PictureAsPdfSharp style={{ color: '#e2574c' }} />
                      ) : (
                        <Avatar variant="square" src={file} />
                      )}
                    </FilePreviewCard>
                  ))}
                </>
              }
            />
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Description"
                  name="description"
                  autoComplete="off"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  required
                  helperText={errors?.description?.message}
                  error={errors?.description ? true : false}
                />
              )}
            />
          </Card>
          {saleID && (
            <RefundAllocationComponent
              isPage={false}
              amount={0}
              documentAmount={0}
              saleId={saleID}
              id={id}
              fields={fields}
              append={append}
              control={control}
              errors={errors}
              watch={watch}
              update={update}
              remove={remove}
              setTotalAmount={setTotalAmount}
            />
          )}
        </form>
      </Container>
      <Footer
        buttons={[
          {
            children: 'Save as Draft',
            color: 'primary',
            onClick: handleSubmit(saveAsDraft),
          },
          {
            children: 'Submit',
            color: 'primary',
            onClick: handleSubmit(onSubmit),
          },
        ]}
      />
    </>
  )
}
export default PArefundForm
