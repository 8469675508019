import {
  Card,
  Container,
  EmptyState,
  List,
  ListItem,
  ListItemText
} from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'
import { getUserProfile } from 'UserData'
import { CardListHeader, CardListItem } from 'components/General/CardListItem'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetTaxSchemeByIdQuery,
  useGetTaxSchemeTaxQuery,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

const PaymentPlanView = () => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  let navigate = useNavigate()
  const { navBack, navTo } = useNav()

  const { id }: any = useParams()

  //--Start GraphQL--
  const {
    loading: getTaxSchemeByIdLoading,
    data: { getTaxSchemeById } = {
      getTaxSchemeById: null,
    },
  } = useGetTaxSchemeByIdQuery({
    variables: {
      id: id,
    },
    fetchPolicy: 'network-only',
  })

  const {
    loading: getTaxSchemeTaxLoading,
    data: { getTaxSchemeTax } = {
      getTaxSchemeTax: [],
    },
  } = useGetTaxSchemeTaxQuery({
    fetchPolicy: 'network-only',
  })
  //--End GraphQL--

  //#region header/subheader
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => {
              //   localStorage.removeItem('paymentPlanDetail')
              navBack()
            },
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${getUserProfile()?.subscriptionName}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: 'Tax Scheme',
          },
          // rightText: mode === 'add' ? 'New' : 'Edit',
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //#endregion

  return (
    <>
      <Container>
        <Card className="card-container-detail">
          <Box width="100%" boxSizing="border-box" marginBottom="14px">
            <Typography className="label-f">Name</Typography>
            <Typography className="value-f">
              {getTaxSchemeById?.name}
            </Typography>
          </Box>
          <Box width="100%" boxSizing="border-box" marginBottom="14px">
            <Typography className="label-f">Description</Typography>
            <Typography className="value-f">
              {getTaxSchemeById?.description ?? '-'}
            </Typography>
          </Box>
        </Card>

        <CardListHeader
          title={
            <Typography
              className="text-xsTitle"
              color="#FF9800"
              component="span"
              flex="1 1"
              variant="inherit"
            >
              Tax Scheme Detail
            </Typography>
          }
        >
          {!getTaxSchemeTaxLoading && (
            <>
              {getTaxSchemeById?.taxSchemeDetails === undefined ||
              getTaxSchemeById?.taxSchemeDetails?.length === 0 ? (
                <EmptyState title={'No Records'} />
              ) : (
                <>
                  {getTaxSchemeById?.taxSchemeDetails?.map((el, i) => {
                    return (
                      <ListItem key={el?.id}>
                        <ListItemText
                          primary={
                            <>
                              <Box borderBottom="1px solid #fff">
                                <Typography
                                  component="div"
                                  display="flex"
                                  variant="inherit"
                                  color="common.black"
                                >
                                  <Typography
                                    className="text-icon text-desc"
                                    component="span"
                                    flex="1"
                                    variant="inherit"
                                  >
                                    {formatDate(el?.effectiveDate.toString())}
                                  </Typography>
                                </Typography>
                              </Box>
                              <List>
                                {el.taxSchemeDetailItems?.map((x, i) => {
                                  return (
                                    <CardListItem
                                      key={x?.id}
                                      body={
                                        <>
                                          <ListItemText
                                            primary={
                                              <>
                                                <Typography
                                                  component="div"
                                                  display="flex"
                                                  variant="inherit"
                                                  color="common.black"
                                                >
                                                  <Typography
                                                    component="span"
                                                    className="text-desc"
                                                    variant="inherit"
                                                    flex="1"
                                                  >
                                                    {x?.seqNo}.&nbsp;
                                                    {
                                                      getTaxSchemeTax?.find(
                                                        e => e.taxId == x.taxId
                                                      )?.code
                                                    }
                                                    &emsp;
                                                    {
                                                      getTaxSchemeTax?.find(
                                                        e => e.taxId == x.taxId
                                                      )?.rate
                                                    }
                                                    %
                                                  </Typography>
                                                  <Typography
                                                    className="text-desc"
                                                    component="span"
                                                    variant="inherit"
                                                  >
                                                    {
                                                      getTaxSchemeTax?.find(
                                                        e => e.taxId == x.taxId
                                                      )?.schemeType
                                                    }
                                                  </Typography>
                                                </Typography>
                                                <Typography
                                                  component="div"
                                                  display="flex"
                                                  variant="inherit"
                                                  color="common.black"
                                                >
                                                  <Typography
                                                    component="span"
                                                    className="text-desc"
                                                    variant="inherit"
                                                    flex="1"
                                                  >
                                                    {x?.computeMethod}
                                                  </Typography>
                                                  <Typography
                                                    className="text-desc"
                                                    component="span"
                                                    variant="inherit"
                                                  >
                                                    {
                                                      getTaxSchemeTax?.find(
                                                        e => e.taxId == x.taxId
                                                      )?.classType
                                                    }
                                                  </Typography>
                                                </Typography>
                                              </>
                                            }
                                          />
                                        </>
                                      }
                                    />
                                  )
                                })}
                              </List>
                            </>
                          }
                        />
                      </ListItem>
                    )
                  })}
                </>
              )}
            </>
          )}
        </CardListHeader>
      </Container>
    </>
  )
}

export default PaymentPlanView
