import React, { lazy } from 'react'
const PropertyTitleListing = lazy(() => import('./PropertyTitleListing'))
const PropertyTitleForm = lazy(() => import('./PropertyTitleForm'))

const PropertyTitleRoutes = [
  {
    path: 'property-title',
    children: [
      {
        path: '',
        element: <PropertyTitleListing />,
      },
      {
        path: 'add',
        element: <PropertyTitleForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PropertyTitleForm mode="edit" />,
      },
    ],
  },
]

export default PropertyTitleRoutes
