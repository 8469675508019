import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const SolicitorListing = lazy(() => import('./SolicitorListing'))
const SolicitorDetail = lazy(() => import('./SolicitorDetail'))
const SolicitorForm = lazy(() => import('./SolicitorForm'))

const SolicitorRoutes: RouteObject[] = [
  { path: '', element: <SolicitorListing /> },
  {
    path: '',
    children: [
      { path: 'solicitor-form/add', element: <SolicitorForm mode="Add" /> },
      {
        path: 'solicitor-form/:id/edit',
        element: <SolicitorForm mode="Edit" />,
      },
      { path: 'solicitor-detail/:id', element: <SolicitorDetail /> },
    ],
  },
]

export default SolicitorRoutes
