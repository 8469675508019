import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const PaymentPlanListing = lazy(() => import('./PaymentPlanListing'))

export const DISCOUNT_PATH = {
  DISCOUNT_LISTING: '/project/:projectID/payment-plan',
}

const paymentPlanRoutes: RouteObject[] = [
  {
    path: 'payment-plan',
    element: <PaymentPlanListing />,
  },
]

export default paymentPlanRoutes
