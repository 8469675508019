import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import BookingListBIroutes from './Booking/BookingListBIroutes'
import CP_Routes from './CampaignPerformance/CP_Routes'
import loanBIroutes from './Loan/LoanBIroutes'
import OutstandingSnProutes from './OutstandingSnP/OutstandingSnProutes'
import ProjectSalesPerformanceRoutes from './ProjectSalesPerformance/Routes'
import SalesAgencyPerformanceRoutes from './SalesAgencyPerformance/Routes'
import SalesAnalysisRoutes from './SalesAnalysis/Routes'
import SLP_Routes from './SalesLeadPerformance/SLP_Routes'
import SalesMovementRoutes from './SalesMovement/Routes'
import SalesTeamPerformanceRoutes from './SalesTeamPerformance/Routes'
import ToPM_Routes from './ToPM/ToPM_Routes'
import WaitingListRoutes from './WaitingList/WaitingListRoutes'
import SalesAnalysisByUnitTypeRoutes from './SalesAnalysisByUnitType/Routes'
import SalesDemographicAnalysisRoutes from './SalesDemographicAnalysis/Routes'
import LotStatusMovementRoutes from './LotStatusMovement/Routes'

const ProjectBusinessInsightSubmenu = lazy(
  () => import('./ProjectBusinessInsightSubmenu')
)

const ProjectBusinessInsightRoutes: RouteObject[] = [
  {
    path: '/business-insight/:projectID',
    element: <ProjectBusinessInsightSubmenu />,
  },
  {
    path: '/business-insight/:projectID/',
    children: [
      ...ProjectSalesPerformanceRoutes,
      ...WaitingListRoutes,
      ...loanBIroutes,
      ...SalesMovementRoutes,
      ...OutstandingSnProutes,
      ...SalesAnalysisRoutes,
      ...BookingListBIroutes,
      ...ToPM_Routes,
      ...SLP_Routes,
      ...SalesTeamPerformanceRoutes,
      ...SalesAgencyPerformanceRoutes,
      ...CP_Routes,
      ...SalesAnalysisByUnitTypeRoutes,
      ...SalesDemographicAnalysisRoutes,
      ...LotStatusMovementRoutes,
    ],
  },
]

export default ProjectBusinessInsightRoutes
