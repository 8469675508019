import { lazy } from 'react'
import { RouteObject } from 'react-router'

const SalesMovementDetail = lazy(() => import('./SalesMovementDetail'))

export const SALES_MOVEMENT_PATH = {
  SUBMENU: '/business-insight/:projectID/sales-movement',
}

const SalesMovementRoutes: RouteObject[] = [
  {
    path: 'sales-movement',
    children: [
      {
        path: '',
        element: <SalesMovementDetail />,
      },
    ],
  },
]

export default SalesMovementRoutes
