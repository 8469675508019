import React from 'react'
import { AlignItemsEnum, JustifyContentEnum, OverflowEnum } from './LayoutXenum'

type GridXProps = {
  children?: React.ReactNode
  className?: string
  crossAxisAlignment?: AlignItemsEnum
  direction: 'row' | 'column'
  fullWidth?: boolean
  gap?: string
  gridTemplate: string
  height?: string
  mainAxisAlignment?: JustifyContentEnum
  overflow?: OverflowEnum
}

export const GridX = ({
  children,
  className,
  crossAxisAlignment,
  direction,
  fullWidth,
  gap,
  gridTemplate,
  height,
  mainAxisAlignment,
  overflow,
}: GridXProps) => {
  return (
    <div
      className={className}
      style={{
        alignItems: crossAxisAlignment,
        display: 'grid',
        gap: gap,
        gridTemplateColumns: direction === 'row' && gridTemplate,
        gridTemplateRows: direction === 'column' && gridTemplate,
        height: height,
        justifyContent: mainAxisAlignment,
        overflow: overflow,
        width: fullWidth ? '100%' : 'auto',
      }}
    >
      {children}
    </div>
  )
}
