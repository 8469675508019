import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const ReligionListing = lazy(() => import('./Religion/ReligionListing'))

const ProjectTypeListing = lazy(
  () => import('./ProjectType/ProjectTypeListing')
)

const PropertyTypeListing = lazy(
  () => import('./PropertyType/PropertyTypeListing')
)

const StandardParameterSubmenu = lazy(
  () => import('./StandardParameterSubmenu')
)

const UnitDirectionListing = lazy(
  () => import('./UnitDirection/UnitDirectionListing')
)

const UnitTypeListing = lazy(() => import('./UnitType/UnitTypeListing'))

const RaceListing = lazy(() => import('./Race/RaceListing'))

const MaritalStatusListing = lazy(
  () => import('./MaritalStatus/MaritalStatusListing')
)

const SalutationListing = lazy(() => import('./Salutation/SalutationListing'))

const ProfessionListing = lazy(() => import('./Profession/ProfessionListing'))

const DesignationListing = lazy(
  () => import('./Designation/DesignationListing')
)

const IndustryListing = lazy(() => import('./Industry/IndustryListing'))

const RelationshipListing = lazy(
  () => import('./Relationship/RelationshipListing')
)
const EducationListing = lazy(() => import('./Education/EducationListing'))

const IncomeRangeListing = lazy(
  () => import('./IncomeRange/IncomeRangeListing')
)

const StandardParameterRoutes: RouteObject[] = [
  {
    path: 'standard-parameter',
    element: <StandardParameterSubmenu />,
  },
  {
    path: 'standard-parameter',
    children: [
      {
        path: `salutation`,
        element: <SalutationListing />,
      },
      {
        path: `religion`,
        element: <ReligionListing />,
      },

      {
        path: 'project-type',
        element: <ProjectTypeListing />,
      },
      {
        path: `property-type`,
        element: <PropertyTypeListing />,
      },
      {
        path: `unit-direction`,
        element: <UnitDirectionListing />,
      },
      {
        path: `unit-type`,
        element: <UnitTypeListing />,
      },
      {
        path: `race`,
        element: <RaceListing />,
      },
      {
        path: `marital-status`,
        element: <MaritalStatusListing />,
      },
      {
        path: `profession`,
        element: <ProfessionListing />,
      },
      {
        path: `industry`,
        element: <IndustryListing />,
      },
      {
        path: `designation`,
        element: <DesignationListing />,
      },
      {
        path: `relationship`,
        element: <RelationshipListing />,
      },
      {
        path: `education`,
        element: <EducationListing />,
      },
      {
        path: `income-range`,
        element: <IncomeRangeListing />,
      },
    ],
  },
]

export default StandardParameterRoutes
