import React, { ReactNode, Suspense } from 'react'
import { FC } from 'react'
import { Navigate, Outlet, Route } from 'react-router'
import { getAccessToken } from '../../AccessToken'
import { useLocation } from 'react-router-dom'
import { Spinner } from '@ifca-ui/core'

export const AuthGuard = () => {
  const { pathname, search } = useLocation()
  const isLoginPage = pathname === '/login'
  const AccessToken = getAccessToken()

  if (isLoginPage && AccessToken) {
    const url = '/'
    return <Navigate to={url} replace />
  }

  if (AccessToken) {
    return (
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    )
  } else {
    // if(!isLoginPage){
    //   return <Navigate to="/login" replace/>
    // }

    return <Navigate to="/login" replace />
  }
}
