import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const PermitHistoryListing = lazy(() => import('./PermitHistoryListing'))

const PermitHistoryForm = lazy(() => import('./PermitHistoryForm'))

const permitHistoryRoutes: RouteObject[] = [
  {
    path: 'permit-history',
    children: [
      {
        path: '',
        element: <PermitHistoryListing />,
      },
      {
        path: 'add',
        element: <PermitHistoryForm mode="add" />,
      },
    ],
  },
]

export default permitHistoryRoutes
