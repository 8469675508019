import { CommonSettingBase } from 'helpers/CommonRoutes'
import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import companyRoutes from './Company/Routes'
import approvalPolicyRoutes from './ApprovalPolicy/Routes'
// import billItemRoutes from './BillItem/BillItemRoute';
import approvalPolicyAssignmentRoutes from './ApprovalPolicy/Routes'
import discountsRoutes from './Discounts/Routes'
// import eLetterRoutes from './ELettering/ELetterRoutes';
import paymentPlanRoutes from './PaymentPlan/Routes'
import eLetterRoutes from './StandardELettering/Routes'
// import calendarPolicyRoutes from './StateCalendar/StateCalendarRoutes';
import taxSetupRoutes from './TaxSetup/Routes'
import StandardParameterRoutes from '../StandardParameter/Routes'
import BillItemsListing from './BillItems/BillItemsListing'
import CurrencyExchangeRoutes from './CurrencyExchange/Routes'
import BankListing from './Bank/BankListing'
import taxSchemeRoutes from './TaxScheme/Routes'
import reasonCodeRoutes from './ReasonCode/Routes'
import eLetterPrintingRoutes from './DXServer/Routes'
import msicRoutes from './MsicCode/Routes'
import classificationCodeRoutes from './ClassificationCode/Routes'

const CommonSettingSubmenu = lazy(() => import('./CommonSettingSubmenu'))

const DiscountTypeListing = lazy(
  () => import('./DiscountType/DiscountTypeListing')
)

const ArchitectCertStagesListing = lazy(
  () => import('./ArchitectCertStages/ArchitectCertStagesListing')
)

const CancellationReasonListing = lazy(
  () => import('./ReasonCode/CancellationReasonListing')
)

const ApprovalPolicyListing = lazy(
  () => import('./ApprovalPolicy/ApprovalPolicyListing')
)

const LeadChannelListing = lazy(
  () => import('./LeadChannel/LeadChannelListing')
)

//#endregion

export const COMMON_SETTING_PATH = {
  COMMON_SETTING_SUBMENU: '/common-setting',
  DISCOUNT_TYPE: '/common-setting/discount-type',
  ARCHITECT_CERT_STAGES: '/common-setting/billing-stages',
  CANCELLATION_REASON: '/common-setting/cancellation-reason',
  APPROVAL_POLICY: '/common-setting/approval-policy',
  LEAD_CHANNEL: '/common-setting/lead-channel',
}

const CommonSettingRoutes: RouteObject[] = [
  {
    path: '',
    element: <CommonSettingSubmenu />,
  },
  {
    path: '',
    children: [
      ...companyRoutes,
      ...approvalPolicyRoutes,
      // // ...billItemRoutes,
      // // ...calendarPolicyRoutes,
      ...discountsRoutes,
      ...eLetterRoutes,
      ...paymentPlanRoutes,
      ...taxSetupRoutes,
      ...StandardParameterRoutes,
      ...CurrencyExchangeRoutes,
      ...taxSchemeRoutes,
      ...reasonCodeRoutes,
      ...eLetterPrintingRoutes,
      ...classificationCodeRoutes,
      ...msicRoutes,
      // ...approvalPolicyAssignmentRoutes,
    ],
  },
  {
    path: 'bill-items',
    element: <BillItemsListing />,
  },
  {
    path: 'bank',
    element: <BankListing />,
  },
  {
    path: 'discount-type',
    element: <DiscountTypeListing />,
  },
  {
    path: 'billing-stages',
    element: <ArchitectCertStagesListing />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: `${CommonSettingBase}/bill-item`,
  //   },
  //   component: <BillItemSubmenu />,
  // },
  {
    path: 'cancellation-reason',
    element: <CancellationReasonListing />,
  },
  {
    path: 'lead-channel',
    element: <LeadChannelListing />,
  },
]

export default CommonSettingRoutes
