import { Tooltip, Typography } from '@mui/material'

interface PBnumberCircleProps {
  data: any
}

const PBnumberCircle = ({ data }: PBnumberCircleProps) => {
  let colorKey
  let title

  if (data.has_posted) {
    colorKey = 'blue'
    title = 'Billed'
  } else if (data.has_architect_cert) {
    colorKey = '#ff9800'
    title = 'With Architecture Certificate'
  } else {
    colorKey = 'grey'
    title = 'Without Architecture Certificate'
  }

  const PBnumberCircleStyle = {
    backgroundColor: colorKey,
    color: 'white',
    cursor: 'pointer',
    minWidth: '15px',
    maxHeight: '15px', // Adding max-height of 15 pixels
    display: 'flex', // Center align horizontally
    justifyContent: 'center', // Center align horizontally
    alignItems: 'center', // Center align vertically
    borderRadius: '50%', // Use borderRadius to create a circular shape
  }

  const PBnumberCircleClassName = `text-desc`

  return (
    <Typography
      className={PBnumberCircleClassName}
      component="span"
      variant="inherit"
      style={PBnumberCircleStyle}
    >
      <Tooltip title={title} id="pbTooltip" placement="top-start">
        <div> {data.stage_seq_no}</div>
      </Tooltip>
    </Typography>
  )
}
export default PBnumberCircle
