import React, { lazy } from 'react'

const KeyCollectionContractorListing = lazy(
  () => import('./KeyCollectionContractorListing')
)
const KeyCollectionContractorDetail = lazy(
  () => import('./KeyCollectionContractorDetail')
)
const KeyCollectionContractorForm = lazy(
  () => import('./KeyCollectionContractorForm')
)

const keyCollectionContractorRoutes = [
  {
    path: 'collection-contractor',
    children: [
      {
        path: '',
        element: <KeyCollectionContractorListing />,
      },
      {
        path: ':id/detail',
        element: <KeyCollectionContractorDetail />,
      },
      {
        path: 'add',
        element: <KeyCollectionContractorForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <KeyCollectionContractorForm mode="edit" />,
      },
    ],
  },
]
export default keyCollectionContractorRoutes
