import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const LatePaymentInterestListing = lazy(
  () => import('./LatePaymentInterestListing')
)
const LatePaymentInterestForm = lazy(() => import('./LatePaymentInterestForm'))
const LatePaymentInterestEditForm = lazy(
  () => import('./LatePaymentInterestEditForm')
)
const LatePaymentInterestDetail = lazy(
  () => import('./LatePaymentInterestDetail')
)

const LatePaymentInterestRoutes: RouteObject[] = [
  {
    path: '',
    children: [
      { path: 'listing', element: <LatePaymentInterestListing /> },
      { path: 'add', element: <LatePaymentInterestForm /> },
      {
        path: ':id',
        children: [
          { path: 'edit', element: <LatePaymentInterestEditForm /> },
          { path: 'detail', element: <LatePaymentInterestDetail /> },
        ],
      },
    ],
  },
]

export default LatePaymentInterestRoutes
