import { lazy } from 'react'
import LADProcessingGenerateResult from './LADProcessingGenerateResult'

const LADProcessingListing = lazy(() => import('./LADProcessingListing'))

const LADProcessingForm = lazy(() => import('./LADProcessingForm'))

const LADProcessingSimulatedResult = lazy(
  () => import('./LADProcessingSimulatedResult')
)

const LADProcessingPostedResult = lazy(
  () => import('./LADProcessingPostedResult')
)

const LADProcessingRoutes = [
  {
    path: 'lad-processing',
    children: [
      { path: '', element: <LADProcessingListing /> },
      { path: 'add', element: <LADProcessingForm /> },
      {
        path: 'generate',
        element: <LADProcessingGenerateResult />,
      },
      { path: ':id/posted/result', element: <LADProcessingPostedResult /> },
      {
        path: ':id/simulated/result',
        element: <LADProcessingSimulatedResult />,
      },
    ],
  },
]

export default LADProcessingRoutes
