import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useRef, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const useAppData = () => {
  // const navigate = useNavigate()
  const navigateData = useLocation()?.state
  const subHeaderRef = useRef(null)
  const { projectID, saleID, id }: any = useParams()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { navBack, navTo, navReplace, navData } = useNav()

  return {
    id,
    projectID,
    rootDispatch,
    rootState,
    saleID,
    navigateData,
    subHeaderRef,
    navBack,
    navTo,
    navReplace,
    navData,
  }
}

export default useAppData
