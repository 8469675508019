import { yupResolver } from '@hookform/resolvers/yup'
import {
  Container,
  Dialog,
  EmptyState,
  FloatButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SearchBar,
  SubContainerX,
  Subheader,
  SubheaderText,
  useMenuOption,
} from '@ifca-ui/core'
import {
  Add,
  AttachFile,
  Audiotrack,
  Email,
  House,
  HouseOutlined,
  MoreVert as MoreVertIcon,
  Person,
  Phone,
  PictureAsPdfSharp,
} from '@mui/icons-material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {
  Box,
  IconButton,
  ListItemIcon,
  TextField,
  Typography,
} from '@mui/material'
import { CardListItem } from 'components/General/CardListItem'
import TextX from 'components/General/TextX'
import TooltipX from 'components/General/TooltipX'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { saveAs } from 'file-saver'
import {
  useDownloadAttachmentMutation,
  useGetCollectionActivityListingQuery,
  useGetCollectionActivitySummaryLazyQuery,
  useGetProjectByIdLazyQuery,
  useProjectDetailQuery,
  useUpdatePaCollectionActivityMutation,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/formatDate'
import { webShare } from 'helpers/webShare'
import { CommonYupValidation } from 'helpers/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'

interface UpdateProps {
  remark: string
}

// interface props {
//   // listData?: []
//   // tableRef?: 'String'
//   // refetch?: any
//   mode?: string
//   pageMode?: string
// }

const PACollectionActivityListing = (props: any) => {
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)

  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  const { mode, pageMode } = props
  const [projectData, setProjectData] = useState(null)
  const [unitNo, setUnitNo] = useState(null)
  const [purchaserName, setPurchaserName] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [isCompany, setIsCompany] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [officeTel, setOfficeTel] = useState(null)
  const [email, setEmail] = useState(null)
  const [outstanding, setOutstanding] = useState(null)
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const { projectID, saleID }: any = useParams()
  const [isDownload, setIsDownload] = useState<boolean>(false)
  const [attachmentData, setAttachmentData] = useState([] as any)
  const [testList, setTestList] = useState<any>([])
  const CollectionSchema = yup.object().shape({
    remark: CommonYupValidation.requireField(SystemMsgs.remark()),
  })

  //#region Yup Schema checker /////////////
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateProps>({
    defaultValues: {
      remark: menu?.data?.activityRemark,
    },
    mode: 'onSubmit',
    resolver: yupResolver(CollectionSchema),
  })
  //#endregion

  //#region GraphQL ///////////

  //Query for Collection Activity Listing /////////
  const {
    refetch,
    loading: collectionActivityLoading,
    data: { getCollectionActivityListing } = {
      collectionActivityList: [],
    },
  } = useGetCollectionActivityListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      saleId: saleID,
    },
  })

  //Lazy Query for Subheader /////////////////
  const [
    GetCollectionActivitySummary,
    {
      loading: getCollectionActivitySummaryLoading,
      data: { getCollectionActivitySummary } = {
        getCollectionActivitySummary: null,
      },
    },
  ] = useGetCollectionActivitySummaryLazyQuery({
    onCompleted: data => {
      setUnitNo(data.getCollectionActivitySummary?.purchaserDetail?.unitNo)
      setPurchaserName(
        data.getCollectionActivitySummary?.purchaserDetail?.purchaserName
      )
      setCompanyName(
        data.getCollectionActivitySummary?.purchaserDetail?.companyName
      )
      setIsCompany(
        data.getCollectionActivitySummary?.purchaserDetail?.isCompany
      )
      setOfficeTel(
        data.getCollectionActivitySummary?.purchaserDetail?.officeTel
      )
      setPhoneNumber(
        data?.getCollectionActivitySummary?.purchaserDetail?.phoneNo
      )
      setEmail(data?.getCollectionActivitySummary?.purchaserDetail?.email)
      setOutstanding(data?.getCollectionActivitySummary?.totalOutstanding)
    },
    onError: error => {},
  })

  useEffect(() => {
    GetCollectionActivitySummary({
      variables: {
        saleId: saleID,
      },
    })
  }, [])

  //Lazy Query for Project ID and Name
  const [
    getProjectID,
    {
      loading: projectIDLoading,
      data: { getProjectById } = {
        getProjectById: null,
      },
    },
  ] = useGetProjectByIdLazyQuery({
    onCompleted: data => {
      setProjectData(data.getProjectById?.name)
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  useEffect(() => {
    getProjectID({
      variables: {
        projectId: projectID,
      },
    })
  }, [])

  //Update Mutation
  const [updatePACollectionActivity, { loading: updateLoading }] =
    useUpdatePaCollectionActivityMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
      onCompleted: data => {
        if (data?.updatePACollectionActivity) {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: SystemMsgs.createNewRecord(),
            },
          })
          refetch()
          // setAddInfoDialog(false)
        }
      },
    })

  //Download Mutation
  const [
    downloadAttachments,
    { loading: downloadAttachmentLoading, error: downloadAttachmentError },
  ] = useDownloadAttachmentMutation({
    onCompleted: data => {
      if (data?.downloadAttachment) {
        let result = data?.downloadAttachment
        isDownload
          ? saveAs(result?.content, result?.fileName)
          : webShare(
              result?.fileName,
              '',
              '',
              result?.content,
              result?.fileName
            )
      }
    },
  })

  //Query Project detail
  const {
    loading: curProjectLoading,
    error: curProjectError,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })

  //#endregion

  //#region download
  const downloadAttachment = (attachmentUrl, attachmentName) => {
    downloadAttachments({
      variables: {
        input: {
          attachmentName: attachmentName,
          attachmentUrl: attachmentUrl,
        },
      },
    })
  }

  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()

  useEffect(() => {
    setOriginalListing(getCollectionActivityListing)
  }, [getCollectionActivityListing])

  //#region update activity dialog
  const [addInfoDialog, setAddInfoDialog] = React.useState(false)

  const handleDialogClosed = () => {
    setAddInfoDialog(false)
  }

  const addDialog = () => {
    setAddInfoDialog(true)
  }

  const onSubmit = (data: UpdateProps) => {
    updatePACollectionActivity({
      variables: {
        input: {
          id: menu.id,
          saleId: saleID,
          activityRemark: data.remark,
        },
      },
    })
    handleDialogClosed()
  }

  //#endregion

  //#region header/subheader //////////////
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
            // pageMode === 'purchaser'
            //   ? navigate(`/${projectID}/purchaser-accounts`, {
            //       state: {
            //         saleId: saleID,
            //       },
            //     })
            //   : navigate(`/${projectID}/credit-control/collection-activity`),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: 'Collection Activity',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])

  //#endregion //////////////

  //#region update activity dialog
  const AddInfoDialog = addInfoDialog && (
    <Dialog
      fullWidth={true}
      open={addInfoDialog}
      // onClose={() => setAddInfoDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle dialog-title-color"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    {`${formatDate(menu?.data?.activityDate)}, ${formatTime(
                      menu?.data?.activityDate
                    )}`}
                  </Typography>

                  <Typography
                    className="text-xsTitle dialog-title-color"
                    component="span"
                    variant="inherit"
                  >
                    {'Update Activity'}
                  </Typography>
                </Box>
              </Box>
            }
            secondary={
              <>
                <Box>
                  <Box display="flex" margin="2px 0px" width="100%">
                    <Typography
                      className="text-xsTitle "
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      {projectData}
                    </Typography>
                    <Typography
                      className="text-xsTitle dialog-title-color"
                      component="span"
                      variant="inherit"
                    >
                      {'test'}
                    </Typography>
                  </Box>
                </Box>
              </>
            }
          />
        </Subheader>
      }
      body={
        <Box width="100%" display="flex">
          <Controller
            control={control}
            name="remark"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                label="Remark"
                name="remark"
                autoComplete="off"
                error={errors?.remark ? true : false}
                helperText={errors?.remark?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                fullWidth
                // defaultValue={menu?.data?.activityRemark}
                margin="normal"
                variant="standard"
                required
              />
            )}
          />
        </Box>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => handleDialogClosed(),
          },
          {
            children: 'Save',
            color: 'primary',
            onClick: () => handleSubmit(onSubmit)(),
          },
        ],
      }}
    />
  )

  //#endregion

  //#region attachment dialog
  const [attachmentDialogStatus, setAttachmentDialogStatus] =
    React.useState(false)

  const handleAttachmentDialogClosed = () => {
    setAttachmentDialogStatus(false)
  }

  const handleAttachmentDialogOpen = (event, data) => {
    event.stopPropagation()
    setAttachmentData(data)
    setAttachmentDialogStatus(true)
  }

  const AttachmentDialog = attachmentDialogStatus && (
    <Dialog
      fullWidth={true}
      open={attachmentDialogStatus}
      // onClose={() => setAddInfoDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle dialog-title-color"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Attachments
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Box>
          {!attachmentData.attachment ||
          attachmentData?.attachment?.length === 0 ? (
            <EmptyState title="No Document Uploaded" />
          ) : (
            attachmentData?.attachment?.map(el => {
              return (
                <CardListItem
                  body={
                    <>
                      <Typography
                        className="digital-document-item-style"
                        component="span"
                      >
                        <Typography
                          component="span"
                          className="text-icon text-xsTitle"
                          variant="inherit"
                        >
                          <ListItemIcon
                            onClick={() => {
                              setIsDownload(true)
                              downloadAttachment(el?.resourceUrl, el?.fileName)
                            }}
                          >
                            {el?.mediaType === 'audio/ogg' ? (
                              <Audiotrack
                                style={{
                                  width: '30px',
                                  height: '30px',
                                }}
                              />
                            ) : el?.mediaType === 'application/pdf' ? (
                              <PictureAsPdfSharp
                                style={{
                                  color: 'red',
                                  width: '30px',
                                  height: '30px',
                                }}
                              />
                            ) : (
                              <img
                                className="item-img-style"
                                src={el?.resourceUrl}
                                alt={el?.fileName}
                              />
                            )}
                          </ListItemIcon>
                          &nbsp;
                        </Typography>
                        <Typography
                          className="text-icon text-xsTitle"
                          component="span"
                          variant="inherit"
                        >
                          {el?.fileName}
                        </Typography>
                      </Typography>
                    </>
                  }
                  itemButton={
                    <>
                      <IconButton>
                        <FileDownloadIcon
                          onClick={() => {
                            setIsDownload(true)
                            downloadAttachment(el?.resourceUrl, el?.fileName)
                          }}
                        />
                      </IconButton>
                    </>
                  }
                />
              )
            })
          )}
        </Box>
      }
      footer={{
        buttons: [
          {
            children: 'Close',
            color: 'primary',
            onClick: () => handleAttachmentDialogClosed(),
          },
        ],
      }}
    />
  )
  //#endregion

  return (
    <>
      {AddInfoDialog}
      {AttachmentDialog}
      <SubContainerX>
        <Subheader>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Typography
                  component="div"
                  display="flex"
                  className="text-xsTitle c-orange"
                >
                  <Typography
                    flex="1 1"
                    display="flex"
                    justifyContent={'unset'}
                    variant="inherit"
                    className="text-icon "
                  >
                    <House />
                    {unitNo}
                  </Typography>

                  <TooltipX title="Outstanding Amount" placement="left">
                    <TextX
                      data={outstanding}
                      dataType={'Number'}
                      className="text-icon"
                      color="red"
                    />
                  </TooltipX>
                </Typography>
              </Box>
            }
            secondary={
              <Box>
                <Typography
                  component="div"
                  display="flex"
                  className="text-xsTitle"
                >
                  <Typography
                    component="div"
                    flex="1 1"
                    display="flex"
                    justifyContent={'unset'}
                    variant="inherit"
                    className="text-icon "
                  >
                    <Person />
                    {!!!isCompany
                      ? purchaserName
                      : `${companyName} (${purchaserName})`}
                  </Typography>
                </Typography>
                <Typography
                  component="div"
                  display="flex"
                  className="text-xsTitle"
                >
                  <Typography
                    component="span"
                    // flex="1 1"
                    display="flex"
                    justifyContent={'unset'}
                    variant="inherit"
                    className="text-icon"
                  >
                    <Email />
                    {email}
                  </Typography>

                  <Typography
                    component="span"
                    display="flex"
                    justifyContent={'unset'}
                    variant="inherit"
                    className="text-icon"
                  >
                    {!!!isCompany ? (
                      <>
                        &nbsp;|&nbsp; <Phone />
                        {phoneNumber}
                      </>
                    ) : (
                      !!officeTel && (
                        <>
                          &nbsp;|&nbsp; <Phone />
                          {officeTel}
                        </>
                      )
                    )}
                  </Typography>
                </Typography>
              </Box>
            }
          />
        </Subheader>
        <Box>
          <SearchBar
            title={
              <Typography
                component="span"
                className="text-mdLabel"
                variant="inherit"
              >
                Collection Activity (
                <Typography
                  component="span"
                  variant="inherit"
                  className="text-mdLabel search-bar-count-f"
                >
                  {`${getCollectionActivityListing?.length ?? 0}`}
                </Typography>
                )
              </Typography>
            }
            searchInput={
              {
                // value: search,
                // onChange: e => {
                //   setSearchInput(e.target.value)
                //   //documentDate chenk
                //   handleSearch(e.target.value, [
                //     'documentDate',
                //     'description',
                //     'documentNo',
                //   ])
                // },
                // onChangeClearInput: () => {
                //   setSearchInput('')
                //   handleSearch('', [
                //     'documentDate',
                //     'description',
                //     'documentNo',
                //   ])
                // },
              }
            }
            isAdvanceSearch={false}
            isMic={false}
          />
        </Box>
      </SubContainerX>

      <Container>
        <List>
          {getCollectionActivityListing === undefined ||
          getCollectionActivityListing?.length === 0 ? (
            <EmptyState
              title="No Record Found"
              subTitle="Click Add Button to add a new record"
            />
          ) : (
            filteredList?.map((v, i) => {
              return (
                <ListItem
                  secondaryAction={
                    <IconButton
                      onClick={e => handleClick(e, v.id, i, v)}
                      disabled={v?.activityStatus === 'COMPLETE'}
                    >
                      <MoreVertIcon
                        className={
                          v?.activityStatus === 'COMPLETE' ? 'color-grey' : ''
                        }
                      />
                    </IconButton>
                  }
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        component="div"
                        display="flex"
                        variant="inherit"
                        color="common.black"
                      >
                        <Typography
                          component="span"
                          className="text-xsTitle"
                          variant="inherit"
                          flex="1"
                        >
                          {formatDate(v.activityDate)},{' '}
                          {formatTime(v.activityDate)}
                        </Typography>
                        <Typography
                          color={
                            v?.activityStatus == 'NEW'
                              ? 'RED'
                              : 'COMPLETE'
                                ? 'GREEN'
                                : ''
                          }
                          fontSize="10px"
                          // flex="1 1"
                          // variant="inherit"
                        >
                          {v.activityStatus}
                        </Typography>
                      </Typography>
                    }
                    secondary={
                      <Box width="100%" display="flex" margin="2px 0px">
                        <Typography
                          className="text-icon text-desc"
                          color="grey.600"
                          flex="1 1"
                          variant="inherit"
                          // marginLeft="6px"
                        >
                          {v.activityRemark}
                        </Typography>

                        <Typography
                          component="u"
                          className="text-icon text-xsTitle"
                          onClick={e => {
                            handleAttachmentDialogOpen(e, v)
                            setTestList(menu?.data?.attachment)
                          }}
                        >
                          <AttachFile />
                          {v?.attachment?.length}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              )
            })
          )}
        </List>
        <Menu
          id="default-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={menu?.data?.activityStatus === 'COMPLETE'}
            onClick={() => addDialog()}
          >
            Update Activity
          </MenuItem>
          <MenuItem
            disabled={menu?.data?.activityStatus === 'COMPLETE'}
            onClick={() =>
              pageMode === 'purchaser'
                ? navTo(
                    `/${projectID}/purchaser-accounts/${saleID}/collection-activity/edit/${menu?.id}`
                  )
                : navTo(
                    `/${projectID}/credit-control/collection-activity/${saleID}/edit/${menu?.id}`
                  )
            }
          >
            Reschedule Activity
          </MenuItem>
        </Menu>
        <FloatButton
          color="primary"
          onClick={() =>
            pageMode === 'purchaser'
              ? navTo(
                  `/${projectID}/purchaser-accounts/${saleID}/collection-activity/add`
                )
              : navTo(
                  `/${projectID}/credit-control/collection-activity/${saleID}/add`
                )
          }
        >
          <Add />
        </FloatButton>
      </Container>
    </>
  )
}

export default PACollectionActivityListing
