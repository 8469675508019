import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const LoanBIpage = lazy(() => import('./LoanBIpage'))
const LoanBIroutes: RouteObject[] = [
  {
    path: 'loan',
    children: [
      {
        path: '',
        element: <LoanBIpage />,
      },
    ],
  },
]

export default LoanBIroutes
