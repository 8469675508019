import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const LoanSettlementListing = lazy(() => import('./LoanSettlementListing'))
const LoanSettlementForm = lazy(() => import('./LoanSettlementForm'))
const LoanSettlementDetail = lazy(() => import('./LoanSettlementDetail'))

export const LOAN_SETTLEMENT_PATH = {
  LISTING: '/:projectID/bank-loan/loan-settlement',
  DETAIL: '/:projectID/bank-loan/loan-settlement/detail/:id',
  FORM_ADD: '/:projectID/bank-loan/loan-settlement/add',
}

const LoanSettlementRoutes: RouteObject[] = [
  {
    path: 'loan-settlement',
    children: [
      {
        path: '',
        element: <LoanSettlementListing />,
      },
      {
        path: 'detail/:id',
        element: <LoanSettlementDetail />,
      },
      {
        path: 'add',
        element: <LoanSettlementForm mode="add" />,
      },
      {
        path: 'edit/:id',
        element: <LoanSettlementForm mode="edit" />,
      },
    ],
  },
]

export default LoanSettlementRoutes
