import React, { lazy } from 'react'

const KeyCollectionScheduleSettingListing = lazy(
  () => import('./KeyCollectionScheduleSettingListing')
)

const KeyCollectionScheduleSettingDetail = lazy(
  () => import('./KeyCollectionScheduleSettingDetail')
)

const KeyCollectionScheduleSettingForm = lazy(
  () => import('./KeyCollectionScheduleSettingForm')
)

const keyCollectionSettingRoutes = [
  {
    path: 'schedule-setting',
    children: [
      {
        path: '',
        element: <KeyCollectionScheduleSettingListing />,
      },
      {
        path: 'add',
        element: <KeyCollectionScheduleSettingForm />,
      },
      // {
      //   path: 'edit/:id',
      //   element: <KeyCollectionScheduleSettingForm />,
      // },
      {
        path: 'detail/:id',
        element: <KeyCollectionScheduleSettingDetail />,
      },
    ],
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/:projectID/key-collection/schedule-setting',
  //   },
  //   component: <KeyCollectionScheduleSettingListing />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/:projectID/key-collection/schedule-setting/detail/:id',
  //   },
  //   component: <KeyCollectionScheduleSettingDetail />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/:projectID/key-collection/schedule-setting/add',
  //   },
  //   component: <KeyCollectionScheduleSettingForm mode="add" />,
  // },
]
export default keyCollectionSettingRoutes
