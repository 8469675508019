import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const RoleListing = lazy(() => import('./RoleListing'))
const RoleForm = lazy(() => import('./RoleForm'))
const RoleDetail = lazy(() => import('./RoleDetail'))

const roleRoutes: RouteObject[] = [
  {
    path: 'role',
    children: [
      {
        path: '',
        element: <RoleListing />,
      },
      {
        path: 'add',
        element: <RoleForm mode="add" />,
      },
      {
        path: 'edit/:roleID',
        element: <RoleForm mode="edit" />,
      },
      {
        path: 'detail/:roleID',
        element: <RoleDetail />,
      },
    ],
  },
]

export default roleRoutes
