import { Close as CloseIcon } from '@mui/icons-material'
import { Badge, type BadgeProps, Card, Typography } from '@mui/material'
import { Box } from '@mui/system'
import type React from 'react'

export interface FilePreviewCardProps {
  children: React.ReactNode
  /** File attachment label */
  badge?: BadgeProps
  label?: string
}
export const FilePreviewCard = ({
  label,
  children,
  badge,
}: FilePreviewCardProps): JSX.Element => {
  return (
    <Box
      width="100%"
      maxWidth="90px"
      mr="20px"
      mb="14px"
      lineHeight="0"
      data-testid="file-preview"
    >
      <Badge
        {...badge}
        sx={{
          '.MuiBadge-badge': {
            padding: '0px',
          },
          left: '90px',
        }}
        badgeContent={
          <CloseIcon
            fontSize="small"
            sx={{
              width: '14px',
              height: '14px',
            }}
          />
        }
        color="secondary"
      ></Badge>
      <Card
        sx={{
          display: 'flex',
          width: '90px',
          height: '70px',
          marginBottom: '4px',
          borderRadius: '3px',
          boxShadow:
            '0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            svg: {
              width: '40px',
              height: '40px',
            },
            // img: {
            //   width: '90px',
            //   height: '70px',
            // },
            '.MuiAvatar-root': {
              width: '90px',
              height: '70px',
            },
          }}
        >
          {children}
        </Box>
      </Card>
      {label && (
        <Typography
          noWrap
          component="div"
          variant="inherit"
          color="common.black"
          className="text-mdDesc"
          textAlign="center"
        >
          {label}
        </Typography>
      )}
    </Box>
  )
}
