import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const SalesCancellationListing = lazy(
  () => import('./SalesCancellationListing')
)
const SalesCancellationForm = lazy(() => import('./SalesCancellationForm'))
const SalesCancellationDetail = lazy(() => import('./SalesCancellationDetail'))

export const SALES_CANCELLATION_PATH = {
  LISTING: '/:projectID/sales-cancellation',
  DETAIL: '/:projectID/sales-cancellation/detail/:id',
  FORM_ADD: '/:projectID/sales-cancellation/add',
}

const SalesCancellationRoutes: RouteObject[] = [
  {
    path: 'sales-cancellation',
    children: [
      {
        path: '',
        element: <SalesCancellationListing />,
      },
      {
        path: 'detail/:id',
        element: <SalesCancellationDetail />,
      },
      {
        path: 'form/add',
        element: <SalesCancellationForm mode="add" />,
      },
      {
        path: 'form/edit/:id',
        element: <SalesCancellationForm mode="edit" />,
      },
    ],
  },
]

export default SalesCancellationRoutes
