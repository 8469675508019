import {
  COUNTRY,
  STATE_BY_COUNTRY,
  CITY_BY_STATE,
  POSTCODE_BY_CITY,
} from '../../containers/modules/Authentication/GraphQL/AccountQuery'
import { useQuery, useLazyQuery } from '@apollo/client'
import { AccountClient } from 'IFCAXClient'
import {
  useGetCityByStateLazyQuery,
  useGetCountryQuery,
  useGetPostCodeByCityLazyQuery,
  useGetStateByCountryLazyQuery,
  useGetStateByCountryQuery,
} from 'generated/graphql'

export const useCustomCountryQuery = () => {
  const { data: { getCountry } = { getCountry: [] } } = useGetCountryQuery({})

  const [
    handleGetStateByCountry,
    { data: { getStateByCountry } = { getStateByCountry: [] } },
  ] = useGetStateByCountryLazyQuery({})
  const [
    handleGetCityByState,
    { data: { getCityByState } = { getCityByState: [] } },
  ] = useGetCityByStateLazyQuery({})
  const [
    handleGetPostcodeByCity,
    { data: { getPostCodeByCity } = { getPostCodeByCity: [] } },
  ] = useGetPostCodeByCityLazyQuery({})

  return {
    getCountry, // country
    getStateByCountry, // State
    getCityByState, // City
    getPostCodeByCity, // Postcode
    handleGetStateByCountry, // variables : CountryID
    handleGetCityByState, // variables : StateID
    handleGetPostcodeByCity, // variables : StateID
  }
}
