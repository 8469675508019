import { lazy } from 'react'
import { RouteObject } from 'react-router'

const ApprovalFlowListing = lazy(() => import('./ApprovalFlowListing'))
const ApprovalFlowForm = lazy(() => import('./ApprovalFlowForm'))
const ApprovalFlowFormNoLimit = lazy(() => import('./ApprovalFlowFormNoLimit'))

const approvalRoutes: RouteObject[] = [
  {
    path: 'approval-flow',
    children: [
      {
        path: '',
        element: <ApprovalFlowListing />,
      },
      {
        path: ':category',
        element: <ApprovalFlowForm />,
      },
      {
        path: ':category/no-limit',
        element: <ApprovalFlowFormNoLimit />,
      },
    ],
  },
]

export default approvalRoutes
