import { yupResolver } from '@hookform/resolvers/yup'
import {
  Chip,
  Container,
  EmptyState,
  FloatButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SearchBar,
  Spinner,
  Subheader,
  SubContainerX,
  SubheaderText,
  useMenuOption,
} from '@ifca-ui/core'
import { Add, MoreVert } from '@mui/icons-material'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { getUserProfile } from 'UserData'
import { Dialog } from 'components/Dialogs/Dialog'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useActivateBankMutation,
  useAddBankMutation,
  useDeleteBankMutation,
  useEditBankMutation,
  useGetBankListQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/yup'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import * as yup from 'yup'

interface BankProps {
  name: string
  code: string
}

const BankListing = (props: any) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const [search, setSearchInput] = React.useState('')
  let navigate = useNavigate()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const open = Boolean(anchorEl)
  const [dialogMode, setDialogMode] = useState<any>('')
  const [addNew, setAddNew] = useState(false)
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const [isDeletable, setIsDeletable] = React.useState(false)
  const [message, setMessage] = useState('')

  const BankSchema = yup.object().shape({
    name: CommonYupValidation.requireField(SystemMsgs.name()),
    code: CommonYupValidation.requireField('Code is required'),
  })

  const showSnackbar = message => {
    rootDispatch({
      type: 'snackBar',
      payload: {
        open: true,
        message: message,
      },
    })
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<BankProps>({
    defaultValues: {
      name: dialogMode !== 'add' ? menu?.data?.name : '',
      code: dialogMode !== 'add' ? menu?.data?.code : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(BankSchema),
  })

  //--Start GraphQL--
  const {
    loading: getBankListLoading,
    refetch,
    data: { getBankList } = {
      getBankList: [],
    },
  } = useGetBankListQuery({
    fetchPolicy: 'network-only',
  })

  const [addBank, { loading: addBankLoading }] = useAddBankMutation({
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        showSnackbar(message)
      })
    },
    onCompleted: data => {
      if (data?.addBank) {
        showSnackbar(SystemMsgs.createNewRecord())
      }
      refetch()
      if (addNew) {
        addDialog('add')
      }
    },
  })

  const [editBank, { loading: editBankLoading }] = useEditBankMutation({
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        showSnackbar(message)
      })
    },
    onCompleted: data => {
      if (data?.editBank) {
        showSnackbar(SystemMsgs.updateRecord())
        refetch()
      }
    },
  })

  const [deleteBank, { loading: deleteBankLoading }] = useDeleteBankMutation({
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        showSnackbar(message)
      })
    },
    onCompleted: data => {
      if (data.deleteBank) {
        showSnackbar(message)
        setDeleteDialog(false)
        refetch()
      }
    },
  })

  const [activateBank, { loading: activateBankLoading }] =
    useActivateBankMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          showSnackbar(message)
        })
      },
      onCompleted: data => {
        if (data.activateBank) {
          showSnackbar('Succesfully activate record')
          setActivationDialog(false)
          refetch()
        }
      },
    })
  //--End GraphQL--

  const onSubmit = data => {
    if (dialogMode === 'add') {
      addBank({
        variables: {
          input: {
            name: data.name,
            code: data.code,
          },
        },
      })
    } else {
      editBank({
        variables: {
          input: {
            id: menu?.id,
            name: data.name,
            code: data.code,
          },
        },
      })
    }
    handleDialogClosed()
  }

  var deleteBankItem = () => {
    menu?.data?.isExistInTransactionData?.length == 0
      ? setMessage('Succesfully delete record')
      : setMessage('Succesfully deactivate record')
    setDeleteDialog(false)
    deleteBank({
      variables: {
        bankId: menu.id,
        isDeletable: isDeletable,
      },
    })
  }

  var activateBankItem = () => {
    setActivationDialog(false)
    activateBank({
      variables: {
        bankId: menu.id,
      },
    })
  }

  //#region add/edit dialog
  const [addInfoDialog, setAddInfoDialog] = React.useState(false)

  const onSaveAndNew = data => {
    onSubmit(data)
    setAddNew(true)
    setAddInfoDialog(true)
  }

  const addDialog = mode => {
    setAddInfoDialog(true)
    setDialogMode(mode)
    setAddNew(false)
  }

  const handleDialogClosed = () => {
    setAddInfoDialog(false)
    setDialogMode('')
    reset({
      name: '',
      code: '',
    })
  }

  const AddInfoDialog = addInfoDialog && (
    <Dialog
      fullWidth={true}
      open={addInfoDialog}
      onClose={() => setAddInfoDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Bank
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="primary.dark"
                    component="span"
                    variant="inherit"
                  >
                    {dialogMode === 'add' ? 'New' : 'Edit'}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <>
          <Box width="100%">
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Name"
                  autoComplete="off"
                  error={errors?.name ? true : false}
                  helperText={errors?.name?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  required
                />
              )}
            />
          </Box>
          <Box width="100%">
            <Controller
              control={control}
              name="code"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Code"
                  autoComplete="off"
                  error={errors?.code ? true : false}
                  helperText={errors?.code?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  required
                />
              )}
            />
          </Box>
        </>
      }
      footer={{
        buttons:
          dialogMode == 'edit'
            ? [
                {
                  children: 'Cancel',
                  color: 'primary',
                  onClick: () => handleDialogClosed(),
                },

                {
                  children: 'Save',
                  color: 'primary',
                  onClick: () => handleSubmit(onSubmit)(),
                },
              ]
            : [
                {
                  children: 'Cancel',
                  color: 'primary',
                  onClick: () => handleDialogClosed(),
                },
                {
                  children: 'Save & New',
                  color: 'primary',
                  onClick: () => handleSubmit(onSaveAndNew)(),
                },
                {
                  children: 'Save',
                  color: 'primary',
                  onClick: () => handleSubmit(onSubmit)(),
                },
              ],
      }}
    />
  )
  //#endregion

  //#region delete dialog
  const [deleteDialog, setDeleteDialog] = React.useState(false)
  const DeleteDialog = (
    <Dialog
      useDefaultDialogHeader={true}
      useDefaultDialogBody={true}
      open={deleteDialog}
      defaultDialogData={{
        header: {
          title: {
            leftTopTitle: {
              title: {
                text: 'Bank',
              },
            },
            rightTopTitle: {
              text: isDeletable ? 'Delete' : 'Deactivate',
            },
          },
        },
        bodyText: isDeletable
          ? 'Are you sure want to delete?'
          : 'Are you sure want to deactivate?',
      }}
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setDeleteDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            type: 'button',
            onClick: () => {
              deleteBankItem()
            },
          },
        ],
      }}
    />
  )
  //#endregion

  //#region activate dialog
  const [activationDialog, setActivationDialog] = React.useState(false)
  const ActivationDialog = (
    <Dialog
      useDefaultDialogHeader={true}
      useDefaultDialogBody={true}
      open={activationDialog}
      defaultDialogData={{
        header: {
          title: {
            leftTopTitle: {
              title: {
                text: 'Bank',
              },
            },
            rightTopTitle: {
              text: 'Activate',
            },
          },
        },
        bodyText: 'Are you sure want to activate?',
      }}
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setActivationDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            type: 'button',
            onClick: () => {
              activateBankItem()
            },
          },
        ],
      }}
    />
  )
  //#endregion

  //#region to setListing, and dialogData
  useEffect(() => {
    setOriginalListing(getBankList)
    if (dialogMode !== 'add') {
      reset({
        name: menu?.data?.name,
        code: menu?.data?.code,
      })
    } else {
      reset({
        name: '',
        code: '',
      })
    }
  }, [getBankList, dialogMode])
  //#endregion

  //#region header/subheader
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navigate(`/common-setting`),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${getUserProfile()?.subscriptionName}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Bank',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //#endregion

  return (
    <>
      {AddInfoDialog}
      {DeleteDialog}
      {ActivationDialog}

      <SubContainerX>
        <SearchBar
          title={
            <Typography
              component="span"
              className="text-mdLabel"
              variant="inherit"
            >
              {`Bank Listing`} (
              <Typography
                component="span"
                variant="inherit"
                className="search-bar-count-f"
              >
                {`${getBankList?.length ?? 0}`}
              </Typography>
              )
            </Typography>
          }
          searchInput={{
            value: search,
            onChange: e => {
              setSearchInput(e.target.value)
              handleSearch(e.target.value, ['name', 'code'])
            },
            onChangeClearInput: () => {
              setSearchInput('')
              handleSearch('', ['name', 'code'])
            },
          }}
        />
      </SubContainerX>

      <>
        <Container>
          <List>
            {getBankList === undefined || getBankList?.length === 0 ? (
              <EmptyState subTitle="Click add button to insert new records" />
            ) : (
              filteredList?.map((x, i) => {
                return (
                  <ListItem
                    style={{
                      background:
                        x?.commonStatus == 'INACTIVE' ? '#9b9b9b' : '',
                    }}
                    secondaryAction={
                      <IconButton onClick={e => handleClick(e, x.id, i, x)}>
                        <MoreVert />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          component="div"
                          display="flex"
                          variant="inherit"
                          color="common.black"
                        >
                          <Typography
                            component="span"
                            className="text-xsTitle"
                            variant="inherit"
                            flex="1"
                          >
                            {x.name}
                          </Typography>
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography
                            component="div"
                            display="flex"
                            variant="inherit"
                            color="common.black"
                          >
                            <Typography
                              component="div"
                              className="text-desc"
                              variant="inherit"
                              color="#454545"
                            >
                              {x.code}
                            </Typography>
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                )
              })
            )}
          </List>
          <FloatButton
            color="primary"
            onClick={() => {
              addDialog('add')
            }}
          >
            <Add />
          </FloatButton>
        </Container>

        <Menu
          id="default-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            onClick={() => {
              addDialog('edit')
            }}
          >
            Edit
          </MenuItem>

          {menu?.data?.isExistInTransactionData?.length === 0 &&
          menu?.data?.commonStatus == 'ACTIVE' ? (
            <MenuItem
              onClick={() => {
                setIsDeletable(true)
                setDeleteDialog(true)
                handleClose()
              }}
            >
              Delete
            </MenuItem>
          ) : menu?.data?.isExistInTransactionData?.length > 0 &&
            menu?.data?.commonStatus == 'ACTIVE' ? (
            <MenuItem
              onClick={() => {
                setIsDeletable(false)
                setDeleteDialog(true)
                handleClose()
              }}
            >
              Deactivate
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                setActivationDialog(true)
                handleClose()
              }}
            >
              Activate
            </MenuItem>
          )}
        </Menu>
      </>
    </>
  )
}

export default BankListing
