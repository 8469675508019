import { lazy } from 'react'
import { RouteObject } from 'react-router'
import LatePaymentInterestRoutes from '../LatePaymentInterest/Routes'

const LatePaymentInterestSettingSubmenu = lazy(
  () => import('./LatePaymentInterestSubmenu')
)
const LatePaymentInterestExceptionListing = lazy(
  () => import('./LatePaymentInterestException')
)
const LatePaymentInterestSetting = lazy(
  () => import('./LatePaymentInterestSetting')
)
const LatePaymentInterestExceptionForm = lazy(
  () => import('./LatePaymentInterestExceptionForm')
)

const LatePaymentInterestSettingRoutes: RouteObject[] = [
  {
    path: 'late-payment-interest',
    children: [
      {
        path: '',
        element: <LatePaymentInterestSettingSubmenu />,
      },
      ...LatePaymentInterestRoutes,
      {
        path: 'exception',
        children: [
          { path: '', element: <LatePaymentInterestExceptionListing /> },
          { path: 'add', element: <LatePaymentInterestExceptionForm /> },
        ],
      },
      {
        path: 'setting',
        element: <LatePaymentInterestSetting />,
      },
    ],
  },
]

export default LatePaymentInterestSettingRoutes
