import { RouteObject } from 'react-router'
import { lazy } from 'react'

const PAdebitNoteDetail = lazy(() => import('./PAdebitNoteDetail'))
const PAdebitNoteForm = lazy(() => import('./PAdebitNoteForm'))
const PAdebitNoteListingPage = lazy(() => import('./PAdebitNoteListingPage'))

//PA = purchaser's accounts
const PADebitNoteRoutes: RouteObject[] = [
  {
    path: 'debit-note',
    children: [
      {
        path: '',
        element: (
          <PAdebitNoteListingPage tableRef="DEBIT_NOTE" path="debit-note" />
        ),
      },
      {
        path: ':id/view',
        element: <PAdebitNoteDetail tableRef="DEBIT_NOTE" path="debit-note" />,
      },
      {
        path: 'add',
        element: <PAdebitNoteForm mode="add" tableRef="DEBIT_NOTE" />,
      },
      {
        path: ':id/edit',
        element: <PAdebitNoteForm mode="edit" tableRef="DEBIT_NOTE" />,
      },
    ],
  },
]

export default PADebitNoteRoutes
//
