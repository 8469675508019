import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const CarParkListing = lazy(() => import('./CarParkListing'))
const SetupForm1 = lazy(() => import('../Form/SetupForm1'))
const SetupForm2 = lazy(() => import('../Form/SetupForm2'))
const SetupForm3 = lazy(() => import('../Form/SetupForm3'))
const SetupForm4 = lazy(() => import('../Form/CarParkSetupForm4'))
const CarParkSetupEditForm = lazy(() => import('./CarParkSetupEditForm'))
const ImportUnitForm = lazy(() => import('../ImportUnit'))

export const CAR_PARK_PATH = {
  CAR_PARK_LISTING: 'car-park-listing',
  IMPORT_EXCEL: 'car-park-import',
  SETUP_FORM_1: 'car-park-setup-form-1',
  SETUP_FORM_2: 'car-park-setup-form-2',
  SETUP_FORM_3: 'car-park-setup-form-3',
  SETUP_FORM_4: 'car-park-setup-form-4',
}

const carParkSetupRoutes: RouteObject[] = [
  {
    path: 'car-park-listing',
    element: <CarParkListing />,
  },
  {
    path: 'car-park-import',
    element: <ImportUnitForm title="Car Park Setup" />,
  },
  {
    path: 'car-park-setup-form-edit',
    element: <CarParkSetupEditForm />,
  },
  {
    path: 'car-park-setup-form-1',
    element: <SetupForm1 isCarpark={true} />,
  },
  {
    path: 'car-park-setup-form-2',
    element: <SetupForm2 isCarpark={true} />,
  },
  {
    path: 'car-park-setup-form-3',
    element: <SetupForm3 isCarpark={true} />,
  },
  {
    path: 'car-park-setup-form-4',
    element: <SetupForm4 isCarpark={true} />,
  },
]

export default carParkSetupRoutes
