import {
  getCountries,
  getCountryCallingCode,
  validatePhoneNumberLength,
} from 'libphonenumber-js'
import moment from 'moment'
import * as yup from 'yup'
import { nricValidator } from './Validator/validatorHelper'
import { amtStr } from './numFormatter'
import { PhoneValidationService } from './phoneValidationHelper'
// Please refer to https://github.com/jquense/yup for more information.

export const CommonYupValidation = {
  field: () => {
    return yup
      .string()
      .transform(value => (value && value?.trim() != '' ? value : undefined))
      .nullable(true)
  },
  requireField: (msg?: string) => {
    return yup
      .string()
      .transform(value => (value && value?.trim() != '' ? value : undefined))
      .required(msg)
      .nullable(true)
  },
  email: () => {
    return CommonYupValidation.requireField('Email is required').email(
      'Email must be a valid email'
    )
  },
  password: () => {
    return yup
      .string()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /(?=.*[A-Z])/,
        'Password must contain at least one uppercase character'
      )
      .matches(
        /(?=.*[a-z])/,
        'Password must contain at least one lowercase character'
      )
      .matches(/(?=.*\d)/, 'Password must contain at least one number')
      .matches(
        /(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
        'Password must contain at least one symbol'
      )
  },
  confirmPassword: () => {
    return yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password'), null], 'Confirm password does not match')
  },
  contactNo: () => {
    return yup
      .string()
      .required('Contact No is required')
      .matches(
        /^(\+?6?0)\s[1][01-46-9][-][0-9]{4}[-][0-9]{3}$|^(\+?6?0)\s[1][1][-][0-9]{4}[-][0-9]{4}$/,
        'Contact No is not valid'
      )
  },
  mobileNo: () => {
    return yup
      .string()
      .required('Mobile No is required')
      .matches(
        /^(\+?6?0)\s[1][01-46-9][-][0-9]{4}[-][0-9]{3}$|^(\+?6?0)\s[1][1][-][0-9]{4}[-][0-9]{4}$/,
        'Mobile No is not valid'
      )
  },
  officeTelNo: () => {
    return yup
      .string()
      .required('Office Tel No is required')
      .matches(
        /^(\+?6?0)\s[1][01-46-9][-][0-9]{4}[-][0-9]{3}$|^(\+?6?0)\s[1][1][-][0-9]{4}[-][0-9]{4}$/,
        'Office Tel No is not valid'
      )
  },
  NRIC: () => {
    return yup
      .string()
      .required('Identity No is required')
      .test(`checkIdentityNoFormat`, function (value) {
        const valid = nricValidator(value.replaceAll('-', ''))
        if (valid != true) {
          return this.createError({
            message: valid.toString(),
            path: 'IdentityNo',
          })
        } else {
          return true
        }
      })
  },
  reminder: () => {
    return yup
      .number()
      .required('All field are required')
      .transform(value => (isNaN(value) ? undefined : value))
  },
  reminderWithChecking: (testErrorMsg: string, check) => {
    return yup
      .string()
      .required('All field are required')
      .test('checkSpaReminder', testErrorMsg, val => {
        return check
      })
  },

  requireArray: (msg?: string) => {
    return yup
      .object()
      .shape([] as any)
      .required(msg)
      .nullable(true)
  },
  requireMultiSelect: (msg?: string) => {
    return yup.array().min(1, msg).required(msg).nullable(true)
  },
  date: () => {
    return yup
      .date()
      .nullable()
      .test('checkValidDate', 'Invalid Date', val => {
        if (!val) return true
        return moment(val).isValid()
      })
    // .test('checkMinDate', (val, context) => {
    //   const startDate = moment('1900').startOf('year').toDate()
    //   const checkDate = isSameOrAfterDate(startDate, val)

    //   if (!checkDate) {
    //     return context.createError({
    //       message: 'Min Date 01-01-1900',
    //       path: context.path,
    //     })
    //   }

    //   return true
    // })
    // .test('checkMaxDate', (val, context) => {
    //   const endtDate = moment('2099').endOf('year').toDate()
    //   const checkDate = isSameOrBeforeDate(val, endtDate)

    //   if (!checkDate) {
    //     return context.createError({
    //       message: 'Max Date 31-12-2099',
    //       path: context.path,
    //     })
    //   }

    //   return true
    // })
  },
  requireDate: (msg?: string) => {
    return CommonYupValidation.date().required(msg)
  },
  number: () => {
    return yup.number().transform(value => (isNaN(value) ? undefined : value))
  },
  requireNumber: (msg?: string) => {
    return yup
      .number()
      .required(msg)
      .transform(value => (isNaN(value) ? undefined : value))
  },
  checkNumber: () => {
    return yup.number().transform(value => (isNaN(value) ? undefined : value))
  },
  allocationList: () => {
    const itemSchema = {
      allocateAmount: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .nullable()
        .when('outstanding', outstanding => {
          return yup
            .number()
            .transform(value => (isNaN(value) ? undefined : value))
            .nullable()
            .max(
              outstanding,
              `Allocate amount cannot more than ${amtStr(outstanding)}`
            )
        }),
    }

    return yup.array().of(yup.object().shape(itemSchema))
  },
  checkPhone: (required: boolean = false, requiredMsg?: string) => {
    let itemSchema = yup.string()

    if (required) {
      itemSchema = itemSchema.required(requiredMsg)
    }

    itemSchema = itemSchema.test(
      'phoneValidate',
      'Invalid Phone Number',
      val => {
        if (!val) {
          return true // Skip validation if the value is empty
        }
        // Extract the country code (up to 3 digits after '+')
        const countryCodeMatch = val.match(/^\+(\d{1,3})/)
        const countryCode = countryCodeMatch[1]

        // Check if the extracted country code is valid
        const countries = getCountries()
        const isValidCountryCode = countries.some(
          country => getCountryCallingCode(country) === countryCode
        )

        if (
          !required &&
          validatePhoneNumberLength(val) == 'TOO_SHORT' &&
          isValidCountryCode
        ) {
          return true
        }

        const phoneValidation = e => {
          return PhoneValidationService.isValidPoneNumber(
            PhoneValidationService.validatePhonePrefix(e)
          )
        }

        return phoneValidation(val)
      }
    )
    return itemSchema
  },
}
