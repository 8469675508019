import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import DeemCollectedRoutes from './DeemCollected/Routes'
import StakeholderReleaseRoutes from './StakeholderRelease/Routes'
import StakeholderUnitSummaryRoutes from './StakeholderUnitSummary/Routes'

const StakeholderListing = lazy(() => import('./StakeholderListing'))
const StakeholderDetail = lazy(() => import('./StakeholderDetail'))

const StakeholderRoutes: RouteObject[] = [
  {
    path: 'stakeholder',
    children: [
      {
        path: '',
        element: <StakeholderListing />,
      },
      {
        path: 'detail/:solicitorId',
        children: [
          {
            path: '',
            element: <StakeholderDetail />,
          },
          ...StakeholderUnitSummaryRoutes,
          ...DeemCollectedRoutes,
          ...StakeholderReleaseRoutes,
        ],
      },
    ],
  },
]

export default StakeholderRoutes
