import { projectName, TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useGetProjectNameLazyQuery } from 'generated/graphql'
import { useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { getUserProfile } from 'UserData'

interface Props {
  backPath?: string
  current: string
  maxItems: number
  rightText?: string
  title?: 'subscriptionName' | 'projectName' | string
}

const useHeader = ({
  backPath = '', // Default value for optional backPath
  current,
  maxItems,
  rightText = '', // Default value for optional rightText
  title,
}: Props) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { navBack } = useNav()
  const navigate = useNavigate()

  const [
    fetchProjectName,
    { data: { getProjectName } = { getProjectName: '' } },
  ] = useGetProjectNameLazyQuery({
    onCompleted: data => {
      if (data?.getProjectName) {
        localStorage.setItem('projectName', data.getProjectName)
      }
    },
  })

  // Memoize title to avoid unnecessary re-calculation
  const computedProjectName = useMemo(() => {
    if (title === 'subscriptionName') {
      return getUserProfile()?.subscriptionName || ''
    } else if (title === 'projectName') {
      let cachedProjectName = projectName()
      if (cachedProjectName) return cachedProjectName
      if (getProjectName) return getProjectName
      // Fetch from query
      fetchProjectName()
    } else {
      return title
    }
  }, [title, getProjectName])

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: { onClick: () => (backPath ? navigate(backPath) : navBack()) },
        },
        rightIcon: {
          // icon: 'switch',
          // Add functionality if required for the rightIcon
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: computedProjectName ?? '',
        },
        bottomSection: {
          breadcrumbs: {
            current,
            maxItems,
          },
          rightText,
        },
      },
    })
    // Ensure dependencies are correctly specified
  }, [computedProjectName])
}

export default useHeader
