import { useState } from 'react'
import { Dialog } from './Dialog'
interface props {
  title: string
  deleteAction: any
  id: string
}

export const useDeleteDialog = () => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const showDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const hideDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const DeleteDialogX = ({ title, deleteAction, id }: props) => (
    <Dialog
      useDefaultDialogHeader={true}
      useDefaultDialogBody={true}
      open={openDeleteDialog}
      onClose={() => setOpenDeleteDialog(false)}
      defaultDialogData={{
        header: {
          title: {
            leftTopTitle: {
              title: {
                text: title,
              },
            },
            rightTopTitle: {
              text: 'Delete',
            },
          },
        },
        bodyText: 'Are you sure want to delete?',
      }}
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setOpenDeleteDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            type: 'button',
            onClick: () => {
              if (deleteAction) {
                deleteAction(id)
                hideDeleteDialog()
              }
            },
          },
        ],
      }}
    />
  )

  return {
    showDeleteDialog,
    hideDeleteDialog,
    DeleteDialogX,
  }
}
// const { showDeleteDialog, DeleteDialog } = useDeleteDialog();
// <DeleteDialog
// onClick={() => {
//   // Handle delete logic here
// }}
// />
