import { Box, type BoxProps } from '@mui/system'
import { type CSSProperties, type FC } from 'react'
import { type BottomSpacing, type TopSpacing } from 'src/utils/types'

interface ContainerProps extends BoxProps {
  id?: string
  style?: CSSProperties
  className?: string
  topSpacing?: TopSpacing | undefined
  bottomSpacing?: BottomSpacing | undefined
}

const mobileTopSpacing = (topSpacing: TopSpacing): string => {
  switch (topSpacing) {
    case 'sm':
      return '38px'
    default:
      return 'unset'
  }
}
const desktopTopSpacing = (topSpacing: TopSpacing): string => {
  switch (topSpacing) {
    case 'sm':
      return '102px'
    default:
      return 'unset'
  }
}

const bottomSpacingStyle = (bottomSpacing: BottomSpacing): string => {
  switch (bottomSpacing) {
    case 'sm':
      return '78px'
    case 'md':
      return '82px'
    case 'lg':
      return '150px'
    default:
      return ''
  }
}

export const Container: FC<ContainerProps> = ({
  className,
  bottomSpacing,
  topSpacing,
  children,
  ...props
}) => {
  return (
    <Box
      className={`coreContainer ${className}`}
      padding="8px 6px"
      paddingBottom={bottomSpacingStyle(bottomSpacing as BottomSpacing)}
      marginTop={{
        xs: mobileTopSpacing(topSpacing as TopSpacing),
        sm: desktopTopSpacing(topSpacing as TopSpacing),
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
