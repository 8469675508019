import { lazy } from 'react'

import { useParams } from 'react-router'
const MiscBillingListing = lazy(
  () => import('../ProgressMiscBilling/MiscBilling/MiscBillingListing')
)

const PAprogressBillingListing = lazy(
  () => import('./ProgressBilling/PAprogressBillingListing')
)

export const PurchaserAccountsDetail = () => {
  const { type }: any = useParams()

  const components = {
    'progress-billing': {
      Component: <PAprogressBillingListing isPA={true} />,
    },
    'misc-billing': {
      Component: <MiscBillingListing />,
    },
  }

  return <>{components[type].Component}</>
}
