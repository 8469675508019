import { Subheader, SubheaderText } from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'
import React from 'react'
import TooltipX from './TooltipX'
import TextX from './TextX'

export interface iconField {
  icon?: React.ReactNode
  iconColor?: string
  className?: string
}

export interface textField {
  text?: React.ReactNode
  number?: string
  textColor?: string
  isNum: boolean
  className?: string
  textDecoration?: string
  justifyRight?: boolean
  tooltip?: string
}

export interface inputField {
  icon?: iconField
  label?: textField
}

export interface firstLine {
  left?: inputField
  middle?: inputField
  right?: inputField
}

export interface secondLine {
  left?: inputField
  middle?: inputField
  right?: inputField
}

export interface customLine {
  firstLine?: firstLine
  secondLine?: secondLine
}

export interface iconTextField {
  iconColor?: string
  iconClassName?: string
  icon?: React.ReactNode
  textColor?: string
  textClassName?: string
  isNum?: boolean
  number?: string
  text?: any
  justifyRight?: boolean
  textDecoration?: string
  tooltip?: string
}
export const CustomIconText = ({
  iconColor,
  iconClassName,
  icon,
  textColor,
  textClassName,
  isNum,
  number,
  text,
  justifyRight,
  textDecoration,
  tooltip,
}: iconTextField) => {
  return (
    <>
      <Typography
        component="div"
        flex="1"
        display="flex"
        justifyContent={justifyRight ? 'right' : 'unset'}
        variant="inherit"
      >
        <Typography
          component="span"
          color={iconColor}
          className={`text-icon ${iconClassName}`}
        >
          <span>{icon}</span>
        </Typography>
        <Typography
          component="span"
          color={textColor}
          className={`text-xsTitle ${textClassName}`}
          style={{
            textDecoration: textDecoration,
          }}
        >
          {tooltip ? (
            <TooltipX title={tooltip} placement="left">
              <TextX
                data={isNum ? number : text}
                dataType={isNum ? 'Number' : 'Text'}
              />
            </TooltipX>
          ) : (
            <TextX
              data={isNum ? number : text}
              dataType={isNum ? 'Number' : 'Text'}
            />
          )}
        </Typography>
      </Typography>
    </>
  )
}

export const CustomSubheader = ({ firstLine, secondLine }: customLine) => {
  return (
    <>
      <Subheader>
        <SubheaderText
          primary={
            <Box borderBottom="1px solid #fff">
              <Typography component="div" display="flex">
                {firstLine?.left && (
                  <CustomIconText
                    iconColor={firstLine?.left?.icon?.iconColor}
                    iconClassName={firstLine?.left?.icon?.className}
                    icon={firstLine?.left?.icon?.icon}
                    textColor={firstLine?.left?.label?.textColor}
                    textClassName={firstLine?.left?.label?.className}
                    isNum={firstLine?.left?.label?.isNum}
                    number={firstLine?.left?.label?.number}
                    text={firstLine?.left?.label?.text}
                    justifyRight={firstLine?.left?.label?.justifyRight}
                    textDecoration={firstLine?.left?.label?.textDecoration}
                  />
                )}

                {firstLine?.middle && (
                  <CustomIconText
                    iconColor={firstLine?.middle?.icon?.iconColor}
                    iconClassName={firstLine?.middle?.icon?.className}
                    icon={firstLine?.middle?.icon?.icon}
                    textColor={firstLine?.middle?.label?.textColor}
                    textClassName={firstLine?.middle?.label?.className}
                    isNum={firstLine?.middle?.label?.isNum}
                    number={firstLine?.middle?.label?.number}
                    text={firstLine?.middle?.label?.text}
                    justifyRight={firstLine?.middle?.label?.justifyRight}
                    textDecoration={firstLine?.middle?.label?.textDecoration}
                  />
                )}

                {firstLine?.right && (
                  <CustomIconText
                    iconColor={firstLine?.right?.icon?.iconColor}
                    iconClassName={firstLine?.right?.icon?.className}
                    icon={firstLine?.right?.icon?.icon}
                    textColor={firstLine?.right?.label?.textColor}
                    textClassName={firstLine?.right?.label?.className}
                    isNum={firstLine?.right?.label?.isNum}
                    number={firstLine?.right?.label?.number}
                    text={firstLine?.right?.label?.text}
                    justifyRight={firstLine?.right?.label?.justifyRight}
                    textDecoration={firstLine?.right?.label?.textDecoration}
                    tooltip={firstLine?.right?.label?.tooltip}
                  />
                )}
              </Typography>
            </Box>
          }
          secondary={
            <Typography component="div" display="flex">
              {secondLine?.left && (
                <CustomIconText
                  iconColor={secondLine?.left?.icon?.iconColor}
                  iconClassName={secondLine?.left?.icon?.className}
                  icon={secondLine?.left?.icon?.icon}
                  textColor={secondLine?.left?.label?.textColor}
                  textClassName={secondLine?.left?.label?.className}
                  isNum={secondLine?.left?.label?.isNum}
                  number={secondLine?.left?.label?.number}
                  text={secondLine?.left?.label?.text}
                  justifyRight={secondLine?.left?.label?.justifyRight}
                  textDecoration={secondLine?.left?.label?.textDecoration}
                />
              )}

              {secondLine?.middle && (
                <CustomIconText
                  iconColor={secondLine?.middle?.icon?.iconColor}
                  iconClassName={secondLine?.middle?.icon?.className}
                  icon={secondLine?.middle?.icon?.icon}
                  textColor={secondLine?.middle?.label?.textColor}
                  textClassName={secondLine?.middle?.label?.className}
                  isNum={secondLine?.middle?.label?.isNum}
                  number={secondLine?.middle?.label?.number}
                  text={secondLine?.middle?.label?.text}
                  justifyRight={secondLine?.middle?.label?.justifyRight}
                  textDecoration={secondLine?.middle?.label?.textDecoration}
                />
              )}

              {secondLine?.right && (
                <CustomIconText
                  iconColor={secondLine?.right?.icon?.iconColor}
                  iconClassName={secondLine?.right?.icon?.className}
                  icon={secondLine?.right?.icon?.icon}
                  textColor={secondLine?.right?.label?.textColor}
                  textClassName={secondLine?.right?.label?.className}
                  isNum={secondLine?.right?.label?.isNum}
                  number={secondLine?.right?.label?.number}
                  text={secondLine?.right?.label?.text}
                  justifyRight={secondLine?.right?.label?.justifyRight}
                  textDecoration={secondLine?.right?.label?.textDecoration}
                  tooltip={secondLine?.right?.label?.tooltip}
                />
              )}
            </Typography>
          }
        />
      </Subheader>
    </>
  )
}
