import {
  Container,
  EmptyState,
  FloatButton,
  Footer,
  List,
  ListItem,
  Menu,
  MenuItem,
  SearchBar,
  SelectionBar,
  SubContainerX,
  useMenuOption,
} from '@ifca-ui/core'
import {
  Add,
  CalendarMonthRounded,
  House,
  MoreVert,
  Person,
} from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { getUserProfile } from 'UserData'
import { useDeleteDialog } from 'components/Dialogs/useDeleteDialog'
import { CustomSubheader } from 'components/General/CustomSubheader'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useDeletePAdataMutation,
  useEditPAmoduleStatusMutation,
  useGetPArefundListLazyQuery,
  useGetPArefundSummaryQuery,
  useGetPaRefundSubmittedListQuery,
  useGetPurchaserDetailQuery,
  useProjectDetailQuery,
  useReversalPaRecordByIdMutation,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { PAdraftListComponent } from '../Components/PAdraftListComponent'
import { PAsubmitListComponent } from '../Components/PAsubmitListComponent'

const PArefundListingPage = (props: any) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  let navigate = useNavigate()

  const { projectID, saleID }: any = useParams()
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()

  const [selectedStatus, setSelectedStatus] = useState<string>('Draft')
  const selectionRef = useRef(null)
  const [search, setSearchInput] = React.useState('')
  const [statusAnchorEl, setStatusAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setStatusAnchorEl(event.currentTarget)
  }
  const statusMenuOpen = Boolean(statusAnchorEl)
  const handleStatusMenuClose = () => {
    setStatusAnchorEl(null)
  }
  const [idList, setIdList] = useState<string[]>([])

  const {
    anchorEl: menuAnchorEl,
    menu,
    handleClick: handleMenuClick,
    handleClose: handleMenuClose,
  } = useMenuOption()
  let location = useLocation()
  const stateData: any = location?.state
  const { navBack, navTo, navData } = useNav()
  const menuOpen = Boolean(menuAnchorEl)
  const { showDeleteDialog, DeleteDialogX } = useDeleteDialog()

  const path = `/${projectID}/purchaser-accounts/${saleID}/refund`
  // Start GraphQL
  const {
    loading: getPurchaserDetailLoading,
    refetch,
    data: { getPurchaserDetail } = {
      getPurchaserDetail: null,
    },
  } = useGetPurchaserDetailQuery({
    variables: {
      saleId: saleID,
    },
  })

  const {
    loading: getPArefundSummaryLoading,
    refetch: getPArefundSummarySummary,
    data: { getPArefundSummary } = {
      getPArefundSummary: null,
    },
  } = useGetPArefundSummaryQuery({
    variables: {
      projectId: projectID,
      saleId: saleID,
    },
  })

  const [
    reversalPARecordById,
    { loading: reversalPARecordByIdLoading, error: reversalPARecordByIdError },
  ] = useReversalPaRecordByIdMutation({
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        return rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
    onCompleted: data => {
      rootDispatch({
        type: 'snackBar',
        payload: {
          open: true,
          message: SystemMsgs.reverseRecord(),
        },
      })
      handleStatusMenuItemClick('Approved')
    },
  })

  const [
    getRefundList,
    {
      data: { getPArefundList } = {
        getPArefundList: null,
      },
      refetch: refetchPArefundListing,
      loading: getPArefundListLoading,
      error: getPArefundListError,
    },
  ] = useGetPArefundListLazyQuery({
    fetchPolicy: 'network-only',
  })

  const {
    refetch: refetchGetPARefundSubmittedList,
    data: { getPARefundSubmittedList } = {
      getPARefundSubmittedList: null,
    },
  } = useGetPaRefundSubmittedListQuery({
    variables: {
      saleId: saleID,
    },
  })

  const [editPAmoduleStatus, { loading: editPAmoduleStatusLoading }] =
    useEditPAmoduleStatusMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
      onCompleted: data => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: SystemMsgs.updateRecord(),
          },
        })
        idList.splice(0)
        setIdList([...idList])
        handleStatusMenuItemClick('Submitted')
        // navigate(`/${projectID}/purchaser-accounts/${saleID}/refund`, {
        //   state: 'Submitted',
        // })
        refetchPArefundListing()
        refetchGetPARefundSubmittedList()
      },
    })

  //#region project detail API
  const {
    loading: curProjectLoading,
    error: curProjectError,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })

  const [deletePAdata] = useDeletePAdataMutation({
    onCompleted: () => refetchPArefundListing(),
  })
  const deleteData = id => {
    deletePAdata({
      variables: {
        tableRef: 'refund',
        id: id,
        haveAllocation: true,
      },
    })
  }
  //#endregion

  // End GraphQL

  //#region to filter the listing based on the selectedStatus
  useEffect(() => {
    // if (stateData === 'Approved') return
    // if (stateData === 'Submitted') return
    getRefundList({
      variables: {
        saleId: saleID,
        status: selectedStatus.toUpperCase(),
      },
    })
  }, [selectedStatus])

  const handleStatusMenuItemClick = (statusType: string) => {
    setSelectedStatus(statusType)
    setStatusAnchorEl(null)
    getRefundList({
      variables: {
        saleId: saleID,
        status: statusType.toUpperCase(),
      },
    })
  }

  useEffect(() => {
    setOriginalListing(getPArefundList)
  }, [getPArefundList])

  useEffect(() => {
    if (stateData === 'Approved') {
      handleStatusMenuItemClick(stateData)
    } else if (stateData === 'Submitted') {
      handleStatusMenuItemClick(stateData)
    } else {
      handleStatusMenuItemClick('Draft')
    }
  }, [stateData])

  //#region header/subheader
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: 'Refund',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])
  //#endregion

  //#region to set status based on routeStack
  useEffect(() => {
    if (navData || navData?.['searchInput']) {
      setSelectedStatus(navData?.['status'])
      setSearchInput(navData?.['searchInput'])
      searchValue(navData?.['searchInput'])
    }
  }, [navData])
  //#endregion

  //#region search pa refund listing
  const searchValue = x => {
    handleSearch(x, ['documentDate', 'description', 'documentNo'])
  }
  //#endregion

  return (
    <>
      <DeleteDialogX deleteAction={deleteData} id={menu.id} title={'Refund'} />
      <SubContainerX>
        <CustomSubheader
          firstLine={{
            left: {
              icon: {
                icon: <House />,
                className: 'text-xsTitle',
              },
              label: {
                text: getPurchaserDetail?.unitNo,
                isNum: false,
                className: 'text-xsTitle',
              },
            },
            right: {
              label: {
                number: getPArefundSummary?.totalCharges.toString(),
                isNum: true,
                textColor: 'primary',
                className: 'text-desc',
                textDecoration: 'unset',
                justifyRight: true,
                tooltip: 'Total Charges',
              },
            },
          }}
          secondLine={{
            left: {
              icon: {
                icon: <Person />,
                className: 'text-desc',
              },
              label: {
                text: `${
                  !!getPurchaserDetail?.isCompany
                    ? `${getPurchaserDetail?.companyName} (${getPurchaserDetail?.purchaserName})`
                    : getPurchaserDetail?.purchaserName
                }`,
                isNum: false,
                className: 'text-desc',
                textDecoration: 'unset',
              },
            },
            right: {
              label: {
                number: getPArefundSummary?.totalUnallocated.toString(),
                isNum: true,
                textColor: 'red',
                className: 'text-desc',
                textDecoration: 'unset',
                justifyRight: true,
                tooltip: 'Total Unallocated',
              },
            },
          }}
        />
        <Box width="100%" display="flex" padding="8px 6px">
          <Box width="80%">
            <SearchBar
              title={
                <Typography
                  component="span"
                  className="text-mdLabel"
                  variant="inherit"
                >
                  Refund (
                  <Typography
                    component="span"
                    variant="inherit"
                    className="text-mdLabel search-bar-count-f"
                  >
                    {`${filteredList?.length ?? 0}`}
                  </Typography>
                  )
                </Typography>
              }
              searchInput={{
                value: search,
                onChange: e => {
                  setSearchInput(e.target.value)
                  //documentDate chenk
                  handleSearch(e.target.value, [
                    'documentDate',
                    'description',
                    'documentNo',
                  ])
                },
                onChangeClearInput: () => {
                  setSearchInput('')
                  handleSearch('', [
                    'documentDate',
                    'description',
                    'documentNo',
                  ])
                },
              }}
              isAdvanceSearch={false}
              isMic={false}
            />
          </Box>
          <Box width="30%" pl="6px" ref={selectionRef}>
            <SelectionBar
              title={
                <Typography
                  component="span"
                  className="text-mdLabel"
                  variant="inherit"
                >
                  {selectedStatus}
                </Typography>
              }
              selectionItem={{
                onClick: e => handleStatusClick(e),
              }}
            />
          </Box>
        </Box>
        <Menu
          id="default-menu"
          divRef={selectionRef}
          anchorEl={statusAnchorEl}
          open={statusMenuOpen}
          onClose={handleStatusMenuClose}
          MenuListProps={{
            role: 'listbox',
          }}
        >
          {['Draft', 'Submitted', 'Approved']?.map((option, index) => (
            <MenuItem
              key={index}
              onClick={e => handleStatusMenuItemClick(option)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </SubContainerX>

      <Container>
        <List>
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyState
              title="No Record found"
              subTitle="Click Add Button to add a new record"
            />
          ) : selectedStatus === 'Submitted' ? (
            <PAsubmitListComponent
              listData={getPARefundSubmittedList}
              tableRef={'refund'}
              handleStatusMenuItemClick={handleStatusMenuItemClick}
              idList={idList}
              setIdList={setIdList}
              path={'refund'}
            />
          ) : (
            filteredList?.map((v, index) => (
              <ListItem
                secondaryAction={
                  <IconButton onClick={e => handleMenuClick(e, v.id, index, v)}>
                    <MoreVert />
                  </IconButton>
                }
              >
                <PAdraftListComponent
                  firstLine={{
                    left: {
                      icon: <CalendarMonthRounded />,
                      text: `${formatDate(v?.documentDate) ?? '-'}`,
                    },
                    right: {
                      amount: v?.documentAmount,
                      color: 'primary',
                      tooltip: 'Document Amount',
                    },
                  }}
                  secondLine={
                    v?.status == 'APPROVED'
                      ? {
                          left: {
                            text: v?.documentNo,
                          },
                          right: {
                            amount: v?.unallocatedAmount,
                            color: 'secondary',
                            tooltip: 'Unallocated Amount',
                          },
                        }
                      : {
                          left: {
                            text: `${v?.description ? v?.description : '-'}`,
                          },
                        }
                  }
                  thirdLine={
                    v?.status == 'APPROVED'
                      ? {
                          left: {
                            text: `${v?.description ? v?.description : '-'}`,
                          },
                        }
                      : null
                  }
                />
              </ListItem>
            ))
          )}
        </List>

        {selectedStatus === 'Draft' && (
          <FloatButton
            color="primary"
            onClick={() =>
              navTo(
                `/${projectID}/purchaser-accounts/${saleID}/refund/add`,
                null,
                { status: 'Draft', searchInput: search }
              )
            }
          >
            <Add />
          </FloatButton>
        )}
        {selectedStatus === 'Submitted' &&
          !getUserProfile()?.superUser &&
          idList?.length > 0 && (
            <Footer
              buttons={[
                {
                  children: 'Reject',
                  color: 'secondary',
                  onClick: data => {
                    editPAmoduleStatus({
                      variables: {
                        isApproved: false,
                        sourceIds: idList,
                        tableRef: 'refund',
                      },
                    })
                  },
                },
                {
                  children: 'Approve',
                  color: 'primary',
                  onClick: data => {
                    editPAmoduleStatus({
                      variables: {
                        isApproved: true,
                        sourceIds: idList,
                        tableRef: 'refund',
                      },
                    })
                  },
                },
              ]}
            />
          )}
        <Menu
          id="default-menu"
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
        >
          {menu?.data?.status == 'DRAFT' && (
            <>
              <MenuItem
                onClick={() =>
                  navTo(`${path}/${menu?.id}/edit`, null, {
                    status: 'Draft',
                    searchInput: search,
                  })
                }
              >
                Edit
              </MenuItem>
              <MenuItem onClick={() => showDeleteDialog()}>Delete</MenuItem>
            </>
          )}
          {menu?.data?.status == 'APPROVED' && (
            <>
              <MenuItem
                onClick={() =>
                  navTo(`${path}/${menu?.id}/view`, null, {
                    status: 'Approved',
                    searchInput: search,
                  })
                }
              >
                View
              </MenuItem>
            </>
          )}
        </Menu>
      </Container>
    </>
  )
}

export default PArefundListingPage
