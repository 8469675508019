import React, { lazy } from 'react'

const ArchitectCertificatesListing = lazy(
  () => import('./ArchitectCertificatesListing')
)
const ArchitectCertificatesDetail = lazy(
  () => import('./ArchitectCertificatesDetail')
)
const ArchitectCertificatesForm = lazy(
  () => import('./ArchitectCertificatesForm')
)
const ArchitectCertificatesApproval = lazy(
  () => import('./ArchitectCertificatesApproval')
)
const ArchitectCertificatesReversal = lazy(
  () => import('./ArchitectCertificatesReversal')
)

const architectCertificatesRoutes = [
  {
    path: 'architect-certificates',
    children: [
      {
        path: '',
        element: <ArchitectCertificatesListing />,
      },
      {
        path: 'detail/:ID',
        element: <ArchitectCertificatesDetail />,
      },
      {
        path: 'add',
        element: <ArchitectCertificatesForm mode="add" />,
      },
      {
        path: ':id',
        children: [
          {
            path: 'edit',
            element: <ArchitectCertificatesForm mode="edit" />,
          },
          {
            path: 'approval',
            element: <ArchitectCertificatesApproval />,
          },
          {
            path: 'reversal',
            element: <ArchitectCertificatesReversal />,
          },
        ],
      },
    ],
  },
]

export default architectCertificatesRoutes
