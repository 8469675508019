import { lazy } from 'react'
import { RouteObject } from 'react-router'

const SalesAnalysisByUnitTypeDetail = lazy(
  () => import('./SalesAnalysisByUnitTypeDetail')
)

export const SALES_ANALYSIS_PATH = {
  SUBMENU: '/business-insight/:projectID/sales-analysis-by-unit-type',
}

const SalesAnalysisByUnitTypeRoutes: RouteObject[] = [
  {
    path: 'sales-analysis-by-unit-type',
    children: [
      {
        path: '',
        element: <SalesAnalysisByUnitTypeDetail />,
      },
    ],
  },
]

export default SalesAnalysisByUnitTypeRoutes
