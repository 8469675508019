import React, { lazy } from 'react'
import userRoutes from './User/Routes'
import roleRoutes from './Role/Routes'
import { RouteObject } from 'react-router'
const AccessSecuritySubmenu = lazy(() => import('./AccessSecuritySubmenu'))
const PasswordPolicyForm = lazy(
  () => import('./PasswordPolicy/PasswordPolicyForm')
)

export const ACCESS_SECURITY_PATH = {
  ROOT: '/access-security',
}
export const AccessSecurityBase = '/access-security'

const AccessSecurityRoutes: RouteObject[] = [
  {
    path: '',
    element: <AccessSecuritySubmenu />,
  },
  {
    path: 'password-policy',
    element: <PasswordPolicyForm />,
  },
  {
    path: '',
    children: [...userRoutes, ...roleRoutes],
  },
]

export default AccessSecurityRoutes
