import React, { lazy } from 'react'

const ProgressBillingListing = lazy(() => import('./ProgressBillingListing'))
const ProgressBillingDetail = lazy(() => import('./ProgressBillingDetail'))
const ProgressBillingForm = lazy(() => import('./ProgressBillingForm'))
const ProgressBillingEditForm = lazy(() => import('./ProgressBillingEditForm'))
const ProgressBillingPrint = lazy(() => import('./ProgressBillingPrint'))

const progressBillingRoutes = [
  {
    path: 'progress-billing',
    children: [
      {
        path: '',
        element: <ProgressBillingListing />,
      },
      {
        path: 'add',
        element: <ProgressBillingForm />,
      },
      {
        path: 'detail',
        element: <ProgressBillingDetail />,
      },
      {
        path: 'edit',
        element: <ProgressBillingEditForm />,
      },
      {
        path: 'print',
        element: <ProgressBillingPrint />
      },
    ],
  },
]

export default progressBillingRoutes
