import React, { lazy } from 'react'
import campaignRoutes from './Campaign/Routes'
import discountRoutes from './Discount/Routes'
import permitHistoryRoutes from './PermitHistory/Routes'
import projectPolicyRoutes from './ProjectPolicy/Routes'
import roleAuthorizationRoutes from './RoleAuthorization/Routes'
import salesPackagesRoutes from './SalesPackages/Routes'
import standardLetterRoutes from './StandardLetter/Routes'
import approvalRoutes from './ApprovalFlow/Routes'
import { RouteObject } from 'react-router'
import paymentPlanRoutes from './PaymentPlan/Routes'
import teamAssignmentRoutes from './TeamAssignment/Routes'
import unitRoutes from './Unit/Routes'
import unitPricingRoutes from './UnitPricing/Routes'
import projectReminderSettingRoute from './ProjectReminderSetting/Routes'

const ProjectListing = lazy(() => import('./ProjectListing'))
const ProjectForm = lazy(() => import('./ProjectForm'))
const ProjectEditForm = lazy(() => import('./ProjectEditForm'))
const ProjectSubmenu = lazy(() => import('./ProjectSubmenu'))
const ProjectDetail = lazy(() => import('./ProjectDetail'))
const ConstructionInfoForm = lazy(
  () => import('./ConstructionInfo/ConstructionInfoForm')
)
const FireInsuranceInfoForm = lazy(
  () => import('./FireInsuranceInfo/FireInsuranceForm')
)
const SalesOfficeInfoForm = lazy(
  () => import('./SalesOfficeInfo/SalesOfficeInfoForm')
)

export const PROJECT_PATH = {
  PROJECT_LISTING: '/project',
  PROJECT_FORM: '/project/add',
  PROJECT_EDIT_FORM: '/project/edit',
  PROJECT_SUBMENU: '/project/:projectID',
  PROJECT_DETAIL: '/project/:projectID/project-details',
  PROJECT_ROLE_AUTHORIZATION: '/project/:projectID/project-role-authorisation',
}

const ProjectRoutes: RouteObject[] = [
  {
    path: '',
    element: <ProjectListing />,
  },
  {
    path: '',
    children: [
      {
        path: 'add',
        element: <ProjectForm mode="add" />,
      },
      {
        path: ':projectID/edit',
        element: <ProjectEditForm mode="edit" />,
      },
      {
        path: ':projectID',
        children: [
          {
            path: '',
            element: <ProjectSubmenu />,
          },
          {
            path: 'project-details',
            element: <ProjectDetail />,
          },
          {
            path: 'construction-info',
            element: <ConstructionInfoForm />,
          },
          {
            path: 'fire-insurance-info',
            element: <FireInsuranceInfoForm />,
          },
          {
            path: 'sales-office-info',
            element: <SalesOfficeInfoForm />,
          },
          ...roleAuthorizationRoutes,
          ...projectPolicyRoutes,
          ...unitRoutes,
          ...salesPackagesRoutes,
          ...discountRoutes,
          ...campaignRoutes,
          ...approvalRoutes,
          ...standardLetterRoutes,
          ...permitHistoryRoutes,
          ...paymentPlanRoutes,
          ...teamAssignmentRoutes,
          ...unitPricingRoutes,
          ...projectReminderSettingRoute,
        ],
      },
    ],
  },
]

export default ProjectRoutes
