import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const MOTTrackingListing = lazy(() => import('./MOTTrackingListing'))
const MOTTrackingForm = lazy(() => import('./MOTTrackingForm'))
const MOTTrackingDetail = lazy(() => import('./MOTTrackingDetail'))

const MOTRoutes: RouteObject[] = [
  {
    path: 'mot-tracking',
    children: [
      {
        path: '',
        element: <MOTTrackingListing />,
      },
      {
        path: 'add',
        element: <MOTTrackingForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <MOTTrackingForm mode="edit" />,
      },
      {
        path: ':id/detail',
        element: <MOTTrackingDetail />,
      },
    ],
  },
]

export default MOTRoutes
