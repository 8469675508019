import { lazy } from 'react'
import { RouteObject } from 'react-router'

const ProjectSalesPerformanceDetail = lazy(
  () => import('./ProjectSalesPerformanceDetail')
)

export const PROJECT_SALES_PERFORANCE_PATH = {
  SUBMENU: '/business-insight/:projectID/project-sales-performance',
}

const ProjectSalesPerformanceRoutes: RouteObject[] = [
  {
    path: 'project-sales-performance',
    children: [
      {
        path: '',
        element: <ProjectSalesPerformanceDetail />,
      },
    ],
  },
]

export default ProjectSalesPerformanceRoutes
