export const navigateReactUrl = (initPath: any, path: any) => {
  return window.open(`${initPath}${path}`, '_blank')
}

export const mailTo = (email: string) => {
  var a = document.createElement('a')
  a.href = `mailto:${email}`
  a.click()
}

export const telTo = (phone: string) => {
  var a = document.createElement('a')
  a.href = `tel:${phone}`
  a.click()
}
