import { lazy } from 'react'
import { RouteObject } from 'react-router'

const TeamAssignmentListing = lazy(() => import('./TeamAssignmentListing'))
const TeamAssignmentForm = lazy(() => import('./TeamAssignmentForm'))

export const TEAM_ASSIGNMENT_PATH = {
  TEAM_ASSIGNMENT_LISTING: '/team-assignment',
}

const teamAssignmentRoutes: RouteObject[] = [
  {
    path: 'team-assignment',
    children: [
      {
        path: '',
        element: <TeamAssignmentListing />,
      },
      {
        path: ':teamID',
        element: <TeamAssignmentForm />,
      },
    ],
  },
]

export default teamAssignmentRoutes
