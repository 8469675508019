import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import ContractMgmtRoutes from '../ContractManagement/Routes'

const Home = lazy(() => import('./Home'))
const DemoForm = lazy(() => import('./DemoForm'))

export const HOME_PATH = {
  DEMO_FORM: '/demo/add',
  HOME: '/home',
}
const HomeRoutes: RouteObject[] = [
  {
    path: '',
    element: <Home />,
  },
  {
    path: 'demo/add',
    element: <DemoForm />,
  },
  // {
  //   path: 'contract-management',
  //   children: [...ContractMgmtRoutes],
  // },
]

export default HomeRoutes
