import { Tooltip, Typography } from '@mui/material'

interface PBnumberSquareProps {
  data: any
}
// square
// circle
const PBnumberSquarePro = ({ data }: PBnumberSquareProps) => {
  let colorKey
  let title
  if (data.isReversal) {
    colorKey = 'grey'
    title = ''
  } else if (data?.has_posted) {
    colorKey = 'blue'
    title = 'Billed'
  } else if (data.isAC && !data?.has_posted) {
    colorKey = '#ff9800'
    title = 'With Architecture Certificate'
  } else {
    colorKey = 'grey'
    title = 'Without Architecture Certificate'
  }

  const PBnumberSquareStyle = {
    backgroundColor: colorKey,
    color: 'white',
    cursor: 'pointer',
    minWidth: '15px',
    maxHeight: '15px', // Adding max-height of 15 pixels
    display: 'flex', // Center align horizontally
    justifyContent: 'center', // Center align horizontally
    alignItems: 'center', // Center align vertically
  }

  const PBnumberSquareClassName = `text-desc`

  return (
    <Typography
      className={PBnumberSquareClassName}
      component="span"
      variant="inherit"
      style={PBnumberSquareStyle}
    >
      <Tooltip title={title} id="pbTooltip" placement="top-start">
        <div> {data.stage_seq_no}</div>
      </Tooltip>
    </Typography>
  )
}
export default PBnumberSquarePro
