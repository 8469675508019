import { lazy } from 'react'
import { RouteObject } from 'react-router'
import PACollectionActivityForm from '../PurchaserAccounts/CollectionActivity/PACollectionActivityForm'
import PACollectionActivityListing from '../PurchaserAccounts/CollectionActivity/PACollectionActivityListing'
import CollectionActivityListing from './CollectionActivity/CollectionActivityListing'
import PaymentReminderSettingRoutes from './PaymentReminderSetting/Routes'
import LatePaymentInterestSettingRoutes from './LatePaymentInterestSetting/Routes'

const CreditControlSubmenu = lazy(() => import('./CreditControlSubmenu'))

export const CREDIT_CONTROL_PATH = {
  SUBMENU: '/:projectID/credit-control',
}

const CreditControlRoutes: RouteObject[] = [
  {
    path: 'credit-control',
    children: [
      {
        path: '',
        element: <CreditControlSubmenu />,
      },
      ...LatePaymentInterestSettingRoutes,
      ...PaymentReminderSettingRoutes,
      // ...CollectionActivityRoutes,
      {
        path: 'collection-activity',
        children: [
          {
            path: '',
            element: <CollectionActivityListing pageMode="credit" />,
          },

          {
            path: ':saleID',
            element: (
              <PACollectionActivityListing mode="test" pageMode="credit" />
            ),
          },
          {
            path: ':saleID/add',
            element: <PACollectionActivityForm mode="add" pageMode="credit" />,
          },

          {
            path: ':saleID/edit/:id',
            element: <PACollectionActivityForm mode="edit" pageMode="credit" />,
          },
        ],
      },
    ],
  },
]

export default CreditControlRoutes
