import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const BankPaymentListing = lazy(() => import('./BankPaymentListing'))
const BankPaymentForm = lazy(() => import('./BankPaymentForm'))
const BankPaymentEditForm = lazy(() => import('./BankPaymentEditForm'))
const BankPaymentSubmitted = lazy(() => import('./BankPaymentSubmitted'))
const BankPaymentApproved = lazy(() => import('./BankPaymentApproved'))

const BankPaymentRoutes: RouteObject[] = [
  {
    path: 'bank-payment',
    children: [
      {
        path: '',
        element: <BankPaymentListing />,
      },
      {
        path: 'form/add',
        element: <BankPaymentForm mode="add" />,
      },
      {
        path: 'form/edit',
        element: <BankPaymentEditForm />,
      },
      { path: 'submitted/:id', element: <BankPaymentSubmitted /> },
      { path: 'approved/:id', element: <BankPaymentApproved /> },
    ],
  },
]

export default BankPaymentRoutes
