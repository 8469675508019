import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import AreaVarianceSubmenuRoutes from 'containers/modules/AreaVariance/Routes'
import BankLoanRoutes from 'containers/modules/BankLoan/Routes'
import BridgingLoanRoutes from 'containers/modules/BridgingLoan/Routes'
import CreditControlRoutes from 'containers/modules/CreditControl/Routes'
import DownpaymentReminderRoutes from 'containers/modules/DownpaymentReminder/Routes'
import KeyCollectionRoutes from 'containers/modules/KeyCollection/Routes'
import LADRoutes from 'containers/modules/LAD/Routes'
import MOTRoutes from 'containers/modules/MOTTracking/Routes'
import ProgressMiscBillingRoutes from 'containers/modules/ProgressMiscBilling/Routes'
import PropertyTitleRoutes from 'containers/modules/PropertyTitle/Routes'
import SalesCancellationRoutes from 'containers/modules/SalesCancellation/Routes'
import SalesContractRoutes from 'containers/modules/SalesContracts/Routes'
import SpReminderRoutes from 'containers/modules/SPReminder/Routes'
import VacantPossessionRoutes from 'containers/modules/VacantPossession/Routes'
import PurchaserAccountsRoutes from '../PurchaserAccounts/Routes'
import CollectionRoutes from '../Collection/Routes'
import StakeholderRoutes from '../Stakeholder/Routes'
import SubsaleSubmenuRoutes from '../Subsale/Routes'
import UnitTransferRoutes from '../UnitTransfer/Routes'
import AccountJournalRoutes from '../AccountJournal/Routes'

const SalesAdministrationSubmenu = lazy(
  () => import('./SalesAdministrationSubmenu')
)

export const SALES_ADMINISTRATION_PATH = {
  ROOT: '/:projectID',
}

const SalesAdministrationRoutes: RouteObject[] = [
  {
    path: '',
    element: <SalesAdministrationSubmenu />,
  },
  {
    path: '',
    children: [
      ...AreaVarianceSubmenuRoutes,
      ...BankLoanRoutes,
      ...BridgingLoanRoutes,
      ...CreditControlRoutes,
      ...DownpaymentReminderRoutes,
      ...KeyCollectionRoutes,
      ...LADRoutes,
      ...MOTRoutes,
      ...SubsaleSubmenuRoutes,
      ...ProgressMiscBillingRoutes,
      ...PropertyTitleRoutes,
      ...SalesCancellationRoutes,
      ...SpReminderRoutes,
      ...VacantPossessionRoutes,
      ...SalesContractRoutes,
      ...PurchaserAccountsRoutes,
      // ...SubsaleRoutes,
      ...CollectionRoutes,
      ...StakeholderRoutes,
      ...UnitTransferRoutes,
    ],
  },
]

export default SalesAdministrationRoutes
