import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const CommonSettingBase = '/common-setting'

const DiscountsListing = lazy(() => import('./DiscountsListing'))
const DiscountsForm = lazy(() => import('./DiscountsForm'))

export const DISCOUNTS_PATH = {
  LISTING: `${CommonSettingBase}/discount`,
  FORM_ADD: `${CommonSettingBase}/discount/form/add`,
  FORM_EDIT: `${CommonSettingBase}/discount/form/edit`,
}

const DiscountsRoutes: RouteObject[] = [
  {
    path: 'discount',
    element: <DiscountsListing />,
  },
  {
    path: 'Discount',
    children: [
      {
        path: 'form/add',
        element: <DiscountsForm mode="add" />,
      },
      {
        path: 'form/:id/edit',
        element: <DiscountsForm mode="edit" />,
      },
    ],
  },
]

export default DiscountsRoutes
