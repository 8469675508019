import React, { lazy } from 'react'

const StandardMiscChargesListing = lazy(
  () => import('./StandardMiscChargesListing')
)
const StandardMiscChargesForm = lazy(() => import('./StandardMiscChargesForm'))

const standardMiscChargesRoutes = [
  {
    path: 'standard-misc-charges',
    children: [
      {
        path: '',
        element: <StandardMiscChargesListing />,
      },
      {
        path: 'add',
        element: <StandardMiscChargesForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <StandardMiscChargesForm mode="edit" />,
      },
    ],
  },
]

export default standardMiscChargesRoutes
