import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const BridgingFinancingListing = lazy(
  () => import('./BridgingFinancingListing')
)
const BridgingFinancingDetail = lazy(() => import('./BridgingFinancingDetail'))
const BridgingFinancingUnitForm = lazy(
  () => import('./BridgingFinancingUnitForm')
)

const BridgingFinancingRoutes: RouteObject[] = [
  {
    path: 'bridging-financing-setting',
    children: [
      { path: '', element: <BridgingFinancingListing /> },
      { path: 'detail/:id', element: <BridgingFinancingDetail /> },
      { path: 'add', element: <BridgingFinancingUnitForm /> },
    ],
  },
]

export default BridgingFinancingRoutes
