import React, { lazy } from 'react'
import LADProcessingRoutes from './LADProcessing/Routes'

const LADSubmenu = lazy(() => import('./LADSubmenu'))
const LADSettingForm = lazy(() => import('./LADSetting/LADSettingForm'))

const LADRoutes = [
  {
    path: 'lad',
    children: [
      {
        path: '',
        element: <LADSubmenu />,
      },
      { path: 'lad-setting', element: <LADSettingForm /> },
      ...LADProcessingRoutes,
    ],
  },
]
export default LADRoutes
