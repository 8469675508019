import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const CommonSettingBase = '/common-setting'

const ExchangeListing = lazy(() => import('./ExchangeListing'))
const CurrencyExchangeRoutes: RouteObject[] = [
  {
    path: 'currency',
    children: [
      {
        path: '',
        element: <ExchangeListing />,
      },
    ],
  },
]

export default CurrencyExchangeRoutes
