import { lazy } from 'react'
import { RouteObject } from 'react-router'
import { UnitPricingForm } from './UnitPricingForm'
const UnitPricingList = lazy(() => import('./UnitPricingList'))
const UnitPricingView = lazy(() => import('./UnitPricingView'))

const unitPricingRoutes: RouteObject[] = [
  {
    path: 'unit-pricing',
    element: <UnitPricingList />,
  },
  {
    path: 'unit-pricing/view/:id',
    element: <UnitPricingView />,
  },
  {
    path: 'unit-pricing/add',
    element: <UnitPricingForm />,
  },
  {
    path: 'unit-pricing/edit/:id',
    element: <UnitPricingForm />,
  },
]

export default unitPricingRoutes
