import { Card, Container, SubContainerX } from '@ifca-ui/core'
import { Grid, Typography } from '@mui/material'
import { CustomSubheader } from 'components/General/CustomSubheader'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { PaymentMethod, useGetPArefundByIdQuery } from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { AttachmentsListComponent } from '../Components/AttachmentsListComponent'

const PArefundDetail = (props: any) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { projectID, saleID, id }: any = useParams()
  let navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const { navBack, navTo } = useNav()

  // GraphQL start
  // Get PAmodule details start
  const {
    loading: getPArefundByIdLoading,
    data: { getPArefundById } = {
      getPArefundById: null,
    },
  } = useGetPArefundByIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: id,
    },
    onCompleted: data => {
      rootDispatch({
        type: 'headerComponent',
        payload: {
          ...rootState.headerComponent,
          leftIcon: {
            icon: 'back',
            props: {
              onClick: () => navBack(),
            },
          },
          rightIcon: {
            // icon: 'switch',
          },
          topSection: {
            smTitle: TopSectionHeader,
            title: `${data.getPArefundById.projectDetail?.name ?? '-'}`,
          },
          bottomSection: {
            breadcrumbs: {
              maxItems: 4,
              current: 'Refund',
            },
          },
        },
      })
    },
  })
  //--Get PAmodule details end--

  return (
    <>
      <SubContainerX>
        <CustomSubheader
          firstLine={{
            left: {
              label: {
                text: formatDate(getPArefundById?.documentDate),
                isNum: false,
                className: 'text-xsTitle',
                textColor: 'primary',
              },
            },
            right: {
              label: {
                number: getPArefundById?.documentAmount.toString(),
                isNum: true,
                textColor: 'primary',
                className: 'text-desc',
                textDecoration: 'unset',
                justifyRight: true,
                tooltip: 'Document Amount',
              },
            },
          }}
          secondLine={{
            left: {
              label: {
                text: getPArefundById?.documentNo,
                isNum: false,
                className: 'text-desc',
                textDecoration: 'unset',
              },
            },
            right: {
              label: {
                number: '',
                isNum: false,
                textColor: 'red',
                className: 'text-desc',
                textDecoration: 'unset',
                justifyRight: true,
              },
            },
          }}
        />
      </SubContainerX>

      <Container>
        <Card className="card-container-detail">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="label-f">Document Date</Typography>
              <Typography className="value-f">
                {formatDate(getPArefundById?.documentDate)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Document Ref</Typography>
              <Typography className="value-f">
                {getPArefundById?.documentRef}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Total Amount</Typography>
              <Typography className="value-f">
                {getPArefundById?.documentAmount}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Remarks</Typography>
              <Typography className="value-f">
                {getPArefundById?.description
                  ? getPArefundById?.description
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Refund Method</Typography>
              <Typography className="value-f">
                {getPArefundById?.refundMethod}
              </Typography>
            </Grid>
            {getPArefundById?.refundMethod === PaymentMethod.Cheque && (
              <>
                <Grid item xs={6}>
                  <Typography className="label-f">Cheque Date</Typography>
                  <Typography className="value-f">
                    {formatDate(getPArefundById?.chequeDate)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="label-f">Cheque Expiry</Typography>
                  <Typography className="value-f">
                    {formatDate(getPArefundById?.chequeExpireDate)}
                  </Typography>
                </Grid>
              </>
            )}
            {getPArefundById?.creditCardType && (
              <Grid item xs={12}>
                {' '}
                <Typography className="label-f">Remarks</Typography>
                <Typography className="value-f">
                  {getPArefundById?.creditCardType}
                </Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography className="label-f">Submitted Date</Typography>
              <Typography className="value-f">
                {getPArefundById?.submittedDate
                  ? formatDate(getPArefundById?.submittedDate)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Submitted By</Typography>
              <Typography className="value-f">
                {getPArefundById?.submitUserData?.name}
              </Typography>
            </Grid>{' '}
            <Grid item xs={6}>
              <Typography className="label-f">Approved Date</Typography>
              <Typography className="value-f">
                {getPArefundById?.approvedDate
                  ? formatDate(getPArefundById?.approvedDate)
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Approved By</Typography>
              <Typography className="value-f">
                {getPArefundById?.approveUserData?.name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className="label-f">Attachments</Typography>
              <Typography
                className="value-f text-underline text-xsTitle"
                onClick={e => setOpenDialog(true)}
              >
                {getPArefundById?.attachment.length}
              </Typography>
            </Grid>
          </Grid>

          <AttachmentsListComponent
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            listData={getPArefundById?.attachment}
          />
        </Card>
      </Container>
    </>
  )
}

export default PArefundDetail
