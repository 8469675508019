import { useMemo, useState } from 'react'
import AddressField from './AddressField'

interface AddressAutoCompleteProps {
  address: string
  country: any
  state: any
  city: any
  postcode: any
}
interface hideFieldProps {
  address?: boolean
  country?: boolean
  state?: boolean
  city?: boolean
  postcode?: boolean
}
interface props {
  isSubmitted?: boolean
  editDataAddress?: any
  isRequired?: boolean
  hideField?: hideFieldProps
  renameAddress?: string
}

export const useAddressField = ({
  isSubmitted,
  editDataAddress,
  isRequired = true,
  hideField = {
    address: false,
    country: false,
    state: false,
    city: false,
    postcode: false,
  },
  renameAddress = 'Address',
}: props) => {
  const [addressState, setAddressState] = useState<AddressAutoCompleteProps>({
    address: '',
    country: {},
    state: {},
    city: {},
    postcode: {},
  })

  const handleAddressSubmitValidation = () => {
    return (!addressState.address ||
      !addressState?.country?.name ||
      !addressState?.state?.name ||
      !addressState?.city?.name ||
      !addressState?.postcode?.postcode) &&
      isRequired
      ? false
      : true
  }

  const AddressFieldUI = useMemo(() => {
    return (
      <>
        <AddressField
          editDataAddress={editDataAddress}
          isSubmitted={isSubmitted}
          setAddressState={setAddressState}
          hideField={hideField}
          isRequired={isRequired}
          renameAddress={renameAddress}
        ></AddressField>
      </>
    )
  }, [isSubmitted, editDataAddress])

  return {
    addressState,
    setAddressState,
    AddressFieldUI,
    handleAddressSubmitValidation,
  }
}
