import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const DeemCollectedListing = lazy(() => import('./DeemCollectedListing'))
const DeemCollectedDetail = lazy(() => import('./DeemCollectedDetail'))
const DeemCollectedForm = lazy(() => import('./DeemCollectedForm'))

const DeemCollectedRoutes: RouteObject[] = [
  {
    path: 'deem-collected',
    children: [
      {
        path: '',
        element: <DeemCollectedListing />,
      },
      {
        path: ':id/detail',
        element: <DeemCollectedDetail mode="detail" />,
      },
      {
        path: ':id/post',
        element: <DeemCollectedDetail mode="post" />,
      },
      {
        path: 'add',
        element: <DeemCollectedForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <DeemCollectedForm mode="edit" />,
      },
    ],
  },
]

export default DeemCollectedRoutes
