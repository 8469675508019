import {
  ListItemText as MuiListItemText,
  type ListItemTextProps,
} from '@mui/material'

export const ListItemText = ({ ...props }: ListItemTextProps): JSX.Element => {
  return (
    <MuiListItemText
      {...props}
      sx={{
        mt: '0px',
        mb: '0px',
      }}
    />
  )
}
