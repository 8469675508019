import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const RedemptionRepaymentListing = lazy(
  () => import('./RedemptionRepaymentListing')
)
const RedemptionRepaymentDetail = lazy(
  () => import('./RedemptionRepaymentDetail')
)

const RedemptionRepaymentForm = lazy(() => import('./RedemptionRepaymentForm'))
const RedemptionRepaymentEditForm = lazy(
  () => import('./RedemptionRepaymentEditForm')
)

const RedemptionRepaymentRoutes: RouteObject[] = [
  {
    path: 'redemption-repayment',
    children: [
      { path: '', element: <RedemptionRepaymentListing /> },
      { path: 'detail/:id', element: <RedemptionRepaymentDetail /> },
      { path: 'add', element: <RedemptionRepaymentForm /> },
      { path: 'edit/:id', element: <RedemptionRepaymentEditForm /> },
    ],
  },
]

export default RedemptionRepaymentRoutes
