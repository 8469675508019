import { getAccessToken, setAccessToken } from 'AccessToken'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Observable,
  HttpLink,
  RequestHandler,
  Operation,
  NextLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { navigateTo } from 'helpers/navigationSubjectHelper'
import jwtDecode from 'jwt-decode'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { AUTH_PATH } from './containers/modules/Authentication/Routes'
import { createUploadLink } from 'apollo-upload-client'
import { setLoading } from 'helpers/Messages/loadingHelper'
import moment from 'moment'
import { setContext } from '@apollo/client/link/context'

// const apolloCache = new UploadInMemoryCache({
//   addTypename: false,
// })

const cache = new InMemoryCache({})

/////////////////////m////////////////////////////////////////////////////////////////////
// @account-node

let propertyXNodeUrl = import.meta.env.VITE_API_URL
let apiNum: number = 0

export let GoogleAPIKey = import.meta.env.VITE_GoogleAPIKey
export let PaymentUrl = import.meta.env.VITE_PAYMENT_URL
export const propertyXRefreshUrl = import.meta.env.VITE_REFRESH_URL

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken()
          setLoading(true)
          apiNum++
          if (accessToken) {
            //TODO: might need to do as a service for project
            const project = localStorage.getItem('latestProject')
            operation.setContext({
              headers: {
                authorization: `Bearer ${accessToken}`,
                ProjectId: project,
              },
            })
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        deductApiCount()
        if (handle) handle.unsubscribe()
      }
    })
)

const responseData = new ApolloLink((operation, forward) => {
  // deductApiCount()
  return forward(operation).map(response => {
    let resultResponse = JSON.parse(JSON.stringify(response))
    return resultResponse
  })
})

const uploadLink = createUploadLink({
  uri: propertyXNodeUrl,
  credentials: 'include',
})

const deductApiCount = () => {
  if (apiNum > 0) {
    --apiNum
  }
  if (apiNum === 0) {
    setLoading(false)
  }
}

export const client = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: req => {
        if (req.operationName === 'login' || !req.operationName) return true

        const token = getAccessToken()
        if (!token) {
          return true
        }

        try {
          const decodedToken = jwtDecode(token)

          const isValid = moment(moment()).isBefore(decodedToken['exp'] * 1000)

          return isValid
        } catch (err) {
          return false
        }
      },
      fetchAccessToken: () => {
        return fetch(propertyXRefreshUrl, {
          method: 'POST',
          credentials: 'include',
        })
      },
      handleFetch: (accessToken: string, operation) => {
        setAccessToken(accessToken)
      },
      handleError: err => {
        console.warn('Your refresh token is invalid. Try to relogin')
        console.error(err)
      },
    }),
    onError(({ graphQLErrors, networkError, response }) => {
      // all graphQl error will be thrown here
      //network error will navigate to error page
      if (networkError) {
        navigateTo(AUTH_PATH.NOT_FOUND)
      } else {
        if (graphQLErrors) {
          const errorMsg = graphQLErrors[0]?.message.toLowerCase()

          //   const expiredMsg = [ 'not authenticated', 'Token is not valid, please try login again', 'Token is not valid, please try login again']
          if (errorMsg == 'expired') {
            setSnackBar('Please try again')
          } else if (errorMsg == 'not authenticated') {
            sessionStorage.clear()
            setAccessToken('')
            navigateTo(AUTH_PATH.LOGIN)
          } else {
            // all other situtations should just throw any msg returned from API
            setSnackBar(graphQLErrors[0].message)
          }
        }
      }
    }),
    requestLink,
    responseData,
    uploadLink as any as ApolloLink | RequestHandler,
    new HttpLink({
      uri: propertyXNodeUrl,
      credentials: 'include',
    }), //new HttpLink ends here
  ]),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  },
})
