import { GetApp as GetAppIcon } from '@mui/icons-material'
import {
  IconButton,
  InputAdornment,
  type StandardTextFieldProps,
  TextField,
} from '@mui/material'

import { FilePreviewContainer } from '../Container/FilePreviewContainer'
interface FileUploadTextFieldProps extends StandardTextFieldProps {
  accept?: string
  multiple?: boolean
  filePreview?: React.ReactNode
}
export const FileUploadTextField = ({
  accept = '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf',
  multiple = false,
  filePreview,
  ...textFieldProps
}: FileUploadTextFieldProps): JSX.Element => {
  const hiddenInputOnClick = (): void => {
    document.getElementById('icon-file')?.click()
  }
  const displayFileValue = (files: any): boolean | null => {
    return files?.length === 0 || files === undefined
      ? ''
      : files?.length === 1
      ? files[0]?.name
      : files?.length > 1
      ? `${files.length} files`
      : null
  }
  return (
    <>
      <TextField
        {...textFieldProps}
        variant="standard"
        margin="normal"
        fullWidth
        value={displayFileValue(textFieldProps?.value)}
        onClick={() => {
          hiddenInputOnClick()
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    transform: 'rotate(180deg)',
                  }}
                  aria-label="get-app"
                >
                  <GetAppIcon />
                </IconButton>
              </InputAdornment>
              <input
                accept={accept}
                multiple={multiple}
                type="file"
                id={'icon-file'}
                hidden
                value={''}
                onChange={e => {
                  textFieldProps?.onChange(e)
                }}
                data-testid="file-upload"
              />
            </>
          ),
        }}
      />
      <FilePreviewContainer>{filePreview}</FilePreviewContainer>
    </>
  )
}
