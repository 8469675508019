import { lazy } from 'react'
import { RouteObject } from 'react-router'
const LateInterestChargesDetail = lazy(
  () => import('./LateInterestChargesDetail')
)
const LateInterestChargesListing = lazy(
  () => import('./LateInterestChargesListing')
)

const PALateInterestChargesRoutes: RouteObject[] = [
  {
    path: 'late-interest-charges',
    children: [
      {
        path: '',
        element: <LateInterestChargesListing />,
      },
      {
        path: ':id/view',
        element: <LateInterestChargesDetail />,
      },
    ],
  },
]

export default PALateInterestChargesRoutes
