import MainLayout from 'containers/app/MainLayout'
import AccessSecurityRoutes from 'containers/modules/AccessSecurity/Routes'
import AcctRoutes from 'containers/modules/Account/Routes'
import CommonSettingRoutes from 'containers/modules/CommonSetting/Routes'
import EndFinancierRoutes from 'containers/modules/EndFinancier/Routes'
import HomeRoutes from 'containers/modules/Home/Routes'
import ProjectRoutes from 'containers/modules/Project/Routes'
import SalesAdministrationRoutes from 'containers/modules/SalesAdministration/Routes'
import SolicitorRoutes from 'containers/modules/Solicitor/Routes'
import { Navigate, useRoutes } from 'react-router-dom'
import AuthRoutes, { AUTH_PATH } from '../modules/Authentication/Routes'
// import { Redirect } from 'react-router'
import { AuthGuard } from './AuthGuard'
import { Blank } from './Blank'
import AccountJournalRoutes from 'containers/modules/AccountJournal/Routes'
import ProjectBusinessInsightRoutes from 'containers/modules/ProjectBusinessInsight/Routes'
import ContractorRoutes from 'containers/modules/Contractor/Routes'
import ContractMgmtRoutes from 'containers/modules/ContractManagement/Routes'

const CommonSettingBase = '/common-setting'

//! Redirect to Home page
// const Root = [
//   {
//     props: { exact: true, path: '/' },
//     component: <Redirect to="/" />,
//   },
// ]

//! Redirect to 404 page
// const Error = [
//   {
//     component: <Redirect to={'/404-not-found'} />,
//   },
// ]
//! Error component must be at the last item in an array.

export function MainRoutes() {
  const RootRoutes = useRoutes([
    {
      path: '',
      element: <AuthGuard />,
      children: [
        {
          path: '',
          element: <MainLayout />,
          children: [
            {
              path: '',
              element: <Navigate to="/home" />,
            },
            {
              path: 'home',
              children: [...HomeRoutes],
            },
            {
              path: 'contract-management',
              children: [...ContractMgmtRoutes],
            },
            {
              path: 'business-insight/:projectID',
              children: [...ProjectBusinessInsightRoutes],
            },
            {
              path: 'common-setting',
              children: [...CommonSettingRoutes],
            },
            {
              path: 'project',
              children: [...ProjectRoutes],
            },
            {
              path: 'profile',
              children: [...AcctRoutes],
            },
            {
              path: 'end-financier',
              children: [...EndFinancierRoutes],
            },
            {
              path: 'solicitor',
              children: [...SolicitorRoutes],
            },
            {
              path: 'contractor',
              children: [...ContractorRoutes],
            },
            // {
            //   // path: 'common-setting',
            //   path: `/standard-parameters`,
            //   children: [...StandardParameterRoutes],
            // },
            {
              path: 'access-security',
              children: [...AccessSecurityRoutes],
            },
            {
              path: ':projectID',
              children: [...SalesAdministrationRoutes],
            },
            {
              path: 'account-journal',
              children: [...AccountJournalRoutes],
            },
          ],
        },
      ],
    },
    // ...HomeRoutes,
    // ...CommonSettingRoutes,
    // ...ProjectRoutes,
    // ...AcctRoutes,
    // ...EndFinancierRoutes,
    // ...SolicitorRoutes,
    // ...StandardParameterRoutes,
    // ...ComponentRoutes,
    // ...AccessSecurityRoutes,
    // ...SalesAdministrationRoutes,
    // ...AreaVarianceSubmenuRoutes,
    // ...BankLoanRoutes,
    // ...BridgingLoanRoutes,
    // ...CreditControlRoutes,
    // ...DownpaymentReminderRoutes,
    // ...KeyCollectionRoutes,
    // ...LADRoutes,
    // ...MOTRoutes,
    // ...ProgressMiscBillingRoutes,
    // ...PropertyTitleRoutes,
    // ...SalesCancellationRoutes,
    // ...SpReminderRoutes,
    // ...VacantPossessionRoutes,
    // ...SalesContractRoutes,
    // ...Root,
    // ...Error,
    {
      path: '',
      element: <Blank />,
      children: [...AuthRoutes],
    },
    {
      path: '*',
      element: <Navigate replace to={AUTH_PATH.NOT_FOUND}></Navigate>,
    },
  ])
  return RootRoutes
}
//export default RootRoutes
