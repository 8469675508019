import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const CampaignListing = lazy(() => import('./CampaignListing'))
const EventsListing = lazy(() => import('./Events/EventsListing'))
const EventsDetail = lazy(() => import('./Events/EventsDetail'))
const EventsForm = lazy(() => import('./Events/EventsForm'))

export const CAMPAIGN_PATH = {
  CAMPAIGN_LISTING: '/project/:projectID/campaign',
  EVENTS_LISTING: '/project/:projectID/campaign/:campaignId/events',
  EVENTS_DETAIL: '/project/:projectID/campaign/:campaignId/events/view',
  EVENTS_ADD: '/project/:projectID/campaign/:campaignId/events/add',
  EVENTS_EDIT: '/project/:projectID/campaign/:campaignId/events/:id/edit',
}

const campaignRoutes: RouteObject[] = [
  {
    path: 'campaign',
    children: [
      {
        path: '',
        element: <CampaignListing />,
      },
      {
        path: ':campaignId/events',
        children: [
          {
            path: '',
            element: <EventsListing />,
          },
          {
            path: ':id/view',
            element: <EventsDetail />,
          },
          {
            path: 'add',
            element: <EventsForm mode="add" />,
          },
          {
            path: ':id/edit',
            element: <EventsForm mode="edit" />,
          },
        ],
      },
    ],
  },
]

export default campaignRoutes
