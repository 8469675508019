import { lazy } from 'react'
import { RouteObject } from 'react-router'

const LoanCancellationListing = lazy(() => import('./LoanCancellationListing'))
const LoanCancellationForm = lazy(() => import('./LoanCancellationForm'))
const LoanCancellationDetail = lazy(() => import('./LoanCancellationDetail'))

const LoanCancellationRoutes: RouteObject[] = [
  {
    path: 'loan-cancellation',
    children: [
      {
        path: '',
        element: <LoanCancellationListing />,
      },
      {
        path: 'add',
        element: <LoanCancellationForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <LoanCancellationForm mode="edit" />,
      },
      {
        path: ':id/detail',
        element: <LoanCancellationDetail />,
      },
    ],
  },
]

export default LoanCancellationRoutes
