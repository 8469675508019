import { ColumnFlex, Container, EmptyState } from '@ifca-ui/core'
import { SalesContractSubheader } from 'components/General/SalesContractSubheader'
import useAppData from 'components/General/useAppData'
import { useGetNewPBdataQuery, useGetPBdataListQuery } from 'generated/graphql'
import { useSalesContractById } from './PBcomponent/PAprogressBillingBloc'
import usePBheader from './PBcomponent/usePBheader'
import { PBtypeListPro } from './Pbtest/PBtypeListPro'

interface props {
  isPA: boolean
}

const PAprogressBillingListing = ({ isPA }: props) => {
  const { rootState, subHeaderRef, rootDispatch, saleID } = useAppData()
  const { salesContract } = useSalesContractById()
  usePBheader(isPA)

  const {
    data: { getNewPBdata } = { getNewPBdata: {} },
    refetch: refetchData,
  } = useGetNewPBdataQuery({
    variables: { saleId: saleID },
  })

  const { data: { getPBdataList } = { getPBdataList: {} } } =
    useGetPBdataListQuery({
      variables: { saleId: saleID },
    })
  return (
    <>
      <SalesContractSubheader getSalesContractById={salesContract} />
      <Container>
        {/* <div>
            {getNewPBdata && (
              <ColumnFlex gap={'5px'}>
                {getNewPBdata['sale_progress_billing'] && (
                  <PBsaleProductList
                    isPA={isPA}
                    pbListData={getNewPBdata['sale_progress_billing']}
                    refetchData={refetchData}
                  />
                )}
                {getNewPBdata['car_park'] && (
                  <PBtypeList
                    isPA={isPA}
                    type={'SALE_SUBPRODUCT'}
                    pbListData={getNewPBdata['car_park']}
                    refetchData={refetchData}
                  />
                )}
                {getNewPBdata['sale_package'] && (
                  <PBtypeList
                    isPA={isPA}
                    type={'SALE_PACKAGE'}
                    pbListData={getNewPBdata['sale_package']}
                    refetchData={refetchData}
                  />
                )}
                {!getNewPBdata['sale_progress_billing'] &&
                  !getNewPBdata['car_park'] &&
                  !getNewPBdata['sale_package'] && (
                    <EmptyState title="No Record found" />
                  )}
              </ColumnFlex>
            )}
          </div> */}
        {['sale_product', 'sale_subproduct', 'sale_package']?.every(
          key => getPBdataList[key]?.length === 0
        ) ? (
          <EmptyState title="No Record found" />
        ) : (
          <ColumnFlex gap="1rm">
            {['sale_product', 'sale_subproduct', 'sale_package']?.map(
              key =>
                getPBdataList[key] && (
                  <PBtypeListPro
                    isPA={isPA}
                    pbListData={getPBdataList[key]}
                    refetchData={refetchData}
                    type={''}
                  />
                )
            )}
          </ColumnFlex>
        )}
      </Container>
    </>
  )
}
export default PAprogressBillingListing
