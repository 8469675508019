import { TextField } from '@mui/material'
import NumberFormat from 'react-number-format'

export const NricTextField = ({
  label,
  name,
  required,
  value,
  onChange,
  onBlur,
  helperText,
  error,
}: any) => {
  return (
    <NumberFormat
      customInput={TextField}
      label={label}
      name={name}
      autoComplete="off"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      fullWidth
      margin="normal"
      variant="standard"
      decimalScale={0}
      format="######-##-####"
      mask="_"
      helperText={helperText}
      error={error ? true : false}
      required={required}
    />
  )
}
