import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const SLP_Page = lazy(() => import('./SLP_Page'))
const SLP_Routes: RouteObject[] = [
  {
    path: 'SalesLeadPerformance',
    children: [
      {
        path: '',
        element: <SLP_Page />,
      },
    ],
  },
]

export default SLP_Routes
