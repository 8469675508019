import { Dialog, Subheader, SubheaderText } from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'

interface ActionDialogProps {
  dialogAction?: boolean
  label?: String
  title: string
  subtitle?: string
  setOpenDialog?: any
  action?: any
}
export const ActionDialog = (props: ActionDialogProps) => {
  const { dialogAction, title, subtitle, setOpenDialog, label, action } = props

  return (
    <Dialog
      fullWidth={true}
      open={dialogAction}
      onClose={() => setOpenDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    {title}
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="primary.dark"
                    component="span"
                    variant="inherit"
                  >
                    {subtitle}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Box width="100%" display="flex">
          <Typography
            component="span"
            flex="1 1"
            variant="inherit"
            className="text-xsTitle"
          >
            {label}
          </Typography>
        </Box>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setOpenDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => action(),
          },
        ],
      }}
    />
  )
}
