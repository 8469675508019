import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import ContractVoRoutes from './ContractVO/Routes'
// import projectWBSRoutes from './ProjectWBSBudgeting/Routes'
import retentionRoutes from './Retention/Routes'
import contractEOTRoutes from './ContractEOT/Routes'

const ContractMgmtListing = lazy(() =>
  import('./ContractMgmtListing').then(module => ({
    default: module.ContractMgmtListing,
  }))
)

const ContractMgmtSubmenu = lazy(() =>
  import('./ContractMgmtSubmenu').then(module => ({
    default: module.ContractMgmtSubmenu,
  }))
)

const ContractDetail = lazy(() =>
  import('./ContractDetail').then(module => ({
    default: module.ContractDetail,
  }))
)

const ContractForm = lazy(() =>
  import('./ContractForm').then(module => ({
    default: module.ContractForm,
  }))
)

export const CONTRACT_MGMT_PATH = {
  LISTING: '/contract-management',
  CON_MGMT_SUBMENU: `/contract-management/:contractID`,
}

const ContractMgmtRoutes: RouteObject[] = [
  // {
  //   path: 'submenu',
  //   element: <ContractMgmtListing />,
  // },
  {
    path: '',
    children: [
      {
        path: '',
        element: <ContractMgmtListing />,
      },
      {
        path: 'add',
        element: <ContractForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <ContractForm mode="edit" />,
      },
      {
        path: ':contractID',
        children: [
          {
            path: '',
            element: <ContractMgmtSubmenu />,
          },
          {
            path: 'contract-details',
            element: <ContractDetail />,
          },
          ...retentionRoutes,
          ...contractEOTRoutes,
          ...ContractVoRoutes,
          // {
          //   path: ':contractID',
          //   children: [...projectWBSRoutes, ...retentionRoutes],
          // },
        ],
      },
    ],
  },
]

export default ContractMgmtRoutes
