import { lazy } from 'react'
import { RouteObject } from 'react-router'

const SalesAnalysisDetail = lazy(() => import('./SalesAnalysisDetail'))

export const SALES_ANALYSIS_PATH = {
  SUBMENU: '/business-insight/:projectID/sales-analysis',
}

const SalesAnalysisRoutes: RouteObject[] = [
  {
    path: 'sales-analysis',
    children: [
      {
        path: '',
        element: <SalesAnalysisDetail />,
      },
    ],
  },
]

export default SalesAnalysisRoutes
