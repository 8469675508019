import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import unitSetupRoutes from './UnitSetup/Routes'
import carParkSetupRoutes from './CarParkSetup/Routes'

const unitRoutes: RouteObject[] = [
  {
    path: '',
    children: [...unitSetupRoutes, ...carParkSetupRoutes],
  },
]

export default unitRoutes
