import { lazy } from 'react'
import { RouteObject } from 'react-router'
const LTreportDesign = lazy(() => import('./LetterTemplate/LTreportDesign'))
const ELetterCategory = lazy(() => import('./ELetterCategory'))
const LetterTemplateForm = lazy(
  () => import('./LetterTemplate/LetterTemplateForm')
)
const LetterTemplateListing = lazy(
  () => import('./LetterTemplate/LetterTemplateListing')
)
const LTreportView = lazy(() => import('./LetterTemplate/LTreportView'))

export const E_LETTER_PATH = {
  CATEGORY_LISTING: `/common-setting/e-lettering`,
}

const eLetterRoutes: RouteObject[] = [
  {
    path: 'e-lettering',
    children: [
      {
        path: '',
        element: <ELetterCategory />,
      },
      {
        path: 'report-viewer/:reportId',
        element: <LTreportView />,
      },
      {
        path: ':standardLetterId',
        children: [
          {
            path: '',
            element: <LetterTemplateListing />,
          },
          {
            path: 'html-editor',
            element: <LetterTemplateForm isCommonSetting={true} />,
          },
          {
            path: 'report-designer',
            element: <LTreportDesign isCommonSetting={true} />,
          },
        ],
      },
    ],
  },
]

export default eLetterRoutes
