import { lazy } from 'react'
import { RouteObject } from 'react-router'

const CollectionListing = lazy(() => import('./PACollectionListing'))
const CollectionDetail = lazy(() =>
  import('./PACollectionDetail').then(module => ({
    default: module.PACollectionDetail,
  }))
)
const CollectionAllocation = lazy(() => import('./PACollectionAllocationForm'))
const PACollectionRoutes: RouteObject[] = [
  {
    path: 'collection',
    children: [
      {
        path: '',
        element: <CollectionListing />,
      },

      {
        path: ':id',
        children: [
          {
            path: 'detail',
            element: <CollectionDetail />,
          },
          {
            path: 'allocation',
            element: <CollectionAllocation />,
          },
        ],
      },
    ],
  },
]

export default PACollectionRoutes
