import { RouteObject } from 'react-router'
import PAPaymentReminder from './PAPaymentReminder'

const PAPaymentReminderRoutes: RouteObject[] = [
  {
    path: 'payment-reminder',
    children: [
      {
        path: '',
        element: <PAPaymentReminder />,
      },
    ],
  },
]

export default PAPaymentReminderRoutes
