import { BehaviorSubject } from "rxjs";

// export interface snackBarObservableInterface{
//   message:string,

// }

let snackBarSubject = new BehaviorSubject<string>("");

export const setSnackBar = (message: string) => {
  snackBarSubject.next(message);
};

export const snackBarObservable = () => {
  return snackBarSubject;
};