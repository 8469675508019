import { RouteObject } from 'react-router'
import PArefundDetail from './PArefundDetail'
import PArefundForm from './PArefundForm'
import PArefundListingPage from './PArefundListingPage'

//PA = purchaser's accounts
const PARefundRoutes: RouteObject[] = [
  {
    path: 'refund',
    children: [
      {
        path: '',
        element: <PArefundListingPage />,
      },
      {
        path: ':id/view',
        element: <PArefundDetail />,
      },
      {
        path: 'add',
        element: <PArefundForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PArefundForm mode="edit" />,
      },
    ],
  },
]

export default PARefundRoutes
