export const webShare = async (
  title,
  body = '',
  url = '',
  fileContent = '',
  fileName = ''
) => {
  let files = null
  if (fileContent != '') {
    const base64Response = await fetch(`${fileContent}`)
    const blob = await base64Response.blob()
    const pdfFile = new File([fileContent], fileName, {
      type: blob.type,
    })
    files = { files: [pdfFile] }
  }
  if (await navigator.share) {
    await navigator
      .share({
        title: title,
        text: body,
        url: url,
        ...files,
      })
      .then(() => {
        return 'Sharing successfull'
      })
      .catch(error => {
        return `error : ${error}`
      })
  } else {
    return 'Sorry! Your browser does not support Web Share API'
  }
}
