import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  type ButtonProps,
  type DialogProps as MuiDialogProps,
} from '@mui/material'
import type * as React from 'react'
import { DialogButton } from '../Button/DialogButton'

interface DialogProps extends MuiDialogProps {
  /** Display dialog information on the header and able to reuse Subheader in this header props section */
  header?: React.ReactNode
  body?: React.ReactNode
  footer?: {
    buttons: ButtonProps[]
  }
}
export const Dialog = ({
  header,
  body,
  footer,
  ...props
}: DialogProps): JSX.Element => {
  return (
    <MuiDialog {...props} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title" sx={{ padding: '0px' }}>
        {header}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '8px 12px !important',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          grow: 1,
          shrink: 1,
          minHeight: '0px',
        }}
      >
        {body}
      </DialogContent>
      <DialogActions
        sx={{
          padding: '8px 12px ',
        }}
      >
        {footer?.buttons?.map((button, index) => (
          <DialogButton
            key={index}
            variant="contained"
            size="small"
            color={button.color ?? 'primary'}
            {...button}
          />
        ))}
      </DialogActions>
    </MuiDialog>
  )
}
