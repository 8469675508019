import {
  ColumnFlex,
  Expansion,
  ExpansionSummary,
  Menu,
  MenuItem,
  RowFlex,
  RowGrid,
  useMenuOption,
  Wrap,
} from '@ifca-ui/core'
import { PxHumanArrow } from '@ifca-ui/icons/dist/index.esm'
import {
  AccountBalance,
  CalendarMonthRounded,
  KeyboardArrowRightOutlined,
  Person,
} from '@mui/icons-material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Tooltip } from '@mui/material'
import IconX from 'components/General/IconX'
import useAppData from 'components/General/useAppData'
import { PBdata } from 'generated/graphql'
import PBnumberSquarePro from './PBnumberSquarePro'
import UIlabel from 'components/General/UIlabel'
import TextX from 'components/General/TextX'
import TooltipX from 'components/General/TooltipX'
interface PBexpansionSummaryProps {
  PBdata: PBdata
  isPA: boolean
  handleReversal: () => void
  lastIndex: number
  index: number
}

const DocumentInfo: React.FC<{ PBdata: PBdata }> = ({ PBdata }) => (
  <RowFlex mainAxisAlignment="space-between" fullWidth>
    <RowFlex>
      <IconX icon={CalendarMonthRounded} />
      <RowFlex gap="4px">
        <TooltipX title="Date of Document">
          <TextX data={PBdata.document_date} />
        </TooltipX>
        <div>|</div>
        <TooltipX title="Document No." placement="right">
          <TextX data={PBdata.document_no} />
        </TooltipX>
      </RowFlex>
    </RowFlex>
    <div style={{ color: 'red' }}>
      <TooltipX title="Outstanding Amount" placement="left">
        <TextX
          data={PBdata.sumRedAmount}
          dataType={PBdata.sumRedAmount ? 'Number' : 'Text'}
          color="red"
        />
      </TooltipX>
    </div>
  </RowFlex>
)

const CreditDocs = (creditDocs: PBdata['creditDoc']) =>
  creditDocs?.map((doc, i) => (
    <span key={i}>
      <TextX data={doc?.document_date} /> | <TextX data={doc?.document_no} />
      {i !== creditDocs.length - 1 && ', '}
    </span>
  )) || <span>-</span>

const PBexpansionSummaryPro: React.FC<PBexpansionSummaryProps> = ({
  PBdata,
  isPA,
  handleReversal,
  index,
}) => {
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const { navTo, projectID } = useAppData()

  const handleView = menu => {
    navTo(
      `/${projectID}/purchaser-accounts/${
        menu.id.split('/')[0]
      }/progress-billing/${menu.id.split('/')[0]}/view`,
      {
        calculatedDocumentAmt: menu.sumOrangeAmount,
        outstandingAmt: menu.sumRedAmount,
      }
    )
  }

  return (
    <Expansion disabled={PBdata.isReversal}>
      <ExpansionSummary
        onClick={() => {
          !isPA && handleView(PBdata)
        }}
      >
        <RowFlex fullWidth gap="20px" crossAxisAlignment="center">
          <ColumnFlex gap="4px" fullWidth className="text-desc text-icon">
            <RowGrid gridTemplateColumns="1fr auto" fullWidth>
              <RowFlex gap="4px">
                <PBnumberSquarePro data={PBdata} />
                <UIlabel children={PBdata?.title} fontWeight={'lighter'} />
                {/* <TextX
                  data={PBdata.title}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                /> */}
              </RowFlex>
              <RowFlex className="c-orange" mainAxisAlignment="flex-end">
                {PBdata.is_stakeholder && <IconX icon={PxHumanArrow} />}
                <TooltipX title="Document Amount" placement="left">
                  <TextX
                    data={PBdata.sumOrangeAmount}
                    dataType="Number"
                    className="text-icon"
                    color="primary"
                  />
                </TooltipX>
              </RowFlex>
            </RowGrid>
            <DocumentInfo PBdata={PBdata} />
            <RowFlex fullWidth>
              {PBdata.isBuyer ? (
                <div>
                  <RowFlex>
                    <IconX icon={Person} />
                    <Wrap fullWidth>{CreditDocs(PBdata.creditDoc)}</Wrap>
                  </RowFlex>
                </div>
              ) : (
                <Tooltip
                  title={`${PBdata?.financier_name ?? '-'}(${
                    PBdata?.financier_code ?? '-'
                  })`}
                  id="pbTooltip"
                  placement="top-start"
                >
                  <div>
                    <RowFlex>
                      <IconX icon={AccountBalance} />
                      <Wrap fullWidth>{CreditDocs(PBdata.creditDoc)}</Wrap>
                    </RowFlex>
                  </div>
                </Tooltip>
              )}
            </RowFlex>
          </ColumnFlex>
          {isPA ? (
            <IconX
              icon={MoreHorizIcon}
              onClick={e => handleClick(e, PBdata.id, index, PBdata)}
            />
          ) : (
            <IconX icon={KeyboardArrowRightOutlined} />
          )}
          <Menu
            id="menu-list"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem onClick={() => handleView(menu.data)}>
              <span>View</span>
            </MenuItem>
            {isPA && (
              <MenuItem
                onClick={handleReversal}
                disabled={!menu.data?.showReversal}
              >
                <span>Reversal</span>
              </MenuItem>
            )}
          </Menu>
        </RowFlex>
      </ExpansionSummary>
    </Expansion>
  )
}

export default PBexpansionSummaryPro
