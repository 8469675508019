import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const OutstandingSnPpage = lazy(() => import('./OutstandingSnPpage'))
const OutstandingSnProutes: RouteObject[] = [
  {
    path: 'OutstandingSnPpage',
    children: [
      {
        path: '',
        element: <OutstandingSnPpage />,
      },
    ],
  },
]

export default OutstandingSnProutes
