import { useState, memo } from 'react'
import { Dialog } from './Dialog'
interface props {
  title: string
  rightTopTitle: string
  action?: any
  bodyText: string
}

export const useConfirmDialog = () => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const showConfirmDialog = () => {
    setOpenConfirmDialog(true)
  }

  const hideConfirmDialog = () => {
    setOpenConfirmDialog(false)
  }

  const ConfirmDialogX = memo(
    ({
      // BEGIN: memo
      title,
      rightTopTitle,
      bodyText,
      action,
    }: props) => (
      <Dialog
        useDefaultDialogHeader={true}
        useDefaultDialogBody={true}
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        defaultDialogData={{
          header: {
            title: {
              leftTopTitle: {
                title: {
                  text: title,
                },
              },
              rightTopTitle: {
                text: rightTopTitle,
              },
            },
          },
          bodyText: bodyText,
        }}
        footer={{
          buttons: [
            {
              children: 'Cancel',
              color: 'primary',
              onClick: e => {
                e.stopPropagation() // Stop event propagation
                setOpenConfirmDialog(false)
              },
            },
            {
              children: 'Confirm',
              color: 'primary',
              type: 'button',
              onClick: () => {
                action()
                hideConfirmDialog()
              },
            },
          ],
        }}
      />
    )
  ) // END: memo

  return {
    showConfirmDialog,
    hideConfirmDialog,
    ConfirmDialogX,
  }
}
